import React, { Component } from 'react'
import PlaceholderLoading from 'react-placeholder-loading'
export class ButtonPlaceholder extends Component {
    constructor(props){
        super(props);
        
    }
    static defaultProps ={
        block:false
    }

  render() {
      const { block } = this.props;
    return (
      <span className={block?"button block":"button"}> 
          <PlaceholderLoading width={100} height={40} shape="rect"/>
      </span>
    )
  }
}

export default ButtonPlaceholder
