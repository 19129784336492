import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import TeamApi from "../../../apis/TeamApi";
import { ToastContainer, toast } from "react-toastify";
import { Constants } from "../../../constants";

import avatar from "../../../assets/images/avatar.png";

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);


class EditMember extends Component{
    constructor(){
        super();
        this.state = this.getInitialState();

    }
    
    getInitialState = ()=>({
        id:'',
        name:'',
        email:'',
        position:'',
        level:'',
        bio:'',
        facebook:'',
        twitter:'',
        linkedin:'',
        instagram:'',
        name_valid:true,
        email_valid:true,
        position_valid:true,
        level_valid:true,
        bio_valid:true,
        errors: {
            name:'',
            email:'',
            position:'',
            level:'',
            bio:'',
            facebook:'',
            twitter:'',
            linkedin:'',
            instagram:'',
            image:''
        },
        processing:false,
        image:''
    });
  componentDidMount(){
    this.setState({id:this.props.member.id});
  }
  componentDidUpdate(props){
    if(this.props!=props){
      this.setState({
        name:this.props.member.name,
        email:this.props.member.email,
        position:this.props.member.position,
        level:this.props.member.level,
        bio:this.props.member.bio,
        facebook:this.props.member.facebook_url,
        twitter:this.props.member.twitter_url,
        linkedin:this.props.member.linkedin_url,
        instagram:this.props.member.instagram_url
      });
    }
  }
  handleChange = (event) => {
      this.setState({[event.target.name]: event.target.value})

      event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        
        switch (name) {
          case 'name': 
            errors.name = 
              value.length < 2
                ? 'Name must be 2 characters long!'
                : '';
            break;
          case 'email': 
            errors.email = 
              validEmailRegex.test(value)
                ? ''
                : 'Email is not valid!';
            break;
          case 'position': 
            errors.position = 
              value.length <= 0
                ? 'Position is required'
                : '';
            break;
          case 'level': 
            errors.level = 
              value.length <= 0
                ? 'Level is required'
                : '';
            break;
          case 'facebook': 
            errors.facebook = '';
            break;
          case 'twitter': 
            errors.twitter = '';
            break;
          case 'linkedin': 
            errors.linkedin = '';
            break;
          case 'instagram': 
            errors.instagram = '';
            break;
          
          default:
            break;
        }

        let fieldValid = !errors[name]?true:false;
        let fieldNameValid = name+'_valid';
      
        this.setState({errors, [name]: value, [fieldNameValid]:fieldValid});

        console.log(this.state);
  }

  validateForm = (errors) => {
      let valid = true;
      
      if(!this.state.name_valid){
          errors['name'] = 'Name is required';
      }
      if(!this.state.email_valid){
          errors['email'] = 'Email is required';
      }

      if(!this.state.position_valid){
          errors['position'] = 'Position is required';
      }

      if(!this.state.level_valid){
          errors['level'] = 'Level is required';
      }
      // if(!this.state.bio_valid){
      //     errors['bio'] = 'Image is required';
      // }

      this.setState({errors:errors});

      Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
      );
      
      return valid;
  }

  updateMember = (e)=>{
        e.preventDefault();

        const data = new FormData(document.getElementById("memberFrm"));

        this.setState({processing:true});

        if(this.validateForm(this.state.errors,["name","email","position","level","bio","image","facebook","twitter","instagram","linkedin"])) {
          const toastId = toast.loading('Creating Member...',{position:'bottom-right'});

          TeamApi.updateMember(this.state.id,data)
              .then(response=>{
                  /**Reset Form */
                  // this.setState(this.getInitialState());
                  /**Show sucess message */
                  // this.setState({processing:false});

                  toast.update(toastId,{
                      isLoading: false,
                      autoClose: 5000,
                      type:'success',
                      render:response?.data?.message
                  });
                  this.props.onMemberUpdated();
              })
              .catch(err=>{
                  this.setState({processing:false});
                  
                  if(err?.response?.status==422){
                      const { errors } = err.response.data;
                      let inputErrors = this.state.errors;
    
                      for (const key in errors) {
                          inputErrors[key]=errors[key][0];
                      }

                      this.setState({errors:inputErrors});
                  }
                  if(err?.response?.status==404||err?.response?.status==500){
                    toast.update(toastId,{
                        isLoading: false,
                        autoClose: 5000,
                        type:'error',
                        render: err?.message??'Something went wrong'
                    });
                  }
                  else{
                    toast.update(toastId,{
                        isLoading: false,
                        autoClose: 5000,
                        type:'error',
                        render: err?.response?.data?.message??'Something went wrong'
                    });
                  }
              });
        };
    }
    handleHideModal =()=>{
        this.setState(this.getInitialState());
        this.props.onHideModal();
    }
    
   
    handleFileChange = (event)=>{
        this.setState({[event.target.name]: event.target.files[0]})
    }

   render(){
      const { errors } = this.state;
        const { show } = this.props;
        
        return (
            <div>
                <Modal className="modalWrapper" show={show} fullscreen="md-down" onHide={this.handleHideModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Edit Member</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
    <form id="memberFrm" onSubmit={this.updateMember} enctype="multpart/form-data">
    <Row>
    <Col lg={6} sm={12}>
          <Form.Label htmlFor="name">Name<sup className="text-danger">*</sup></Form.Label>
            <Form.Control
                        label="Name"
                        type='text'
                        name="name"
                        id='name'
                        defaultValue={this.props.member.name}
                        value={this.state.name}
                        onChange={this.handleChange}
                    />
            {errors.name.length > 0 && 
<div className='text-danger mb-2'>{errors.name}</div>}
<br />
        </Col>
        <Col lg={6} sm={12}>
            <Form.Label htmlFor="email">Email<sup className="text-danger">*</sup></Form.Label>
            <Form.Control
                    name="email"
                    type='text'
                    id='email'
                    value={this.state.email}
                    defaultValue={this.props.member.email}
                    onChange={this.handleChange}
                />
            {errors.email.length > 0 && 
<div className='text-danger mb-2'>{errors.email}</div>}
<br />
        </Col>

        <Col lg={6} sm={12}>
            <Form.Label htmlFor="email">Position<sup className="text-danger">*</sup></Form.Label>
            <Form.Control
                    name="position"
                    type='text'
                    id='position'
                    defaultValue={this.props.member.position}
                    value={this.state.position}
                    onChange={this.handleChange}
                />
            {errors.position.length > 0 && 
<div className='text-danger mb-2'>{errors.position}</div>}
<br />
        </Col>

        <Col lg={6} sm={12}>
        <Form.Label htmlFor="level">Level</Form.Label>
          <Form.Select aria-label="Default select example" name="level" onChange={this.handleChange}>
          <option value="">--Select level--</option>
            <option value="1" selected={this.state.level==1}>1</option>
            <option value="2" selected={this.state.level==2}>2</option>
            <option value="3" selected={this.state.level==3}>3</option>
            <option value="4" selected={this.state.level==4}>4</option>
            <option value="5" selected={this.state.level==5}>5</option>
            <option value="6" selected={this.state.level==6}>6</option>
            <option value="7" selected={this.state.level==7}>7</option>
            <option value="8" selected={this.state.level==8}>8</option>
            <option value="9" selected={this.state.level==9}>9</option>
            <option value="10" selected={this.state.level==10}>10</option>
          </Form.Select>
          {errors.level.length > 0 && 
  <div className='text-danger mb-2'>{errors.level}</div>}
  <div className="mb-5"></div>
        </Col>

        <Row className="mb-4">
          <Col lg={6} sm={12}>
          <Form.Label htmlFor="image">Photo</Form.Label>
            <Form.Control
                        name="image"
                        type='file'
                        id='image'
                        onChange={this.handleFileChange}
                    />
                {errors.image.length > 0 && 
    <div className='text-danger mb-2'>{errors.image}</div>}
    <br />
          </Col>
          <Col lg={6} sm={12}>
                <img className="img-responsive" src={this.props.member.photo?Constants.publicUrl+'/images/'+this.props.member.photo:avatar} alt="" />
          </Col>
        </Row>



        


        
        <Col lg={6} sm={12}>
            <Form.Label htmlFor="facebook">Facebook</Form.Label>
            <Form.Control
                name="facebook"
                type='text'
                id='facebook'
                defaultValue={this.props.member.facebook}
                value={this.state.facebook}
                onChange={this.handleChange}
            />
            {errors.facebook.length > 0 && 
<div className='text-danger mb-2'>{errors.facebook}</div>}
            <br/>
            
        </Col>
        <Col lg={6} sm={12}>
            <Form.Label htmlFor="twitter">Twitter</Form.Label>
            <Form.Control
                name="twitter"
                type='text'
                id='twitter'
                defaultValue={this.props.member.twitter}
                value={this.state.twitter}
                onChange={this.handleChange}
            />
            {errors.twitter.length > 0 && 
<div className='text-danger mb-2'>{errors.twitter}</div>}
            <br/>
            
        </Col>
        <Col lg={6} sm={12}>
            <Form.Label htmlFor="linkedin">Linkedin</Form.Label>
            <Form.Control
                name="linkedin"
                type='text'
                id='linkedin'
                defaultValue={this.props.member.linkedin}
                value={this.state.linkedin}
                onChange={this.handleChange}
            />
            {errors.linkedin.length > 0 && 
<div className='text-danger mb-2'>{errors.linkedin}</div>}
            <br/>
            
        </Col>


        <Col lg={6} sm={12}>
            <Form.Label htmlFor="instagram">Instagram</Form.Label>
            <Form.Control
                name="instagram"
                type='text'
                id='instagram'
                defaultValue={this.props.member.instagram}
                value={this.state.instagram}
                onChange={this.handleChange}
            />
            {errors.instagram.length > 0 && 
<div className='text-danger mb-2'>{errors.instagram}</div>}
            <br/>
            
        </Col>

        <Col lg={12} sm={12}>
            <Form.Label htmlFor="instagram">Bio</Form.Label>
            <Form.Control
                name="bio"
                as="textarea"
                id='bio'
                defaultValue={this.props.member.bio}
                value={this.state.bio}
                onChange={this.handleChange}
            />
            {errors.bio.length > 0 && 
<div className='text-danger mb-2'>{errors.bio}</div>}
            <br/>
            
        </Col>

        
    </Row>
    <hr />
        
    <div className="text-right mt-3 mb-4">
        <Button variant="primary" type="submit" className="float-right">Update Member</Button>
        <div className="clearfix"></div>
    </div>
</form> 
                    </Modal.Body>
                </Modal>
                <ToastContainer/>
            </div>
        )
    }
}
export default withRouter(EditMember);