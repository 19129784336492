import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Header from '../../common/Header'
import {faPlus } from '@fortawesome/free-solid-svg-icons'
import UserSidebarNav from '../../components/user/UserSidebarNav'
import HelpButton from '../../components/user/HelpButton'
import AddUser from '../../components/admin/users/AddUser'
import UserItem from '../../components/admin/users/UserItem'
import { UserApi }  from '../../apis/UserApi'
import {ToastContainer, toast} from 'react-toastify'
import UsersPagePlaceholder from './placeholders/UsersPagePlaceholder'

class UsersPage extends Component {
    state = {
        AccountTypeFilter:'',
        users:'',
        next_page_url:'',
        showAddUserForm:false
    }

    componentDidMount(){
      this.loadAllUsers();
      document.documentElement.scrollTop=0;
    }

    onNewUser = ()=>{
      this.loadAllUsers();
    }

    loadAllUsers = ()=>{
      UserApi.getUsers()
        .then(response=>{
          this.setState({users:response.data.data,next_page_url:response.data.next_page_url});
          document.documentElement.scrollTop=0;
        });
    }

    loadUserByType = (e)=>{
      this.setState({AccountTypeFilter:e.target.value});

      const toastId=toast.loading('Loading...',{position:'bottom-right'});
      UserApi.getUsers(e.target.value)
        .then(response=>{
          toast.dismiss(toastId);
          this.setState({users:response.data.data,next_page_url:response.data.next_page_url});
          document.documentElement.scrollTop=0;
        })
        .catch(()=>{
          toast.update(toastId,{
            isLoading:false,
            render:'An error occored',
            autoClose:2000,
            type:'error'
          });
        });

    }

    searchUsers = (e)=>{
        UserApi.searchUsers(e.target.value)
        .then(response=>{
          this.setState({users:response.data.data,next_page_url:response.data.next_page_url});
          document.documentElement.scrollTop=0;
        })
    }

    showAddUserFormHandler = ()=>{
      this.setState({showAddUserForm:true});
    }
    handleAddUserHide = ()=>{
      this.setState({showAddUserForm:false});
    }

  render() {
    const { users, next_page_url, AccountTypeFilter } = this.state;

    if(!users){
      return <UsersPagePlaceholder />
    }
    else{
      let userItems = '';
      if(users){
        userItems = users.map((val)=>{
          return <UserItem user={val} key={val.id}/>
        });
      }
      return (
      <div>
          <Header background={false} />
          <Container className="content-on-bg mt-3">
            <Row>
              <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
                <UserSidebarNav />
                <HelpButton />
              </Col>
              <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                <div className="welcome mt-0 p-4">
                  <div className="Users mb-3">
                    <h2 className="section-title border-bottom mb-5 pb-4 mt-2">
                      Users
                    </h2>
                      <div className="buttons mb-4">
                          <Row>
                              <Col xl={6} lg={6} md={5} sm={12} className="group mb-3">
                                  <Button variant={AccountTypeFilter==''?"dark":'light'} value="" onClick={this.loadUserByType}>All</Button>
                                  <Button variant={AccountTypeFilter=='model'?"dark":'light'} value="model"  onClick={this.loadUserByType}>Models</Button>
                                  <Button variant={AccountTypeFilter=='actor'?"dark":'light'} value="actor"  onClick={this.loadUserByType}>Actors</Button>
                                  <Button variant={AccountTypeFilter=='admin'?"dark":'light'} value="admin"  onClick={this.loadUserByType}>Admins</Button>
                              </Col>
                              <Col xl={4} lg={4} md={4} sm={12} className="mb-3">
                                  <input type="text" className="form-control" onChange={this.searchUsers} placeholder="Search model & actors"/>
                              </Col>
                              <Col xl={2} lg={2} md={3} sm={12} className="text-center mb-3">
                                  <Button variant="outline-danger" onClick={this.showAddUserFormHandler}><FontAwesomeIcon icon={faPlus}/> New</Button>
                              </Col>
                          </Row>
                          
                          

                      </div>
                    <Row>
                          {/*User items */}
                          {userItems}
                      </Row>

                  </div>
                  <AddUser onNewUser={this.onNewUser} show={this.state.showAddUserForm} onHideModal={this.handleAddUserHide} />
                </div>
              </Col>
            </Row>
          </Container>
          <div className="home-bg"></div>
          <div className="overlay"></div>
          <ToastContainer/>
        </div>
      )
    }
    
  }
}

export default UsersPage
