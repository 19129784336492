import React, { Component } from 'react'
import { Col, Container, Row,Button, Form, InputGroup } from 'react-bootstrap'
import Header from '../../common/Header'
import HelpButton from '../../components/user/HelpButton'
import SubscriptionItem from '../../components/admin/subscriptions/SubscriptionItem'
import { SubscriptionApi } from '../../apis/SubsccriptionApi'
import {ToastContainer, toast} from 'react-toastify'
import axios from 'axios'
import UserSidebarNav from '../../components/user/UserSidebarNav'
import SubscriptionsPagePlaceholder from './placeholders/SubscriptionsPagePlaceholder'

class SubscriptionsPage extends Component {
    state = {
        subscriptions:'',
        next_page_url:'',
        start_date:'',
        end_date:''
    }

    handleDateChange = (e)=>{
        this.setState({[e.target.name]:e.target.value});

        const self = this;
        setTimeout(()=>{
            self.filterByDate();
        },300);
        
    }

    filterByDate = ()=>{
        if(this.state.start_date&&this.state.end_date){
            /**filter data */
            toast.promise(
                SubscriptionApi.filterByDate(this.state.start_date,this.state.end_date)
                .then(response=>{
                    this.setState({subscriptions:response.data.data,next_page_url:response.data.next_page_url});
                })
            ,
            {
                pending:'Loading...'
            });
        }
    }

    handleSearch = (e)=>{
        if(e.target.value){
            toast.promise(
                SubscriptionApi.filterByReferenceNumber(e.target.value)
                .then(response=>{
                    this.setState({subscriptions:response.data.data,next_page_url:response.data.next_page_url});
                })
            ,
            {
                pending:'Loading...'
            });
        }
    }

    handleLoadMore = ()=>{
        const url = this.state.next_page_url.replace(axios.defaults.baseURL,'');
        toast.promise(
            SubscriptionApi.loadMoreSubscriptions(url)
                .then(response=>{
                    let subscriptions = this.state.subscriptions;

                    this.setState({subscriptions:subscriptions.concat(response.data.data),next_page_url:response.data.next_page_url});
                }),
                {
                    pending:'Loading....'
                });
    }

    componentDidMount(){
        SubscriptionApi.getSubscriptions()
            .then(response=>{
                this.setState({subscriptions:response.data.data,next_page_url:response.data.next_page_url});
                document.documentElement.scrollTop=0;
            })
    }

    handleDateInputFocus = (e)=>{
        e.target.type = "date";
        document.getElementById(e.target.id).showPicker();
    }
    handleDateInputBlur = (e)=>{
        e.target.type = "text";
    }

  render() {
    const {subscriptions}=this.state;
    let subscriptionItems = '';
    if(subscriptions){
        subscriptionItems = subscriptions.map((val)=>{
            return <SubscriptionItem key={val.id} subscription={val} user={val.user} showName={true} /> 
        })
    }

    
    
    if(!subscriptions){
        return <SubscriptionsPagePlaceholder />
    }
    else{
        return (
            <div>
            <Header background={false} />
            <Container className="content-on-bg mt-3">
              <Row>
                <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
                  <UserSidebarNav />
                  <HelpButton />
                </Col>
                <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                    <div className="welcome m-0 p-4">
                        <div className="profileInfo subscriptions mb-3">
                            <div className="p-4 pt-0 pb-0">
                                <Row>
                                <Col xl={8} md={8} lg={8} sm={12}>
                                    <h2 className="mb-4">Payments</h2>
                                </Col>
                                </Row> 
    
                                <Row>
                                <Col xl={8} md={8} lg={8} sm={12}>
                                    <div className="item inline-text">
                                        <Row>
                                            <Col xl={6} lg={6} sm={12} xs={12}>
                                                <InputGroup>
                                                    <Form.Control
                                                        id="startDate"
                                                        inline="true"
                                                        type="text"
                                                        name="start_date"
                                                        placeholder="Start Date"
                                                        onChange={this.handleDateChange}
                                                        onBlur={this.handleDateInputBlur}
                                                        onFocus={this.handleDateInputFocus}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col xl={6} lg={6} sm={12} xs={12}>
                                                <InputGroup>
                                                    <Form.Control
                                                        id="endDate"
                                                        inline="true"
                                                        type="text"
                                                        name="end_date"
                                                        placeholder="End date"
                                                        min={this.state.start_date}
                                                        onChange={this.handleDateChange}
                                                        onBlur={this.handleDateInputBlur}
                                                        onFocus={this.handleDateInputFocus}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                            
                                    </div>
                                </Col>
                                <Col xl={4} md={4} lg={4} sm={12}>
                                    <div className="mt-2">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search by reference number"
                                        onChange={this.handleSearch}
                                    />
                                    </div>
                                </Col>
                                </Row>  
                                <div className="mb-3"></div>
    
                                {subscriptionItems}
    
                                <div className={!subscriptions.length?'d-none':''}>
                                    <Button className="float-right" onClick={this.handleLoadMore} disabled={!this.state.next_page_url} variant="light">Load More</Button>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    
                </Col>
              </Row>
            </Container>
            <div className="home-bg"></div>
            <div className="overlay"></div>
            <ToastContainer/>
          </div>
        )
    }
    
  }
}

export default SubscriptionsPage
