import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import Header from '../../common/Header'
import { ToastContainer, toast } from 'react-toastify';
import { ResetPasswordApi } from '../../apis/ResetPasswordApi';
import { Auth } from '../../apis/Auth';
import { Constants } from '../../constants';

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);
    }


    state = {
        email:'',
        email_valid:false,
        errors:{
            email:''
        },
        resetPasswordRequestSent:false
    }

    validateForm = (errors) => {
        let valid = true;
    
        if(!this.state.email_valid){
            errors['email'] = 'Email is required';
        }

        this.setState({errors:errors});

        Object.values(errors).forEach(
          // if we have an error string set valid to false
          (val) => val.length > 0 && (valid = false)
        );
        
        return valid;
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        
        switch (name) {
          case 'email': 
            errors.email = 
              validEmailRegex.test(value)
                ? ''
                : 'Email is not valid!';
            break;
          default:
            break;
        }

        let fieldValid = !errors[name]?true:false;
        let fieldNameValid = name+'_valid';
      
        this.setState({errors, [name]: value, [fieldNameValid]:fieldValid})
      }
    
    handleSubmit = (e)=>{
        e.preventDefault();
        if(this.validateForm(this.state.errors)) {
            const toastId = toast.loading('Processing....',{position:'bottom-right'});
            const self = this;

            window.grecaptcha.ready(function() {
              window.grecaptcha.execute(Constants.public_key, {action: 'submit'}).then(function(token) {

                  ResetPasswordApi.forgotPassword({email:self.state.email,recaptcha_token:token})
                  .then(response=>{
                      /**submit form */
                      self.setState({resetPasswordRequestSent:true,email:'',email_valid:false});
                      toast.update(toastId,{
                          isLoading: false,
                          autoClose: 5000,
                          type:'success',
                          render:response?.data?.message
                      });
                  })
                  .catch(err=>{
                    if(err?.response?.status==422){
                        const errors = err.response.data.errors;
                        let inputErrors = self.state.errors;

                        for (const key in errors) {
                            inputErrors[key]=errors[key][0];
                        }
                        self.setState({errors:inputErrors});
                    }
                    if(err?.response?.status==404||err?.response?.status==500){
                      toast.update(toastId,{
                          isLoading: false,
                          autoClose: 5000,
                          type:'error',
                          render: err?.message??'Something went wrong'
                      });
                    }
                    else{
                      toast.update(toastId,{
                          isLoading: false,
                          autoClose: 5000,
                          type:'error',
                          render: err?.response?.data?.message??'Something went wrong'
                      });
                    }
                  });
              });
            });
            
        }
    }

   

  render() {
      if(this.state.resetPasswordRequestSent){
          return <Redirect to="/reset-password-request-confirmation"/>
      }

      if(Auth.user){
        /**you are logged in */
        return <Redirect to="/account"/>
      }

      const {errors} = this.state;
    return (
    <div>
        <Header background={false} />
        <Container className="content-on-bg">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div  className="register">
                <h1 className="title text-center mb-4">Forgot Password</h1>
                <form onSubmit={this.handleSubmit}>
                    <Row>
                        <p>Don't worry. We wil send you reset password instructions</p>
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                                type="text"
                                id="email"
                                name="email"
                                value={this.state.email} 
                                onChange={this.handleChange}
                            />
                            {errors.email.length > 0 && 
  <span className='text-danger'>{errors.email}</span>}
                        </Col>
                    </Row><br />
                    <p className="d-grid gap-2">
                        <Button type="submit" variant="primary" size="lg">Reset Password</Button>
                    </p>
                </form>
                <p className="p-2 text-center"><Link to="/signin" className="text-primary"><FontAwesomeIcon icon={faArrowLeft}/> Back to sign in</Link></p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-bg"></div>
        <div className="overlay"></div>
        <ToastContainer/>
      </div>
    )
  }
}

export default ForgotPasswordPage
