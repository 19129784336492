import { faCakeCandles, faCalendar, faLocation, faLocationDot, faMailBulk, faPhone, faTextHeight } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'

class UserDetailsProfile extends Component {
  constructor(props){
    super(props);
  }
  static defaultProps = {
    lg:4,
    md:6,
    sm:12,
    showTitle:true,
    usage:"admin"
  }
  render() {
    const { user,lg,md,sm,showTitle,usage } = this.props;
    const title = <h2 className="section-title mb-4">Profile Information</h2>
    return (
      <div className="profileInfo mb-3">
        <div className="">
          {showTitle?title:''}
          <Row>
            <Col lg={lg} md={md} sm={sm} className={usage=="public"&&!user.birthday?"d-none":""}>
              <div className="item">
                <Row>
                  <Col sm={8} xs={8}>
                    <strong>Date of birth</strong>
                    <p><small>{user.birthday?user.birthday:'?'}({user.years?user.years:'?'} years)</small></p>
                  </Col>
                  <Col sm={4} xs={4}>
                    <span className="icon">
                    <FontAwesomeIcon icon={faCakeCandles}/>
                    </span>
                    
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={lg} md={md} sm={sm} className={usage=="public"&&!user.height?"d-none":""}>
            <div className="item">
              <Row>
                <Col sm={8} xs={8}>
                  <strong>Height</strong>
                  <p><small>{user.height?user.height+'m':'?'}</small></p>
                </Col>
                <Col sm={4} xs={4}>
                  <span className="icon">
                  <FontAwesomeIcon icon={faTextHeight}/>
                  </span>
                  
                </Col>
              </Row>
              </div>
            </Col>
            <Col lg={lg} md={md} sm={sm} className={usage=="public"?"d-none":""}>
            <div className="item">
              <Row>
                <Col sm={8} xs={8}>
                  <strong>Email</strong>
                  <p><small>{user.email}</small></p>
                </Col>
                <Col sm={4} xs={4}>
                  <span className="icon">
                  <FontAwesomeIcon icon={faMailBulk}/>
                  </span>
                  
                </Col>
              </Row>
              </div>
            </Col>
            <Col lg={lg} md={md} sm={sm} className={usage=="public"?"d-none":""}>
            <div className="item">
              <Row>
                <Col sm={8} xs={8}>
                  <strong>Phone Number</strong>
                  <p><small>{user.phone?user.phone:'?'}</small></p>
                </Col>
                <Col sm={4} xs={4}>
                  <span className="icon">
                  <FontAwesomeIcon icon={faPhone}/>
                  </span>
                  
                </Col>
              </Row>
              </div>
            </Col>
            <Col lg={lg} md={md} sm={sm} className={usage=="public"&&!user.instagram?"d-none":""}>
            <div className="item">
              <Row>
                <Col sm={8} xs={8}>
                  <strong>instagram</strong>
                  <p><small>{user.instagram?user.instagram:'?'}</small></p>
                </Col>
                <Col sm={4} xs={4}>
                  <span className="icon">
                    <FontAwesomeIcon icon={faInstagram}/>
                  </span>
                  
                </Col>
              </Row>
              </div>
            </Col>
            <Col lg={lg} md={md} sm={sm} className={usage=="public"?"d-none":""}>
            <div className="item">
              <Row>
                <Col sm={8} xs={8}>
                  <strong>Location</strong>
                  <p><small>{user.location?user.location:'?'}</small></p>
                </Col>
                <Col sm={4} xs={4}>
                  <span className="icon">
                    <FontAwesomeIcon icon={faLocationDot}/>
                  </span>
                  
                </Col>
              </Row>
              </div>
            </Col>
            <Col lg={lg} md={md} sm={sm} className={usage=="public"&&!user.creationDate?"d-none":""}>
            <div className="item">
              <Row>
                <Col sm={8} xs={8}>
                  <strong>Joined</strong>
                  <p><small>{user.creationDate?user.creationDate:'?'}</small></p>
                </Col>
                <Col sm={4} xs={4}>
                  <span className="icon">
                    <FontAwesomeIcon icon={faCalendar}/>
                  </span>
                  
                </Col>
              </Row>
              </div>
            </Col>
          </Row>
        </div>
        
      </div>
    )
  }
}

export default UserDetailsProfile
