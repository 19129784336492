import axios from "axios";

const TeamApi = {
    loadMembers: (page="")=>{
        return axios.get('/teams?page='+page);
    },
    loadMoreMembers : (url)=>{
        return axios.get(url);
    },
    filterByDate : (date1,date2)=>{
        return axios.get('/teams?date1='+date1+'&date2='+date2);
    },
    searchMembers : (search)=>{
        return axios.get('/teams?search='+search);
    },
    createMember:(data)=>{
        return axios.post('/teams',data);
    },
    updateMember:(id,data)=>{
        return axios.post(`/teams/${id}`,data);
    },
    deleteMember:(id)=>{
        return axios.get(`/teams/delete/${id}`);
    },
    publishMember:(id)=>{
        return axios.get(`/teams/publish/${id}`);
    },
    unpublishMember:(id)=>{
        return axios.get(`/teams/unpublish/${id}`);
    },
    getMemberById: (id)=>{
        return axios.get(`/teams/${id}`);
    }
}

export default TeamApi;