import { faBriefcase, faEnvelope, faHome, faQuestionCircle, faUsers, faUserCircle,
  faIdBadge, faCodePullRequest, faMoneyBill, faArrowCircleLeft, faComments, faCogs } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Nav } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Auth } from '../../apis/Auth';

export class UserSidebarNav extends Component {
    state = {
        loggedOut:false
    }

    logout = ()=>{
        if(Auth.logout()){
          this.setState({loggedOut:true});
        }
      }

render() {
 if(this.state.loggedOut){
     return <Redirect to="/signin"/>
 }
let userSignInButtons = '';

if(!Auth.user){
userSignInButtons = <Nav className="flex-column">
<Nav.Link as={Link} to="/signin">Signin</Nav.Link>
<Nav.Link as={Link} to="/register">Register</Nav.Link>
</Nav>
}

if(Auth.userIsAdmin()){
 return (
     <div>
         <div className="sidebar">
             <Nav className="flex-column">
                 <Link className="nav-link"    to="/account">
                     <FontAwesomeIcon icon={faUserCircle} /> Profile</Link>
                 <Link className="nav-link"    to="/inquiries">
                 <FontAwesomeIcon icon={faCodePullRequest} /> Inquiries</Link>
                 <Link className="nav-link"   to="/users">
                 <FontAwesomeIcon icon={faUsers} /> Users</Link>
                 <Link className="nav-link"    to="/subscriptions">
                 <FontAwesomeIcon icon={faMoneyBill} /> Payments</Link>
                 <Link className="nav-link"    to="/staff">
                 <FontAwesomeIcon icon={faUserCircle} /> Staff</Link>
                 <Link className="nav-link"    to="/settings">
                 <FontAwesomeIcon icon={faCogs} /> Settings</Link>
                 <a className="nav-link desk-logout" onClick={this.logout} ><FontAwesomeIcon icon={faArrowCircleLeft} /> Logout</a>
             </Nav>
         </div>
     </div>
 )
}
else if(Auth.user){
 return (
     <div>
         <div className="sidebar">
             <Nav className="flex-column">
                 <Link className="nav-link" to="/account">
                     <FontAwesomeIcon icon={faUserCircle} /> Profile</Link>
                 <Link className="nav-link" to="/subscription">
                 <FontAwesomeIcon icon={faIdBadge} /> Subscription</Link>
                 <a className="nav-link desk-logout" onClick={this.logout}><FontAwesomeIcon icon={faArrowCircleLeft} /> Logout</a>
             </Nav>
         </div>
     </div>
     
 )
}
}
}

export default UserSidebarNav
