import SEO from '@americanexpress/react-seo'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import { Constants } from '../../constants'
import NotFoundBg from "../../assets/images/not_found_error.png"
import { Link } from 'react-router-dom'

class PageNotFound extends Component {
  render() {
    return (
        <div className="NotFoundPage">
        <SEO
          title="404 Error - Afrifame Models"
          description="Your request was not found"
          keywords={['Rwandese talents', 'Rwandese actors']}
          siteUrl={Constants.siteUrl+'/about'}
        />

        <Header background={false} />
        <Container>
            <Row>
                <Col lg={{span:8,offset:2}}>
                    <div className="wrapper mt-4 mb-5">
                    <div className="mb-4 text-center">
                        <img src={NotFoundBg} alt="404 error" />
                        <h2 className="mb-4 mt-5">Oops! Looks like you are lost.</h2>
                        <p className="mb-4">Maybe this page used to exist or you just spelled something wrong. Chances are you spelled something wrong, so can you double check the URL?</p>
                        <Link to="/" className="btn btn-primary">Return Home</Link>
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
        <Footer/>
      </div>
    )
  }
}

export default PageNotFound
