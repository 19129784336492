import React, { Component } from 'react'
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap'
class VerifyAccountCode extends Component {
    state={
        show:false,
        verification_code:''
    }
    handleInputChange = (e)=>{
      this.setState({verification_code:e.target.value});
    }

    handleClose = ()=>{
        this.setState({show:false});
    }

    handleSubmit = (e)=>{
        e.preventDefault();
        if(this.state.verification_code){
            this.props.handleVerificationCode(this.state.verification_code);
        }
    }

  render() {
    return (
      <div>
        <Modal 
            show={this.props.show} 
            onHide={this.props.handleClose} 
            size="md"
            centered
            fullscreen="md-down"
            className="text-dark verify-account-modal"
            aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                <Modal.Title>Not a bot?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-3">
                        It seems your are like a bot. If not Enter the code we sent you via {this.props.email} below and click Verify.
                    </div>
                    <form onSubmit={this.handleSubmit}>
                    <InputGroup>
                        <FormControl
                            type="text"
                            name="verification_code"
                            onChange={this.handleInputChange}
                            placeholder="Enter verification code here"
                            inline
                            />
                        <Button type="submit" className="btn-sm">Verify</Button>
                    </InputGroup>
                    </form>
                </Modal.Body>
            </Modal>
      </div>
    )
  }
}

export default VerifyAccountCode
