import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class AcceptTermsRequired extends Component {
  render() {
      const { acceptedTerms } = this.props;
    return (
      <div className={acceptedTerms==2?"subscription":'d-none'}>
        <Row>
            <Col xs={4} md={3} lg={2} className="leftside">
                <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="xl"/>
                </div>
            </Col>
            <Col xs={8} md={9} lg={10}>
                <h2>Terms and Conditions</h2>
                <p>You declined our terms and conditions. Note that you cannot do anything further before you accept them.</p>
                <Link onClick={this.props.acceptTermsHandler} className="btn btn-primary" as={Button}>Accept Terms</Link>
            </Col>
        </Row>
      </div>
    )
  }
}

export default AcceptTermsRequired
