import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../../common/Header'
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

class ResetPasswordConfirmationPage extends Component {
    state = {
        accountType:''
    }


  render() {
    return (
    <div>
        <Header background={false} />
        <Container className="content-on-bg">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div  className="register text-center">
                <h1 className="title text-center mb-4">Password Reset</h1>
                <div className="circle envelope">
                    <FontAwesomeIcon icon={faCheck}/>
                </div>
                <p>Your password have been reset successfully</p>
                <p className="p-2 text-center"><Link to="/signin" className="text-primary"><FontAwesomeIcon icon={faArrowLeft}/> Back to sign in</Link></p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-bg"></div>
        <div className="overlay"></div>
      </div>
    )
  }
}

export default ResetPasswordConfirmationPage
