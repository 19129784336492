import React, { Component } from 'react'
import PlaceholderLoading from 'react-placeholder-loading'
export class ParagraphPlaceholder extends Component {
    constructor(props){
        super(props);
        
    }
    static defaultProps ={
        block:false,
        height:20
    }

  render() {
      const { block,height } = this.props;
    return (
      <div className={block?"paragraph block":"paragraph"}> 
          <PlaceholderLoading width={100} height={height} shape="rect"/>
      </div>
    )
  }
}

export default ParagraphPlaceholder
