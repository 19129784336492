
class ConstantVariables {
    // publicUrl='http://localhost:8000';
    publicUrl='https://modelsapi.afrifame.rw';

    // siteUrl = "http://localhost:3000";
    siteUrl = "https://modelsdemo.afrifame.rw";

    public_key="6LeoWGMgAAAAAC4GsT2XuaugVuJpE1IVn7aIdmYy";

    formatDate = (date)=>{
        let today = new Date(date);
        let dd = today.getDate();

        let mm = today.getMonth()+1; 
        const yyyy = today.getFullYear();
        if(dd<10) 
        {
            dd=`0${dd}`;
        } 

        if(mm<10) 
        {
            mm=`0${mm}`;
        } 
        today = `${mm}-${dd}-${yyyy}`;
        return today;
    }
}

const Constants = new ConstantVariables();

export {Constants}