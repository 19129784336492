import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Constants } from '../../../constants';

class InquiryItem extends Component {
  render() {
      const { inquiry } = this.props;

    return (
        <div className="item p-0">
            <div className="p-2">
        <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="item no-border">
                <div className="mb-2">
                    <span className="text-muted no-border p-0 mb-3">Customer</span> <br />
                    <strong>{inquiry.name}</strong>
                </div>
                <strong>#{inquiry.company}</strong>
            </div>
                
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="item no-border">
                    <Row>
                    <Col sm={12} xs={12}>
                        <Button className="icon inquiry-btn-cicle btn-primary" id={inquiry.id} onClick={()=>{this.props.showDetails(inquiry.id)}}>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </Button>
                        
                    </Col>
                    <Col sm={12} xs={12}>
                        <p className="text-muted booked-on no-border mt-3">
                            Booked on: {Constants.formatDate(inquiry.created_at)}
                        </p>
                    </Col>
                    </Row>
                </div>
            </Col>
        </Row>
        </div>
        
        <div className="border-top p-2 pt-3 pb-3">
            <div className="mb-2">
                <small className="text-muted no-border">
                    Talents
                </small>
            </div>
            <div>
            {inquiry.talents.map((talent)=>{
                return <span className="talent-item border p-2 mr-2" key={talent.id}>{talent.first_name} {talent.last_name}</span>
            })}
            </div>
        </div>
    </div>
    )
  }
}

export default InquiryItem
