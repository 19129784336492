import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Badge, Button, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { Constants } from '../../../constants';
import avatar from '../../../assets/images/avatar.png';

export class UserItem extends Component {
    

  render() {
    const { user } = this.props;

    const rolesColors = {Model:'success',Actor:'warning',Admin:'info'};
    let roles = '';
    if(user){
      roles = user.roles.map((role)=>{
          return <Badge key={role.id} bg={rolesColors[role.name]} className="mr-1">{role.name}</Badge>;
      })
    }
  
    return (
        <Col xs={6} sm={6} md={6} lg={4}>
            <div className="user-photo-wrapper">
                <div className="user-photos">
                    <Link to={'/users/'+user.id}>
                        <img className="w-100" src={user.photo1?Constants.publicUrl+'/images/'+user.photo1:avatar} alt={user.first_name+' '+user.last_name} />
                    </Link>
                    <Button type="button" className="upload-button" variant={user.subscription_valid?"success":"danger"} title={user.subscription_valid?'Subscription is Active':'Subscription expired'} size="sm">
                        <FontAwesomeIcon icon={user.subscription_valid?faCheck:faClose}/>
                    </Button>
                </div>
                <div className="details">
                <Link to={'/users/'+user.id}>
                    <h2 className="title">{user.first_name} {user.last_name}</h2>
                </Link>
                {roles}
                </div>
                <div className="border-bottom-1"></div>
                <div className="border-bottom-2"></div>
            </div>
        </Col>
    )
  }
}

export default UserItem
