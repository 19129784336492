
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class PaySubscriptionPage extends Component {
  render() {
    return (
      <div className="dpo-placeholder">
          <p>
            <strong>
                Redireting to DPO...
            </strong>
          </p>
          <div className="text-center">
            <Link to="/users">
                    Go back
            </Link>
          </div>
          
      </div>
    )
  }
}

export default PaySubscriptionPage
