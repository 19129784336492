import userEvent from '@testing-library/user-event'
import React, { Component } from 'react'
import { ProgressBar, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import UserName from './UserName'

export class ProfileInfo extends Component {
  render() {
    const { user } = this.props;
    let completion = 0;
    completion += user.first_name?1:0;
    completion += user.last_name?1:0;
    completion += user.gender?1:0;
    completion += user.email?1:0;
    completion += user.phone?1:0;
    completion += user.height?1:0;
    completion += user.location?1:0;
    completion += user.instagram?1:0;

    const completionPercentage = completion*100/8;

    return (
      <div>
        <UserName user={user}/>
        <div className="profileInfo mb-3">
          <h2 className="section-title">
            Profile Information
            <span className="float-right">
              <small>Completed {completion}/8</small>
              <ProgressBar now={completionPercentage} variant="success" />
            </span>
          </h2>
          <div className="clearfix"></div>
<Table responsive className="w-100 mt-3 table">
  <tbody>
    <tr>
      <td>Name</td>
      <td>
        <div className="text-right">{user.first_name} {user.last_name}</div>
      </td>
    </tr>
    <tr>
      <td>Gender</td>
      <td>
        <div className={user.gender?"text-right":'text-right text-danger'}>{user.gender??'Not yet provided'}</div>
      </td>
    </tr>
    <tr>
      <td>Birthdate</td>
      <td>
        <div className={user.birthday?"text-right":'text-right text-danger'}>{user.birthday??'Not Provided yet'}</div>
      </td>
    </tr>
    <tr>
      <td>Email</td>
      <td>
        <div className={user.email?"text-right":'text-right text-danger'}>{user.email??'Not yet provided'}</div>
      </td>
    </tr>
    <tr>
      <td>Phone</td>
      <td>
        <div className={user.phone?"text-right":'text-right text-danger'}>{user.phone??'Not yet provided'}</div>
      </td>
    </tr>
    <tr>
      <td>Location</td>
      <td>
        <div className={user.location?"text-right":'text-right text-danger'}>{user.location??'Not yet provided'}</div>
      </td>
    </tr>
    <tr>
      <td>Height</td>
      <td>
        <div className={user.height?"text-right":'text-right text-danger'}>{user.height?user.height+'m':'Not yet provided'}</div>
      </td>
    </tr>
    <tr>
      <td>Instagram</td>
      <td>
        <div className={user.instagram?"text-right":'text-right text-danger'}>{user.instagram??'Not yet provided'}</div>
      </td>
    </tr>
  </tbody>
</Table>
<Link to="/update-profile" className="btn btn-primary float-right">Update Profile Info</Link>
<div className="clearfix"></div>
        </div>

        {/* Photos */}
      </div>
    )
  }
}

export default ProfileInfo
