import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import '../../assets/css/get-in-touch.css';
class GetInTouch extends Component {
  render() {
    return (
      <div className="item p-5">
          <div className="item-wrapper">
            <h2 className="title mb-4">
                Let’s <br /> get in touch.
            </h2>
            <p>Let’s help you to work with best models & actors in country</p>
            <Link to="/contact" className="btn btn-primary btn-md btn-home">
                Contact Us &nbsp;<FontAwesomeIcon icon={faArrowRight}/>
            </Link>
          </div>
          <div className="bg-image"></div>
          <div className="overlay"></div>
      </div>
    )
  }
}

export default GetInTouch
