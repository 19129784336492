import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class UserDetailsForm extends Component{
    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    saveAndContinue = (e) => {
        e.preventDefault();
        this.props.createUser();
    };

    render(){
        const formValid = this.props.inputValues.firstName
                        &&this.props.inputValues.email
                        &&validEmailRegex.test(this.props.inputValues.email)
                        &&this.props.inputValues.phone
                        &&this.props.inputValues.gender;

        const { errors } = this.props.inputValues;

        return (
            <div>
                <p className="alert alert-info mb-3">Fill all required fields(<span className="text-danger">*</span>) and click Save button to add New User</p>
                <form>
                    <Row>
                        <Col lg={6} sm={12}>
                        <   Form.Label htmlFor="firstName">First Name<sup className="text-danger">*</sup></Form.Label>
                            <Form.Control
                                        label="First Name"
                                        name="firstName"
                                        type='text'
                                        id='firstName'
                                        defaultValue={this.props.inputValues.firstName}
                                        onChange={this.props.handleChange}
                                    />
                            {errors.first_name.length > 0 && 
  <span className='text-danger'>{errors.first_name}</span>}
  <br />
                        </Col>
                        <Col lg={6} sm={12}>
                            <Form.Label htmlFor="lastName">Last Name</Form.Label>
                            <Form.Control
                                    label="Last Name"
                                    name="lastName"
                                    type='text'
                                    id='lastName'
                                    defaultValue={this.props.inputValues.lastName}
                                    onChange={this.props.handleChange}
                                />
                            {errors.last_name.length > 0 && 
  <span className='text-danger'>{errors.last_name}</span>}
  <br />
                        </Col>
                        <Col lg={6} sm={12}>
                            <Form.Label htmlFor="email">Email<sup className="text-danger">*</sup></Form.Label>
                            <Form.Control
                                    name="email"
                                    type='text'
                                    id='email'
                                    defaultValue={this.props.inputValues.email}
                                    onChange={this.props.handleChange}
                                />
                            {errors.email.length > 0 && 
  <span className='text-danger'>{errors.email}</span>}
  <br />
                        </Col>

                        <Col lg={6} sm={12}>
                            <Form.Label htmlFor="email">Phone<sup className="text-danger">*</sup></Form.Label>
                            <Form.Control
                                name="phone"
                                type='text'
                                id='phone'
                                defaultValue={this.props.inputValues.phone}
                                onChange={this.props.handleChange}
                            />
                            {errors.phone.length > 0 && 
  <span className='text-danger'>{errors.phone}</span>}
                            <br/>
                            
                        </Col>
                        
                        <Col lg={6} sm={6} xs={6}>
                            <div key="radio" className="mb-3">
                                <Form.Check type="radio" id="female">
                                    <Form.Check.Input name="gender" checked={this.props.inputValues.gender=='Female'?true:false} value="Female" onChange={this.props.handleChange} type="radio"/>
                                    <Form.Check.Label>Female</Form.Check.Label>
                                </Form.Check>
                            </div>
                        </Col>
                        <Col lg={6} sm={6} xs={6}>
                        <div key="radio" className="mb-3">
                            <Form.Check type="radio" id="male">
                                <Form.Check.Input name="gender" value="Male" checked={this.props.inputValues.gender=='Male'?true:false} onChange={this.props.handleChange} type="radio"/>
                                <Form.Check.Label>Male</Form.Check.Label>
                            </Form.Check>
                        </div>
                        </Col>
                        <Col xs={12}>
                        {errors.gender.length > 0 && 
  <span className='text-danger'>{errors.gender}</span>}
                        </Col>

                        <Col lg={6} sm={12}>
                        <br/>
                            <Form.Label htmlFor="birthday">Birthdate<sup className="text-danger">*</sup></Form.Label>
                            <Form.Control
                                name="birthday"
                                type='date'
                                id='birthday'
                                defaultValue={this.props.inputValues.birthday}
                                onChange={this.props.handleChange}
                            />
                            {errors.birthday.length > 0 && 
  <span className='text-danger'>{errors.birthday}</span>}
                            <br/>
                            
                        </Col>

                        
                        <Col lg={6} sm={12}><br/>
                            <Form.Label htmlFor="location">Location</Form.Label>
                            <Form.Control
                                name="location"
                                type='text'
                                id='location'
                                defaultValue={this.props.inputValues.location}
                                onChange={this.props.handleChange}
                            />
                            {errors.location.length > 0 && 
  <span className='text-danger'>{errors.location}</span>}
                            <br/>
                            
                        </Col>

                        <Col lg={6} sm={12}>
                            <Form.Label htmlFor="height">Height</Form.Label>
                            <Form.Control
                                name="height"
                                type='text'
                                id='height'
                                defaultValue={this.props.inputValues.height}
                                onChange={this.props.handleChange}
                            />
                            {errors.height.length > 0 && 
  <span className='text-danger'>{errors.height}</span>}
                            <br/>
                            
                        </Col>
                        
                        <Col lg={6} sm={12}>
                            <Form.Label htmlFor="instagram">Instagram</Form.Label>
                            <Form.Control
                                name="instagram"
                                type='text'
                                id='instagram'
                                defaultValue={this.props.inputValues.instagram}
                                onChange={this.props.handleChange}
                            />
                            {errors.instagram.length > 0 && 
  <span className='text-danger'>{errors.instagram}</span>}
                            <br/>
                            
                        </Col>

                    </Row>
                    <hr />
                        
                    <div className="text-right mt-3">
                        <Button variant="primary" className="float-right" disabled={!formValid} onClick={this.saveAndContinue}>Create User</Button>
                        <Button variant="light" className="float-right" onClick={this.back}>Back</Button>&nbsp;
                        
                    </div>
                </form> 
            </div>
        )
    }
    
}
export default UserDetailsForm