import React, { Component } from 'react'
import { Alert, Button, Col, Form, FormControl, FormLabel, FormText, Modal, Row } from 'react-bootstrap';
import ModelActorPlaceholder from '../../assets/images/model-placeholder.png';
import SingleSelectedModelActor from './SingleSelectedModelActor';
import '../../assets/css/booking.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getCurrentDate } from '../../utils/GetCurrentDate';
import { ToastContainer,toast } from 'react-toastify';
import { HomeApi } from '../../apis/HomeApi';
import VerifyAccountCode from '../VerifyAccountCode';
import { Constants } from '../../constants';

const validEmailRegex = 
RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class Booking extends Component {
    constructor(props){
        super(props);

        this.state = {
            show:false,
            processing:false,
            booked:false,
            name:'',
            email:'',
            phone:'',
            company:'',
            start_date:'',
            end_date:'',
            message:'',
            name_valid:false,
            email_valid:false,
            phone_valid:false,
            company_valid:false,
            start_date_valid:false,
            end_date_valid:false,
            message_valid:false,
            errors: {
                name:'',
                email:'',
                phone:'',
                company:'',
                start_date:'',
                end_date:'',
                message:''
            }
        }
        //showVerifyAccount:false,
        // verification_email:'',
        // verificationCode:''
    }
    
    

    validateForm = (errors) => {
        let valid = true;
        
        if(!this.state.name_valid){
            errors['name'] = 'Your Name is required';
        }
        if(!this.state.email_valid){
            errors['email'] = 'Your email is required';
        }
        if(!this.state.phone_valid){
            errors['phone'] = 'Your phone number is required';
        }
        if(!this.state.company_valid){
            errors['company'] = 'Company name is required';
        }
        if(!this.state.start_date_valid){
            errors['start_date'] = 'Start date is required';
        }
        if(!this.state.end_date_valid){
            errors['end_date'] = 'End date is required';
        }
        if(!this.state.message_valid){
            errors['message'] = 'Message is required';
        }

        this.setState({errors:errors});

        Object.values(errors).forEach(
          // if we have an error string set valid to false
          (val) => val.length > 0 && (valid = false)
        );

        if(!this.props.selectedItems.length){
            toast.error('Please select Models or Actors to book',{position:"bottom-right"});
            valid = false;
        }
        
        return valid;
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        
        switch (name) {
          case 'name': 
            errors.name = 
              value.length <= 0
                ? 'Your name is required'
                : '';
            break;
          case 'email': 
            errors.email = 
              validEmailRegex.test(value)
                ? ''
                : 'Email is not valid!';
            break;
          case 'phone': 
            errors.phone = 
              value.length < 4
                ? 'Phone is required'
                : '';
            break;
        case 'company': 
            errors.company = 
              value.length < 3
                ? 'Company name is required'
                : '';
            break;
        case 'start_date': 
            errors.start_date = 
              value.length < 8
                ? 'Sart date is required'
                : '';
            break;
        case 'end_date': 
            errors.end_date = 
              value.length < 8
                ? 'End date is required'
                : '';
            break;
        case 'message': 
            errors.message = 
              value.length < 8
                ? 'Message is required'
                : '';
            break;
          default:
            break;
        }

        let fieldValid = !errors[name]?true:false;
        let fieldNameValid = name+'_valid';
      
        this.setState({errors, [name]: value, [fieldNameValid]:fieldValid})
    }
      

    handleRemoveItem = (e)=>{
        this.props.handleRemoveItem(e.target.value);
    }

    handleClose = () => {
        this.setState({show:false});
        if(this.props.handleClose){
            this.props.handleClose();
        }
    }
    handleCloseSuccess = ()=>{
        this.setState({booked:false,show:false})
    }
    handleShow = () => {
        this.setState({show:true});
    }

    handleSubmit = (e)=>{
        e.preventDefault();
        if(this.validateForm(this.state.errors,["name","email","phone","company","start_date","end_date","message"])) {
            const self = this;

            window.grecaptcha.ready(function() {
                window.grecaptcha.execute(Constants.public_key, {action: 'submit'}).then(function(token) {
                    let itemIds = [];
                    self.props.selectedItems.forEach((val)=>{
                        itemIds.push(val.id);
                    });

                    const data = {
                        recaptcha_token:token,
                        talents: itemIds,
                        name: self.state.name,
                        email: self.state.email,
                        phone: self.state.phone,
                        company: self.state.company,
                        start_date: self.state.start_date,
                        end_date: self.state.end_date,
                        message: self.state.message
                    }
                    //verificationCode:self.state.verificationCode

                    console.log(data);

                    const toastId = toast.loading('Booking your talents...',{position:'bottom-right'});

                    HomeApi.bookTalents(data)
                      .then(()=>{
                        self.setState({processing:false,booked:true,show:false});

                        toast.dismiss(toastId);
                        self.props.handleBookingSuccess();
                      })
                      .catch((err)=>{
                        self.setState({processing:false});

                        if(err?.response?.status==422){
                            console.log(err);
                            /**allow user to verify account with code */
                            // if(err.response.data.verify_account){
                            //     self.setState({showVerifyAccount:true,verificationCode:''});
                            // }



                            const errors = err.response.data.errors;
                            let inputErrors = self.state.errors;
          
                            for (const key in errors) {
                                inputErrors[key]=errors[key][0];
                            }
                            self.setState({errors:inputErrors});
                        }
                        if(err?.response?.status==404||err?.response?.status==500){
                          toast.update(toastId,{
                              isLoading: false,
                              autoClose: 5000,
                              type:'error',
                              render: err?.message??'Something went wrong'
                          });
                        }
                        else{
                          toast.update(toastId,{
                              isLoading: false,
                              autoClose: 5000,
                              type:'error',
                              render: err?.response?.data?.message??'Something went wrong'
                          });
                        }
                    });
                });
            });
        }
    }

    processBooking = ()=>{
        document.getElementById('bookingBtn').click();
    }





    // handleVerificationCodeClose = ()=>{
    //     this.setState({showVerifyAccount:false})
    // }
    // handleVerificationCode = (code)=>{console.log(code);
    //     this.setState({verificationCode:code,showVerifyAccount:false});

    //     this.processBooking();
    // }


  render() {
    const { selectedItems } = this.props;
    const errors  = this.state.errors;

    let selectedItemsHtml = '';

    if(selectedItems.length){
        selectedItemsHtml = selectedItems.map((val,index)=>{
            return <SingleSelectedModelActor user={val} key={index} onRemoveItem={this.handleRemoveItem}/>
        });

        const selectedItemsHtmlModal = selectedItems.map((val,index)=>{
            return <SingleSelectedModelActor col={4} user={val} key={index} onRemoveItem={this.handleRemoveItem}/>
        });


        return (
            <div className="booking">
              <div className="start-booking">
                  <h2 className="title booking-title">Start Booking</h2>
                  
                  {selectedItemsHtml}
                  
                  <Button className="btn btn-primary btn-md btn-home" onClick={this.handleShow}>
                  Proceed to Booking
                  </Button>
              </div>
              <Modal 
                show={this.state.show||this.props.show} 
                onHide={this.handleClose} 
                size="lg"
                centered
                fullscreen="md-down"
                className="text-dark"
                aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header closeButton>
                    <Modal.Title>Complete booking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-block">
                            <p><strong>Talents you needs for your work...</strong></p>
                            <div className="modal-selected-items">
                                <Row>
                                    {selectedItemsHtmlModal}
                                </Row>
                            </div>
                            <strong className="text-primary">
                                <FontAwesomeIcon icon={faPlus}/> Add more talents
                            </strong>
                            <div className="border mt-2 mb-2"></div>
                            <div className="mb-3 mt-3">
                                <strong>Your Information</strong>
                            </div>
                            <form id="bookingFrm" onSubmit={this.handleSubmit}>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <FormLabel>Full Name</FormLabel>
                                        <FormControl name="name" type="text" 
                                        onChange={this.handleChange} disabled={this.state.processing}/>
                                        {errors.name.length > 0 && 
    <div className='text-danger mb-2'>{errors.name}</div>}  
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <FormLabel>Email</FormLabel>
                                        <FormControl name="email" type="text"
                                         onChange={this.handleChange} 
                                         disabled={this.state.processing}/>
                                        {errors.email.length > 0 && 
    <div className='text-danger mb-2'>{errors.email}</div>}  
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <FormLabel>Phone</FormLabel>
                                        <FormControl name="phone" 
                                        type="text" onChange={this.handleChange} 
                                        disabled={this.state.processing}/>
                                        {errors.phone.length > 0 && 
    <div className='text-danger mb-2'>{errors.phone}</div>}  
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <FormLabel>Company</FormLabel>
                                        <FormControl name="company"
                                         type="text" onChange={this.handleChange} 
                                         disabled={this.state.processing}/>
                                        {errors.company.length > 0 && 
    <div className='text-danger mb-2'>{errors.company}</div>}  
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <FormLabel>Work will start</FormLabel>
                                        <FormControl type="date" 
                                        min={getCurrentDate()} 
                                        name="start_date" 
                                        onChange={this.handleChange} 
                                        disabled={this.state.processing}/>
                                        {errors.start_date.length > 0 && 
    <div className='text-danger mb-2'>{errors.start_date}</div>}  
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <FormLabel>Work will end</FormLabel>
                                        <FormControl 
                                        type="date" 
                                        min={this.state.start_date??getCurrentDate()} 
                                        name="end_date" 
                                        onChange={this.handleChange} 
                                        disabled={this.state.processing}/>
                                        {errors.end_date.length > 0 && 
    <div className='text-danger mb-2'>{errors.end_date}</div>}  
                                    </Col>
                                    <Col md={12} xs={12}>
                                        <FormLabel>Message</FormLabel>
                                        <FormControl 
                                            as="textarea"
                                            placeholder="Type in your message"
                                            style={{ height: '100px' }}
                                            name="message" onChange={this.handleChange}
                                            disabled={this.state.processing}
                                        />
                                        {errors.message.length > 0 && 
    <div className='text-danger mb-2'>{errors.message}</div>}  
                                    </Col>
                                </Row>
                                <button type="submit"  data-action='book-talents' className="btn btn-outline-primary d-none" id="bookingBtn"></button>
                            </form>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="dark" onClick={this.handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="submit-booking" disabled={this.state.processing} onClick={this.processBooking}>
                        Submit your booking
                    </Button>
                    </Modal.Footer>
                </Modal>
                <ToastContainer />

                {/* <VerifyAccountCode email="ebaziyaka@gmail.com" show={this.state.showVerifyAccount} handleClose={this.handleVerificationCodeClose} handleVerificationCode={this.handleVerificationCode} /> */}
            </div>
        )
    }
    return (
      <div className="booking">
        <div className="start-booking">
            <h2 className="title booking-title">Start Booking</h2>
            
            <div className="image text-center">
                <img src={ModelActorPlaceholder} width="140" height="140" alt="actors"/>
            </div>
            <p>
                Select actors to get started. They will  appear here before proceeding!
            </p>
            <Button className="disabled btn btn-primary btn-md btn-home">
            Proceed to Booking
            </Button>
        </div>

        <Modal 
            show={this.state.booked} 
            onHide={this.handleCloseSuccess} 
            size="lg"
            centered
            fullscreen="md-down"
            className="text-dark success-modal"
            aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-3">
                        Your booking was received successfully. We will get back to you soon. Thank you
                    </div>
                </Modal.Body>
            </Modal>
      </div>
    )
  }
}

export default Booking
