import SEO from '@americanexpress/react-seo'
import React, { Component } from 'react'
import { Button, Col, Container, FormControl, FormGroup, FormLabel, Row, Spinner } from 'react-bootstrap'
import image from "../../assets/images/akazungu.jpg"
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import { Constants } from '../../constants'
import avatar  from "../../assets/images/avatar.png";
import { Link, withRouter } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HomeApi } from '../../apis/HomeApi'
import {ToastContainer ,toast} from "react-toastify";

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);


class TeamMemberPage extends Component {
  constructor({match}){
    super();

    this.state =  {
      id:match.params.id,
      team:"",
      loading:false,
      name:'',
      email:'',
      subject:'',
      message:'',
      name_valid:false,
      email_valid:false,
      subject_valid:false,
      mesage_valid:false,
      errors:{
          name:'',
          email:'',
          subject:'',
          message:''
      },
      processing:false,
      messageSent:false
    }
  }
  
  componentDidMount(){
    document.documentElement.scrollTop=0;
    this.setState({loading:true});
    HomeApi.getTeamMember(this.state.id)
      .then(res=>{
        this.setState({team:res.data,loading:false});
      })
      .catch(()=>{
        this.setState({loading:false});
      });
  }



  validateForm = (errors) => {
    let valid = true;
    
    if(!this.state.name_valid){
        errors['name'] = 'Name is required';
    }
    if(!this.state.email_valid){
        errors['email'] = 'Email is required';
    }
    if(!this.state.subject_valid){
        errors['subject'] = 'Subject is required';
    }
    if(!this.state.message_valid){
      errors['message'] = 'Message is required';
    }

    this.setState({errors:errors});

    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    
    return valid;
}

handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    
    switch (name) {
      case 'name': 
        errors.name = 
          value.length < 2
            ? 'Name must be 2 characters long!'
            : '';
        break;
      case 'email': 
        errors.email = 
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';
        break;
      case 'subject': 
        errors.subject = 
          value.length < 8
            ? 'Subject is required!'
            : '';
        break;
      case 'message': 
        errors.message = 
          value.length < 8
            ? 'Message is required!'
            : '';
        break;
      default:
        break;
    }

    let fieldValid = !errors[name]?true:false;
    let fieldNameValid = name+'_valid';

  
    this.setState({errors, [name]: value, [fieldNameValid]:fieldValid})
  }

  handleSubmit = (e)=>{

    e.preventDefault();

    if(this.validateForm(this.state.errors,["name","email","subject","message"])) {
      const toastId = toast.loading('Sending message..',{position:'bottom-right'});

      this.setState({processing:true});
      const self = this;

      window.grecaptcha.ready(function() {
        window.grecaptcha.execute(Constants.public_key, {action: 'submit'}).then(function(token) {
           HomeApi.sendMessage(self.state.id, {
            name:self.state.name,
            subject: self.state.subject,
            email:self.state.email,
            message:self.state.message,
            recaptcha_token:token
          })
          .then(()=>{
            self.setState({processing:false,messageSent:true,name:'',email:'',subject:'',message:''});
            toast.update(toastId,{
              isLoading:false,
              autoClose:5000,
              render: 'Message Sent successfully',
              type:'success'              
            });

          })
          .catch((err)=>{
            self.setState({processing:false});
            
            if(err?.response?.status==422){
                const errors = err.response.data;
                console.log(err);
                let inputErrors = self.state.errors;

                for (const key in errors) {
                    inputErrors[key]=errors[key][0];
                }
                self.setState({errors:inputErrors});
            }
            if(err?.response?.status==404||err?.response?.status==500){
              toast.update(toastId,{
                  isLoading: false,
                  autoClose: 5000,
                  type:'error',
                  render: err?.message??'Something went wrong'
              });
            }
            else{
              toast.update(toastId,{
                  isLoading: false,
                  autoClose: 5000,
                  type:'error',
                  render: err?.response?.data?.message??'Something went wrong'
              });
            }
          });
        });
      });
    }

  }


  render() {
    const { team, errors, loading, processing } = this.state;

    return (
      <div className="AboutPage">
        <SEO
          title="About Us - Afrifame Models"
          description="Don't be shy. Please share your experience with us or let us know where we can improve"
          keywords={['Rwandese talents', 'Rwandese actors']}
          siteUrl={Constants.siteUrl+'/about'}
          image={{
            src: image
          }}
        />

        <Header background={false} />
        <Container>
            <div className="wrapper mt-4 mb-3">
              {team?
              <div className="mb-4">
                <div className="mb-4">
                  <Row>
                    <Col lg={3} md={4} sm={12} className="mb-5">
                      <div className="team-pub-image">
                        <img src={team.photo?Constants.publicUrl+'/images/'+team.photo:avatar} className="img-responsive ceo-img" />
                      </div>
                    </Col>

                    <Col lg={9} md={8} sm={12}>
                      <div className="welcome-note mb-3">
                          <h3 className="mb-4">{team.name}</h3>
                        {team.bio}
                      </div>
                      <div className="social">
                        {team.facebook_url?
                        <Link className="btn btn-md" target="_blank" to={{pathname:"https://facebook.com"}}>
                          <FontAwesomeIcon icon={faFacebook}/>
                        </Link>
                        :""}
                        {team.twitter_url?
                        <Link className="btn btn-md" target="_blank" to={{pathname:"https://facebook.com"}}>
                          <FontAwesomeIcon icon={faTwitter}/>
                        </Link>:""}
                        {team.instagram_url?
                        <Link className="btn btn-md" target="_blank" to={{pathname:"https://facebook.com"}}>
                          <FontAwesomeIcon icon={faInstagram}/>
                        </Link>:""}
                        {team.linkedin_url?
                        <Link className="btn btn-md" target="_blank" to={{pathname:"https://facebook.com"}}>
                          <FontAwesomeIcon icon={faLinkedin}/>
                        </Link>:""}
                      </div>
                    </Col>
                  </Row>
                </div>


                <div className="team">
                  <h2 className="title mb-4">Text me</h2>
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <FormLabel>Name</FormLabel>
                      <FormControl 
                        type="text"
                        placeholder="Type in your name"
                        disabled={processing?true:false}
                        value={this.state.name} 
                        onChange={this.handleChange}
                        name="name"
                        />
                        {errors.name.length > 0 && 
  <div className='text-danger'>{errors.name}</div>}   
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Email</FormLabel>
                      <FormControl 
                        type="text"
                        placeholder="Type in your email"
                        disabled={processing?true:false}
                        value={this.state.email} 
                        onChange={this.handleChange}
                        name="email"
                        />
                        {errors.email.length > 0 && 
  <div className='text-danger'>{errors.email}</div>} 
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Subject</FormLabel>
                      <FormControl 
                        type="text"
                        placeholder="What is your message about?"
                        disabled={processing?true:false}
                        value={this.state.subject} 
                        onChange={this.handleChange}
                        name="subject"
                        />
                        {errors.subject.length > 0 && 
  <div className='text-danger'>{errors.subject}</div>} 
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Message</FormLabel>
                      <FormControl 
                        as="textarea"
                        placeholder="Type in your message"
                        disabled={processing?true:false}
                        value={this.state.message} 
                        onChange={this.handleChange}
                        name="message"
                        />
                        {errors.message.length > 0 && 
  <div className='text-danger'>{errors.message}</div>} 
                    </FormGroup>
                    <div className="text-right">
                      <Button type="submit" data-action='contact-us'>Send</Button>
                    </div>
                  </form>
                    
    
                </div>
                

               

              </div>: (loading?<div className="text-center p-5">
                  <Spinner animation="grow" variant="light" />
                </div>:
                <div className="mb-4">
                  Failed to load data. Please check your connection and refresh the page
                </div>
              )
                
              }

            </div>
        </Container>
        <Footer/>
        <ToastContainer />
      </div>
    )
  }
}

export default withRouter(TeamMemberPage)
