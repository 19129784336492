import { faImages, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import { Button, Col } from 'react-bootstrap';
import { Constants } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import { UserApi } from '../apis/UserApi';
class SinglePhoto extends Component {
    constructor(){
        super();
        this.state={
            userId:'',
            photoNumber:'',
            image:'',
            fileToUpload:''
        }
    }
    
    selectImage=(photoNumber,userId)=>{
        const imageFile = document.getElementById("userImage"+photoNumber);
        this.setState({userId:userId,photoNumber:photoNumber});
        imageFile.click();
    }
    handleInputChange = (e)=>{
        this.setState({fileToUpload:e.target.files[0]});

        if(e.target.files.length){
            let data = new FormData();
            data.append('image',e.target.files[0]);
            data.append('field_name','photo'+this.state.photoNumber);

            let toastId = toast.loading('Uploading image...',{position:'bottom-right'});

                UserApi.uploadUserImage(data)
                .then((response)=>{
                    e.target.value='';

                    if(response.data){
                        this.setState({image:response.data});
                        toast.update(toastId,{
                            isLoading:false,
                            render:'Uploaded successfuly',
                            type:'success',
                            autoClose:5000
                        });
                    }
                })
                .catch(err=>{
                    const imageFile = document.getElementById("userImage"+this.state.photoNumber);
                    imageFile.value='';

                    if(err?.response?.status==422){
                        const errors = err.response.data.errors;
                        let inputErrors = this.state.errors;
    
                        for (const key in errors) {
                            inputErrors[key]=errors[key][0];
                        }
                        this.setState({errors:inputErrors});
                    }
                    toast.update(toastId,{
                        isLoading: false,
                        autoClose: 5000,
                        type:'error',
                        render: err?.response?.data?.message??'Something went wrong'
                    });
                });
        }
    }
  render() {
    let { image,imageNumber,userId } = this.props;
    if(this.state.image){
        image=this.state.image;
    }

    if(image){
        return <Col sm={6} md={6} lg={4}>
            <div className="user-photos">
                <img src={Constants.publicUrl+'/images/'+image} alt="" />
                <input onChange={this.handleInputChange} id={"userImage"+imageNumber} accept="jpeg,png" type="file" name="user_image" className="d-none"/>
                <Button type="button" className={this.props.displayOnly?'d-none':'upload-button'} variant="light" size="sm" onClick={()=>{this.selectImage(imageNumber,userId)}}><FontAwesomeIcon icon={faUpload}/> Upload</Button>
                    
            </div>
            <ToastContainer/>
        </Col>
    }
    else{
        return <Col sm={6} md={6} lg={4}>
        <div className="user-photos flex">
            <div className="circle comments">
                <FontAwesomeIcon icon={faImages}/>
            </div>
                <input type="file"  accept="jpeg,png" name="user_image" id={"userImage"+imageNumber} className="d-none" onChange={this.handleInputChange} />
                <Button type="button" className={this.props.displayOnly?'d-none':'upload-button'} variant="light" size="sm" onClick={()=>{this.selectImage(imageNumber,userId)}}><FontAwesomeIcon icon={faUpload}/> Upload</Button>

        </div>
        <ToastContainer/>
    </Col>
    }
  }
}

export default SinglePhoto
