import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap';

export class PublishAlertModel extends Component {
  render() {
    const {show} = this.props;
    return (
      <div>
        <Modal className="modalWrapper" show={show} fullscreen="md-down" centered onHide={this.props.handleHideModal}>
            <Modal.Header closeButton>
            <Modal.Title>Publishing / Activating..</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <FontAwesomeIcon icon={faExclamationCircle}/> You are about to publish / activate this. Are you sure?
                </p>
                <div className="text-center">
                    <Button className="btn-light" onClick={this.props.handleHideModal}>Cancel</Button>
                    <Button onClick={this.props.onPublish}>Yes. publish it</Button>
                </div>
            </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default PublishAlertModel
