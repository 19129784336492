import axios from "axios";

// const getUserById = (id)=>{
//     return axios.get('/users/'+id);
// }
// export {getUserById};

class Api{
    forgotPassword=(data)=>{
        return axios.post('/forgot-password',data);
    }
    resetPassword = (data)=>{
        return axios.post('/reset-password',data);
    }
}
const ResetPasswordApi = new Api();
export {ResetPasswordApi};