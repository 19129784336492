import axios from "axios";

// const getUserById = (id)=>{
//     return axios.get('/users/'+id);
// }
// export {getUserById};

class Api{
    getUsers=(accountType)=>{
        if(!accountType)
            accountType='';
        return axios.get('/users?type='+accountType);
    }
    getUserById= (id)=>{
        return axios.get('/users/'+id);
    }
    searchUsers=(criteria)=>{
        return axios.get('/users?search='+criteria);
    }
    createUser=(data)=>{
        return axios.post('/users',data);
    }
    updateUser=(id,data)=>{
        return axios.put('/users/'+id,data);
    }
    checkUserActiveSubscription = (id)=>{
        return axios.get('/users/is-user-active/'+id);
    }
    getUserSubscriptions(id){
        return axios.get('/users/subscriptions/'+id);
    }
    loadMoreUserSubscriptions(url){
        return axios.get(url);
    }
    getProfile=()=>{
        return axios.get('/profile');
    }
    uploadUserImage = (data)=>{
        return axios.post('/profile/photos',data,{
            headers: {
                "content-type": "multipart/form-data"
            }
          });
    }
    uploadUserImageAdmin = (data)=>{
        return axios.post('/user/photos',data,{
            headers: {
                "content-type": "multipart/form-data"
            }
          });
    }
    updateProfile = (data)=>{
        return axios.put('/profile/update',data);
    }
    editUser = (userId,data)=>{
        return axios.put('/users/'+userId,data);
    }
    acceptTerms = ()=>{
        return axios.post('/verify-terms',{});
    }
    disagreeTerms = ()=>{
        return axios.post('/disagree-terms',{});
    }
    changeUserRole = (data)=>{
        return axios.post('/change-user-role',data);
    }
    sendResetLink = (id,data)=>{
        return axios.post('/send-reset-link/'+id,data);
    }
    verifyEmail = (code)=>{
        return axios.post('/verify-email/'+code);
    }
    submitAccountForReview=()=>{
        return axios.post('/submit-account-for-review');
    }
    getUserRoles = (id)=>{
        return axios.get('/get-user-roles/'+id);
    }   
}
const UserApi = new Api();
export {UserApi};