import React, { Component } from 'react'
import { Badge } from 'react-bootstrap';
import { UserApi } from '../apis/UserApi';

class UserRoles extends Component {
    constructor(props){
        super(props);

        this.state = {
            roles:props.roles??[],
            id:props.id??0
        }
    }
    componentDidMount(){
        if(!this.state.roles.lenght&&this.state.id){
            this.getTalentRoles(this.state.id);
        }
    }
    getTalentRoles = (id)=>{
        UserApi.getUserRoles(id)
        .then(res=>{
            let roles = [];
            if(res.data){
              roles = res.data;
            }
            this.setState({roles:roles});
        });
      }

  render() {
    const rolesColors = {Model:'success',Actor:'warning',Admin:'info'};
    const { roles } = this.state;

console.log('roles',roles);
    return (
        <span>
            {roles?
                roles.map((role)=>{
                    return <Badge key={role.id} bg={rolesColors[role.name]} className="mr-1">{role.name}</Badge>;
                }):""
            }
        </span>
    )
  }
}

export default UserRoles
