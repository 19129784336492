import React, { Component } from 'react'
import { Badge, Button, Col } from 'react-bootstrap'
import '../../assets/css/single-model-actor.css';
import { Constants } from '../../constants';
import Avatar from '../../assets/images/avatar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

class SingleModelActor extends Component {
  constructor(props){
      super(props);
  }

  static defaultProps = {
    lg:3,
    xl:3,
    showBookingBtn:false
  }

  render() {

    const { user,xl,lg,selectedItems,showBookingBtn} = this.props;

    if(user){
      let selected = false;
      /**Check to see if item is selected */
      if(selectedItems&&selectedItems.length){
        const foundItems =selectedItems.filter(val=>{
          return val.id == user.id;
        });
        selected = foundItems.length?true:false;
      }
      
      const rolesColors = {Model:'success',Actor:'warning',Admin:'info'};
      let roles = '';
      if(user){
        roles = user.roles.map((role)=>{
            return <Badge key={role.id} bg={rolesColors[role.name]} className="mr-1">{role.name}</Badge>;
        })
      }
      
      return (
        <Col lg={lg} xl={xl} md={6} sm={6} xs={12}>
            <div className="single-actor-wrapper">
              <div className="single-actor-item">
                  <div className="image">
                    <Link to={`/talent/${user.id}`}>
                      <img src={user.photo1?Constants.publicUrl+'/images/'+user.photo1:Avatar} alt="" />
                    </Link>
                    <Button type="button" disabled={selected} className={selected&&showBookingBtn?"disabled btn btn-primary btn-xs btn-home booking-btn-add":(showBookingBtn?"btn btn-primary btn-xs btn-home booking-btn-add":"d-none")} value={user.id} onClick={this.props.addToBooking}>
                        <FontAwesomeIcon icon={faPlus}/>
                        Book
                        </Button>
                  </div>
                  <div className="details">
                      <h5>
                          <Link to={`/talent/${user.id}`} className="title">{user.first_name} {user.last_name} </Link>
                          </h5>
                          {roles}
                  </div>
              </div> 
              <div className="single-actor-item-border-bottom"></div>
            </div>
        </Col>
      )
    }
  }
}

export default SingleModelActor
