import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import UserRoles from '../../../common/UserRoles';
import { Constants } from '../../../constants';

import avatar from "../../../assets/images/avatar.png"
import { Link } from 'react-router-dom';
class InquiryDetails extends Component {

  render() {
    const { inquiry } = this.props;

    return (
      <div className="inquiryDetails">
        <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Booking Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {inquiry?<div>
            <div className="border rounded mb-3 p-3">
              <strong>Customer Information</strong>
              <div className="border rounded p-2 mt-2">
                <Row>
                  <Col lg={8}>
                    <strong>{`${inquiry.name}`}<span className="text-muted">({inquiry.company})</span></strong>
                    <p className="mt-2">Email: {inquiry.email} Tel: {inquiry.phone}</p>
                  </Col>
                  <Col lg={4}>
                    <p className="text-muted text-right">
                      Booked On: <br />
                      {Constants.formatDate(inquiry.created_at)}
                    </p>
                  </Col>
                </Row>
                

              </div>
            </div>

            <div className="border rounded mb-3 p-3">
              <div className="mb-2"><strong>{`${inquiry.name} wants to work with:`}</strong></div>
              {inquiry&&inquiry.talents.map((val,index)=>{
                return <div className="border rounded p-2 mb-2" key={index}>
                  <Row>
                    <Col lg={1} sm={3} xs={3}>
                      <div className="inqury-image">
                        <img src={val.photo1?Constants.publicUrl+'/images/'+val.photo1:avatar} className="img-responsive" alt="" />
                      </div>
                    </Col>
                    <Col lg={9} sm={9} xs={7}>
                      {val.first_name} {val.last_name}
                      <div className="p-2">
                        <UserRoles id={val.id} />
                      </div>
                    </Col>
                    <Col lg={2} sm={2} xs={2}>
                      <Link to={`/users/${inquiry.id}`} className="icon inquiry-btn-cicle btn-primary" >
                          <FontAwesomeIcon icon={faChevronRight}/>
                      </Link>
                    </Col>
                  </Row>
                </div>
              })}
            </div>

            <div className="border rounded mb-3 p-3">
              <strong>Booking</strong>
              <div className="border rounded p-2 mb-3 mt-2">
                <Row>
                    <Col xl={4} lg={4} md={5} sm={12} xs={12}>
                        <strong>Job will start from:</strong>
                        <p>{inquiry.start_date}</p>
                    </Col>
                    <Col xl={8} lg={8} md={7} sm={12} xs={12}>
                        <strong>Till:</strong>
                        <p>{inquiry.end_date}</p>
                    </Col>
                </Row>
              </div>
              <div className="border rounded mb-3 p-3">
                <strong>Message from Customer</strong>
                <div className="border rounded p-2 mb-3 mt-2">
                    <p className="text-muted">{inquiry.message}</p>
                </div>
              </div>
            </div>
          </div>:""}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    )
  }
}

export default InquiryDetails
