import React, { Component } from 'react'
import { Col, Row, Button } from 'react-bootstrap';
import '../../assets/css/single-selected-item.css';
import { Constants } from '../../constants';
import Avatar from '../../assets/images/avatar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

class SingleSelectedModelActor extends Component {
    constructor(props){
        super(props);
    }
    static defaultProps = {
        col:12
    }

  render() {
      const { user,col } = this.props;
    if(user){
        return (
            <Col xl={col} lg={col} md={6} sm={12} xs={12}>
                <div className="single-selected-item mr-1">
                    <Row>
                        <Col lg={3} xl={3} md={3} sm={3} xs={3}>
                            <div className="image">
                                <img src={user.photo1?Constants.publicUrl+'/images/'+user.photo1:Avatar} alt="" />
                            </div>
                        </Col>
                        <Col lg={6} xl={6} md={6} sm={6} xs={6}>
                            <div className="text-left">
                                <span>{user.first_name} {user.last_name}</span>
                            </div>
                        </Col>
                        <Col lg={3} xl={3} md={3} sm={3} xs={3}>
                            <button className="btn" type="button" value={user.id} onClick={this.props.onRemoveItem}>
                            &#10006;
                            </button>
                        </Col>
                    </Row>
                </div>
            </Col>
          )
    }
    
  }
}

export default SingleSelectedModelActor
