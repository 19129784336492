import { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { UserApi } from "../../../apis/UserApi";

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  
class EditUser extends Component{
    constructor(props){
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = ()=>(
        {
            processing:'',
            updated:false,
            errors:{
                first_name:'',
                last_name:'',
                birthdate:'',
                email:'',
                phone:'',
                location:'',
                height:'',
                instagram:'',
                gender:'',
                password:''
            },
            user:this.props.user,
            gender:this.props.user.gender,
            user_id:this.props.user.id,
            first_name:this.props.user.first_name,
            last_name:this.props.user.last_name,
            birthdate:this.props.user.birth_date,
            email:this.props.user.email,
            phone:this.props.user.phone,
            location:this.props.user.location,
            height:this.props.user.height,
            instagram:this.props.user.instagram,
            password:'',
            formValid:false
        }
    )

    handleChange = (e)=>{
        this.setState({[e.target.name]:e.target.value});
        this.inputValidation(e);
    }

    inputValidation = (e)=>{
        let errors = this.state.errors;

        if(e.target.name=='first_name'){
            if(!e.target.value){
            errors[e.target.name] = 'First name is required';
            this.setState({'formValid':false,errors:errors});
            }
            else{
            errors[e.target.name] = '';
            this.setState({'formValid':true,errors:errors});
            }
        }
        if(e.target.name=='last_name'){
            
        }
        if(e.target.name=='gender'){
            if(!e.target.value){
            errors[e.target.name] = 'Gender is is required';
            this.setState({'formValid':false,errors:errors});
            }
            else{
            errors[e.target.name] = '';
            this.setState({'formValid':true,errors:errors});
            }
        }
        if(e.target.name=='birthdate'){
            if(!e.target.value){
            errors[e.target.name] = 'Birthdate is is required';
            this.setState({'formValid':false,errors:errors});
            }
            else{
            errors[e.target.name] = '';
            this.setState({'formValid':true,errors:errors});
            }
        }
        if(e.target.name=='email'){
            if(!e.target.value){
            errors[e.target.name] = 'Email is required';
            this.setState({'formValid':false,errors:errors});
            }
            else{
            if(!validEmailRegex.test(e.target.value)){
                errors[e.target.name] = 'Invalid email';
                this.setState({'formValid':false});
            }
            else{
                errors[e.target.name] = '';
                this.setState({'formValid':true});
            }
            }


        }
        if(e.target.name=='phone'){
            errors[e.target.name] = '';
                this.setState({'formValid':true});
        }
        if(e.target.name=='location'){
            errors[e.target.name] = '';
            this.setState({'formValid':true});
        }
        if(e.target.name=='height'){
            errors[e.target.name] = '';
                this.setState({'formValid':true});
        }
        if(e.target.name=='instagram'){
            errors[e.target.name] = '';
                this.setState({'formValid':true});
        }
        if(e.target.name=='password'){
            if(!e.target.value){
                errors[e.target.name] = 'Your current password is required';
                this.setState({'formValid':false,errors:errors});
            }
            else{
                errors[e.target.name] = '';
                this.setState({'formValid':true,errors:errors});
            }
        }

        
    }
    

    validateForm = (errors) => {
        let countErrors = 0;

        Object.keys(errors).forEach(
            // if we have an error string set valid to false
            (val) => {
                errors[val] = '';
            }
          );
        
        if(!this.state.user_id){
            errors['user_id'] = 'User to edit is unkown';
        }
        
        if(!this.state.first_name){
            errors['first_name'] = 'First name is required';
        }
        if(!this.state.gender){
            errors['gender'] = 'Gender is required';
        }
        if(!this.state.birthdate){
            errors['birthdate'] = 'Birthdate is required';
        }
        if(!this.state.email){
            errors['email'] = 'Email is required';
        }
        if(!this.state.phone){
            errors['phone'] = 'Phone number is required';
        }
        if(!this.state.location){
            errors['location'] = 'Location is required';
        }
        if(!this.state.height){
            errors['height'] = 'Height is required';
        }
        if(!this.state.password){
            errors['password'] = 'Password is required';
        }

        
        Object.values(errors).forEach(
          // if we have an error string set valid to false
          (val) => {
              if(val.length > 0){
                countErrors++;
              }
          }
        );
        
        if(countErrors){
            this.setState({errors:errors,formValid:false});
            return false;
        }
        else{
            this.setState({errors:errors,formValid:true});
            return true;
        }
    }



    handleSubmit = (e)=>{
        e.preventDefault();

        const formValid = this.validateForm(this.state.errors);

        if(formValid){
            const data = {
                first_name:this.state.first_name,
                last_name:this.state.last_name,
                email:this.state.email,
                phone:this.state.phone,
                location:this.state.location,
                height:this.state.height,
                instagram:this.state.instagram,
                gender:this.state.gender,
                birthday:this.state.birthdate,
                current_password:this.state.password
            };

            const toastId = toast.loading('Processing...',{position:'bottom-right'});


            UserApi.editUser(this.state.user_id,data)
                .then(response=>{
                    this.setState({password:''});
                    this.props.userUpdated();
                    toast.update(toastId,{
                        isLoading: false,
                        autoClose: 5000,
                        type:'success',
                        render: 'User updated successfully'
                    });
                })
                .catch(err=>{console.log(err);
                    if(err?.response?.status==422){
                        const errors = err.response.data.errors;
                        let inputErrors = this.state.errors;
    
                        for (const key in errors) {
                            inputErrors[key]=errors[key][0];
                        }
                        this.setState({errors:inputErrors});

                        toast.update(toastId,{
                            isLoading: false,
                            autoClose: 5000,
                            type:'error',
                            render: 'Invalid inputs'
                        });
                    }
                    else{
                        toast.update(toastId,{
                            isLoading: false,
                            autoClose: 5000,
                            type:'error',
                            render: err?.response?.data?.message??'Something went wrong'
                        });
                    }
                });
        }
    }
    render(){
        const { errors,processing,user } = this.state;
        const { first_name,last_name,id }=this.state.user;

        return (

            <div>
                <Modal className="modalWrapper" show={this.props.show} fullscreen="md-down" onHide={this.props.handleHideModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Edit user profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert-info p-2 mb-3">You are about to modify user: {first_name } {last_name}</div>
                        
                        <form onSubmit={this.handleSubmit}>

                <Row>
                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="firstname">First Name<sup className="text-danger">*</sup></Form.Label>
                            <Form.Control
                                type="text"
                                id="firstname"
                                name="first_name"
                                disabled={processing?true:false}
                                value={this.state.first_name} 
                                onChange={this.handleChange}
                            />
                            {errors.first_name.length > 0 && 
  <span className='text-danger'>{errors.first_name}</span>}
                        </Col>
                        


                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="lastname">Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                id="lastname"
                                name="last_name"
                                disabled={processing?true:false}
                                value={this.state.last_name??''} 
                                onChange={this.handleChange}
                            />
                        </Col>

                        

                        <Col xl={12} lg={12} md={12}>
                          <div className="mb-3 mt-2">
                            <Row>
                              <Col xl={3} lg={3} md={3}>
                                <Form.Check 
                                    type='radio'
                                    name="gender"
                                    id='female'
                                    label='Female'
                                    value="Female"
                                    checked={user.gender=='Female'||this.state.gender=='Female'}
                                    onChange={this.handleChange}
                                  />
                              </Col>
                              <Col xl={3} lg={3} md={3}>
                                <Form.Check 
                                    type='radio'
                                    name="gender"
                                    id='male'
                                    label='Male'
                                    checked={user.gender=='Male'||this.state.gender=='Male'}
                                    value="Male"
                                    onChange={this.handleChange}
                                  />

                              </Col>
                            </Row>
                            {errors.gender.length > 0 && 
  <span className='text-danger'>{errors.gender}</span>}
                          </div>
                        </Col>
                        

                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="birthdate">Birthdate</Form.Label>
                            <Form.Control
                                type="date"
                                id="birthdate"
                                name="birthdate"
                                disabled={processing?true:false}
                                value={this.state.birthdate??''} 
                                onChange={this.handleChange}
                            />
                        </Col>

                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="email">Email<sup className="text-danger">*</sup></Form.Label>
                            <Form.Control
                                type="text"
                                id="email"
                                name="email"
                                disabled={processing?true:false}
                                value={this.state.email} 
                                onChange={this.handleChange}
                            />
                            {errors.email.length > 0 && 
  <span className='text-danger'>{errors.email}</span>}
                        </Col>
                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="phone">Phone<sup className="text-danger">*</sup></Form.Label>
                            <Form.Control
                                type="text"
                                id="phone"
                                name="phone"
                                disabled={processing?true:false}
                                value={this.state.phone??''} 
                                onChange={this.handleChange}
                            />
                            {errors.phone.length > 0 && 
  <span className='text-danger'>{errors.phone}</span>}
                        </Col>
                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="location">Location<sup className="text-danger">*</sup></Form.Label>
                            <Form.Control
                                type="text"
                                id="location"
                                name="location"
                                disabled={processing?true:false}
                                value={this.state.location??''} 
                                onChange={this.handleChange}
                            />
                            {errors.location.length > 0 && 
  <span className='text-danger'>{errors.location}</span>}
                        </Col>
                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="email">Height<sup className="text-danger">*</sup></Form.Label>
                            <Form.Control
                                type="text"
                                id="height"
                                name="height"
                                disabled={processing?true:false}
                                value={this.state.height??''} 
                                onChange={this.handleChange}
                            />
                            {errors.height.length > 0 && 
  <span className='text-danger'>{errors.height}</span>}
                        </Col>
                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="instagram">Instagram</Form.Label>
                            <Form.Control
                                type="text"
                                id="instagram"
                                name="instagram"
                                disabled={processing?true:false}
                                value={this.state.instagram??''} 
                                onChange={this.handleChange}
                            />
                            {errors.instagram.length > 0 && 
  <span className='text-danger'>{errors.instagram}</span>}
                        </Col>
                        

                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="password">Password<sup className="text-danger">*</sup></Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Enter your current password to confirm it's you"
                                disabled={processing?true:false}
                                onChange={this.handleChange}
                            />
                            {errors.password.length > 0 && 
  <span className='text-danger'>{errors.password}</span>}
                        </Col>
                        
                    </Row><br />
                    <p className="d-grid gap-2">
                        <Button type="submit" variant="primary" disabled={processing?true:false} size="lg">Update</Button>
                    </p>
                </form>







                </Modal.Body>
                </Modal>
                <ToastContainer/>
            </div>
        )
    }
    
}
export default withRouter(EditUser)