import axios from "axios";

const InquiriesApi = {
    loadInquiries: (page="")=>{
        return axios.get('/inquiries?page='+page);
    },
    loadMoreInquiries : (url)=>{
        return axios.get(url);
    },
    filterByDate : (date1,date2)=>{
        return axios.get('/inquiries?date1='+date1+'&date2='+date2);
    },
    filterByNameEmailOrPhone : (search)=>{
        return axios.get('/inquiries?search='+search);
    }
}

export default InquiriesApi;