import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../../common/Header'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { Auth } from '../../apis/Auth'

import { Constants } from "../../constants.jsx"

import SEO from '@americanexpress/react-seo';

import TalentModel from "../../assets/images/TalentModel.jpg"
import TalentActor from "../../assets/images/TalentActor.jpg"
import ChooseUsItem from '../../components/public/ChooseUsItem'
import GetInTouch from '../../components/public/GetInTouch'
import { HomeApi } from '../../apis/HomeApi';
import RecentModels from '../../components/public/RecentModels'
import RecentActors from '../../components/public/RecentActors'
import Footer from '../../common/Footer'

class HomePage extends Component {
  state={
    recent_models:'',
    recent_actors:'',
    settings:'',
    loadingActors:true,
    loadingModels:true,
    loadingSettings:false
  }

  componentDidMount(){
    document.documentElement.scrollTop=0;
    HomeApi.getRecentUsers('model')
      .then(res=>{
        this.setState({recent_models:res.data,loadingModels:false});
      });

    HomeApi.getRecentUsers('actor')
      .then(res=>{
        this.setState({recent_actors:res.data,loadingActors:false});
      });

      HomeApi.getSettings()
      .then(res=>{
        this.setState({settings:res.data,loadingSettings:true});
      })
      .catch(()=>{
        this.setState({loadingSettings:false});
      });
  }
  render() {
    const { recent_models,recent_actors,settings } = this.state;

    return (
      <div className="home content-wrapper">
         <SEO
          title="Afrifame Models"
          description="The leading market place to discover and hire professional models and Actors"
          keywords={['Rwandese Models', 'Rwandese actors']}
          siteUrl={Constants.publicUrl}
          image={{
            src: 'http://example.com/foo.jpg'
          }}
        />
        <div className="first-visible-content">
          <Header background={false} />
          <div className="wrapper">
            <Container>
              <div className="content-on-bg">
                <Row>
                  <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                    <div  className="home-intro">
                      <h1 className="title">Find & hire top professional models & Actors</h1>
                      <p>The leading market place to discover and hire professional models and Actors</p>
                      <Link to="/talents" className="btn btn-primary btn-md btn-home">
                        Hire Talents &nbsp;<FontAwesomeIcon icon={faArrowRight}/>
                      </Link>
                      <Link to="/register" className={Auth.user?'d-none':'btn btn-default btn-md btn-home'}>Register for free &nbsp;<FontAwesomeIcon icon={faArrowRight}/></Link>
                    </div>
                    
                  </Col>
                </Row>
              </div>
              <div className="talents"></div>
            </Container>
            <div className="clearfix"></div>
            </div>
            <div className="home-bg absolute"></div>
            {/* <div className="overlay"></div> */}
            <div className="clearfix"></div>
          
        </div>

        <div className="promotion">
          <Container className="content-on-bg">
            <div className="promotion-wrapper">
              <Row>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="promotion-item mb-5">
                      <img src={TalentModel} alt="" />
                      <div className="promotion-details">
                        <h3>
                          Are you a model?
                          </h3>
                        <p>Opportunities for professional models.</p>
                        <Link to="/register/model" className="btn btn-primary btn-md btn-home">
                            Join Today &nbsp;<FontAwesomeIcon icon={faArrowRight}/>
                          </Link>
                        </div>
                    </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="promotion-item">
                      <img src={TalentActor} alt="" />
                      <div className="promotion-details">
                        <h3>Are you an actor?</h3>
                        <p>Movie casting for actors</p>
                        <Link to="/register/actor" className="btn btn-primary btn-md btn-home">
                            Join Today &nbsp;<FontAwesomeIcon icon={faArrowRight}/>
                          </Link>
                      </div>
                    </div>
                </Col>
              </Row>
           </div>
          </Container>
        </div>
        <div className="talents-list">
          <Container className="content-on-bg">
            <RecentModels loading={this.state.loadingModels} models={recent_models} />
            <RecentActors loading={this.state.loadingActors} actors={recent_actors} />
          </Container>
        </div>

        {settings?
        <div className="choose-us">
          <Container className="content-on-bg pt-5 ">
            <h2 className="mb-5 pb-5 heading model">Why<br/> to choose Afrifame?</h2>
            {/* <p>We move with make a Creative Strategy for help your business goal, we help to improve your income by a services we have. make your content look interesting and make people look for your business.</p> */}
            <p className="mb-4">We guide and help you in finding the ideal Model / Actor for your Commercials / Firms</p>
            <Row>
              <Col lg={6} xl={6} md={6} sm={12} xs={12}>
                <ChooseUsItem 
                title="Variety" 
                description={settings.variety}
                bg="variaty"/>
              </Col>

              <Col lg={6} xl={6} md={6} sm={12} xs={12}>
                <ChooseUsItem 
                title="Professionalism" 
                description={settings.professionalism}
                bg="professionalism"/>
              </Col>

              <Col lg={6} xl={6} md={6} sm={12} xs={12}>
                <ChooseUsItem 
                title="Trustworthy" 
                description={settings.trustworthy}
                bg="trustworthy"/>
              </Col>

              <Col lg={6} xl={6} md={6} sm={12} xs={12}>
                <ChooseUsItem 
                title="Increase revenue" 
                description={settings.increase_revenue}
                bg="revenu"/>
              </Col>
            </Row>
          </Container>
        </div>
        
        :""}
        

        <div className="get-in-touch">
          <Container className="content-on-bg pt-5 ">
            <GetInTouch/>
          </Container>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default HomePage
