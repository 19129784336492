import React, { Component } from 'react'
import HeadingPlaceholder from '../../../placeholders/HeadingPlaceholder'
import ButtonPlaceholder from '../../../placeholders/ButtonPlaceholder'
import '../../../assets/css/placeholder.css';
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../../../common/Header'
import UserSidebarNav from '../../../components/user/UserSidebarNav';
import HelpButton from '../../../components/user/HelpButton';
import ImagePlaceholder from '../../../placeholders/ImagePlaceholder';
import ParagraphPlaceholder from '../../../placeholders/ParagraphPlaceholder';

class UserDetailsPlaceholder extends Component {
    
  render() {
    return (
    <div>
        <Header background={false} />
        <Container className="content-on-bg mt-3">
          <Row>
            <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
              <UserSidebarNav />
              <HelpButton />
            </Col>
            <Col xl={9} lg={9} md={12} sm={12} xs={12}>
              <div className="welcome mt-0 p-4">
                <div className="Users mb-3">
                    <ParagraphPlaceholder block={true} height={120}/>
                    <div className="clearfix mb-5"></div>
                  
                    <div className="buttons mt-5 pt-5 mb-4">
                        <HeadingPlaceholder block={true}/>
                    </div>
                    <Row>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <HeadingPlaceholder block={true}/>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <HeadingPlaceholder block={true}/>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <HeadingPlaceholder block={true}/>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <HeadingPlaceholder block={true}/>
                        </Col>
                    </Row>
                    <div className="buttons mb-4">
                        <HeadingPlaceholder/>
                    </div>
                  <Row>
                        {/*User items */}
                        <Col xs={12} sm={6} md={4} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                        </Col>
                    </Row>

                </div>
                <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <HeadingPlaceholder block={true}/>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <span className="float-right">
                            <ButtonPlaceholder/>
                        </span>
                    </Col>
                </Row>
                
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-bg"></div>
        <div className="overlay"></div>
      </div>
    )
  }
}

export default UserDetailsPlaceholder
