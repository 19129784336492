import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { Auth } from '../../apis/Auth';

const Admin = ({children}) =>{
    const loggedIn = localStorage.getItem('token')!=null;
    if(loggedIn&&Auth.userIsAdmin()){
        return children;
    }
    else if(loggedIn){
        return <Redirect to="/"/>
    }
    return <Redirect to="/signin"/>
}

export default Admin
