import { Modal } from "react-bootstrap";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import AccountType from "./AcccountType";
import UserDetailsForm from "./UserDetailsForm";
import ResponseMessage from "../ResponseMessage";

import { UserApi } from "../../../apis/UserApi";
import { ToastContainer, toast } from "react-toastify";

class AddUser extends Component{
    constructor(){
        super();
        this.state = this.getInitialState();

    }
    
    getInitialState = ()=>({
        show:false,
        step: 1,
        createUserResponseMessage:'',
        firstName: '',
        lastName: '',
        email: '',
        phone:'',
        gender:'Male',
        location:'',
        height:'',
        instagram:'',
        birthday:'',
        roles:{
            model:'',
            actor:'',
            admin:''
        },
        errors:{
            first_name:'',
            last_name:'',
            email:'',
            phone:'',
            gender:'',
            location:'',
            height:'',
            instagram:'',
            birthday:'',
        }
    });

   componentDidUpdate(prevProps){
       if(this.props!=prevProps){
           this.setState({show:this.props.show});
       }
   }

   nextStep = () => {
        const { step } = this.state
        this.setState({
            step : step + 1
        })
    }

    prevStep = () => {
        const { step } = this.state
        this.setState({
            step : step - 1
        })
    }

    createUser = ()=>{
        
        const roles = Object.values(this.state.roles);
        const validatedRoles = roles.filter(val=>{
            return val!='';
        })

        const data = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            phone: this.state.phone,
            email: this.state.email,
            roles: validatedRoles,
            gender: this.state.gender,
            location: this.state.location,
            height: this.state.height,
            birthday: this.state.birthday,
            instagram: this.state.instagram
        }

        const toastId = toast.loading('Creating user...',{position:'bottom-right'});

        UserApi.createUser(data)
            .then(response=>{
                /**Reset Form */
                this.setState(this.getInitialState());
                /**Show sucess message */
                this.setState({step:3,show:true,createUserResponseMessage:'User created successfully'});

                toast.update(toastId,{
                    isLoading: false,
                    autoClose: 5000,
                    type:'success',
                    render:response?.data?.message
                });
                this.props.onNewUser();
            })
            .catch(err=>{
                if(err?.response?.status==422){
                    const errors = err.response.data.errors;
                    let inputErrors = this.state.errors;

                    for (const key in errors) {
                        inputErrors[key]=errors[key][0];
                    }
                    this.setState({errors:inputErrors});
                }
                toast.update(toastId,{
                    isLoading: false,
                    autoClose: 5000,
                    type:'error',
                    render: err?.response?.data?.message??'Something went wrong'
                });
            });
    }
    handleHideModal =()=>{
        this.setState(this.getInitialState());
        this.props.onHideModal();
    }
    handleRoleChange = (e)=>{
        let roles = this.state.roles;
        var currentRoleInput = document.getElementById(e.target.id);
        if(currentRoleInput.checked==true){
            roles[e.target.name] = e.target.value;
        }else{
            roles[e.target.name] = '';
        }

        this.setState({roles:roles});
        
        console.log(this.state.roles);
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value})
    }
    handleFileChange = (event)=>{
        this.setState({[event.target.name]: event.target.files[0]})
    }

   render(){
        const { step, createUserResponseMessage, firstName, lastName, email, phone,gender,height,location,instagram,birthday,roles,errors } = this.state;
        const inputValues = { firstName, lastName, email, phone,gender,height,location,instagram,birthday,roles,errors};

        let currentComponent = <AccountType 
                                    nextStep={this.nextStep}
                                    handleChange = {this.handleRoleChange}
                                    inputValues={inputValues} />;
        switch(step) {
            case 1:
                currentComponent = <AccountType 
                                        nextStep={this.nextStep}
                                        handleChange = {this.handleRoleChange}
                                        inputValues={inputValues} />
                break;
            case 2:
                currentComponent = <UserDetailsForm 
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        handleChange = {this.handleChange}
                                        handleFileChange = {this.handleFileChange}
                                        createUser={this.createUser}
                                        inputValues={inputValues} />
                break;
            case 3:
                currentComponent = <ResponseMessage 
                                        message={createUserResponseMessage} />
                break;
        }
        
        return (
            <div>
                <Modal className="modalWrapper" show={this.state.show} fullscreen="md-down" onHide={this.handleHideModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>{step==1?'Step 1: Choose Account Type':(step==2?'Step 2: User Details':'Step3: Completed')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            currentComponent
                        }
                    </Modal.Body>
                </Modal>
                <ToastContainer/>
            </div>
        )
    }
}
export default withRouter(AddUser);