
import React, { Component } from 'react'
import { ProgressBar, Row } from 'react-bootstrap'
import SinglePhoto from '../SinglePhoto'

export class UserPhotos extends Component {
    selectImage=()=>{
        const imageFile = document.getElementById('userImage');
        imageFile.click();
    }
    handleInputChange = (e)=>{
        console.log(e);
    }
  render() {
    const { user } = this.props;
    let photoCount=0;
    if(user.photo1)photoCount++;
    if(user.photo2)photoCount++;
    if(user.photo3)photoCount++;
    let percentage = photoCount*100/3;
    
    return (
      <div className="profileInfo mb-3 pt-3 pb-0">
        <h2 className="section-title mb-4">
          My Photos
          <span className={this.props.displayOnly?'d-none':'float-right'}>
              <small>Completed {photoCount}/3</small>
              <ProgressBar now={percentage} variant="success" />
            </span>
        </h2>
        <div className="clearfix"></div>
        <Row>
            <SinglePhoto image={user?user.photo1:''} imageNumber={1} userId={user?user.id:''} displayOnly={this.props.displayOnly}/>
            <SinglePhoto image={user?user.photo2:''} imageNumber={2} userId={user?user.id:''} displayOnly={this.props.displayOnly}/>
            <SinglePhoto image={user?user.photo3:''} imageNumber={3} userId={user?user.id:''} displayOnly={this.props.displayOnly}/>
        </Row>
      </div>
    )
  }
}

export default UserPhotos
