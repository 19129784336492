import React, { Component } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Redirect } from 'react-router-dom';
import {Link} from 'react-router-dom'

class GetStared extends Component {
    constructor(props){
      super(props);
    }
    state = {
        showGetStarted:false,
        accountType:'',
        propsLoaded:false
    }

    componentDidUpdate(prevProps){
        if(prevProps!=this.props){
            this.setState({showGetStarted:this.props.show,propsLoaded:true});
        }
    }

    componentDidMount(){
      this.setState({accountType:''});
    }

  handleClose = () => {
      this.setState({showGetStarted:false});
      this.props.onCloseHandler();
  }
  handleShow = () => this.setState({showGetStarted:true});

  loadRegisterPage = (e)=>{
    localStorage.setItem('accountType',e.target.value);
    this.setState({accountType:e.target.value,showGetStarted:false});
  }

  render() {
    if(this.state.accountType){
      return <Redirect to="/register"/>;
    }
  
    return (
    <Modal className="modalWrapper" size="sm" centered show={this.state.showGetStarted} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <strong>Join the Leading marketplace for Models and Actors</strong><br/><br/>
            <Form>
              
                <Form.Check
                    className="large"
                    inline
                    label="I am a Model"
                    name="account_type"
                    type='radio'
                    id='model'
                    value="model"
                    checked={this.state.accountType=='model'?true:false}
                    onChange={this.loadRegisterPage}
                />
                <Form.Check
                    className="large"
                    inline
                    label="I am aj Actor/Actress"
                    name="account_type"
                    type='radio'
                    id='actor'
                    value="actor"
                    checked={this.state.accountType=='actor'?true:false}
                    onChange={this.loadRegisterPage}
                />
            </Form>
        </Modal.Body>
{/*         
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Link className="btn btn-primary" to="/register">
            Continue
          </Link>
        </Modal.Footer>  */}
    </Modal>
    )
  }
}

export default GetStared
