import { Button, Col, Row } from 'react-bootstrap'
import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { faCcVisa} from '@fortawesome/free-brands-svg-icons'
import SubscriptionItem from '../subscriptions/SubscriptionItem'
import axios from 'axios'

export class UserDetailsSubscription extends Component {

  render() {
    const { user, data } = this.props;
    let subscriptionView = '';
    let next_page_url = '';
    if(data){
      const subscriptions = data.data;
      next_page_url = data.next_page_url;
      console.log(data )
      if(subscriptions ){
        subscriptionView = subscriptions.map((item)=>{
          return <SubscriptionItem user={user} subscription={item} key={item.id}/>
        })
      }
    }
    return (
    <div className="profileInfo subscriptions mb-3">
        <div className="">
            <Row>
            <Col xl={8} md={8} lg={8} sm={12}>
                <h2 className="section-title mb-4">Subscriptions</h2>
            </Col>
                <Col xl={4} md={4} lg={4} sm={12}>
                <Button variant="outline-danger" onClick={this.props.showPaySubscription} className="float-right mb-3">
                <small>Pay New Subscription</small>
            </Button>
                </Col>
            </Row>
          
          <div className="clearfix"></div>
            
            {subscriptionView}
            <div className={next_page_url?"":"d-none"}>
                <Button className="float-right" variant="light" onClick={()=>{this.props.loadMore(next_page_url)}} disabled={next_page_url?false:true}>Load More</Button>
            </div>
            <div className="clearfix"></div>
        </div>
      </div>
    )
  }
}

export default UserDetailsSubscription
