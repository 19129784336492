import axios from 'axios'
import React, { Component } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { ToastContainer,toast } from 'react-toastify'
import InquiriesApi from '../../apis/InquiriesApi'
import Header from '../../common/Header'
import InquiryDetails from '../../components/admin/inquiries/InquiryDetails'
import InquiryItem from '../../components/admin/inquiries/InquiryItem'
import HelpButton from '../../components/user/HelpButton'
import UserSidebarNav from '../../components/user/UserSidebarNav'

class InquiriesPage extends Component {
  state ={
    inquiries:[],
    show:false,
    selectedInquiry:'',
    talentsRoles:[],
    next_page_url:'',
    start_date:'',
    end_date:''
  }

  componentDidMount(){
    InquiriesApi.loadInquiries()
    .then(res=>{
      this.setState({inquiries:res.data.data,next_page_url:res.data.next_page_url});
    })
    .catch(err=>{
      console.log(err);
    })
  }

  handleShowDetails = (id)=>{
    const inquiry = this.state.inquiries.filter(val=>{
      return val.id == id;
    });
    if(inquiry){
      this.setState({selectedInquiry:inquiry[0]});
      this.handleShow();
    }
  }

  handleLoadMore = ()=>{
    const url = this.state.next_page_url.replace(axios.defaults.baseURL,'');
      toast.promise(
        InquiriesApi.loadMoreInquiries(url)
              .then(response=>{
                  let inquiries = this.state.inquiries;

                  this.setState({inquiries:inquiries.concat(response.data.data),next_page_url:response.data.next_page_url});
              }),
              {
                  pending:'Loading....'
              });
  }

  handleSearch = (e)=>{
    //if(e.target.value){
      const toastId = toast.loading('Loading...',{position:"bottom-right"});
      InquiriesApi.filterByNameEmailOrPhone(e.target.value)
            .then(response=>{
                this.setState({inquiries:response.data.data,next_page_url:response.data.next_page_url});
                toast.dismiss();
            })
            .catch(()=>{
              toast.update(toastId,{
                render:"An error occured. If this perisists, please contact the administrator",
                isLoading:false,
                autoClose:5000,
                type:"error"
              })
            });
    //}
}

  filterByDate = ()=>{
    if(this.state.start_date&&this.state.end_date){
        /**filter data */

        const toastId = toast.loading('Loading...',{position:"bottom-right"});

        InquiriesApi.filterByDate(this.state.start_date,this.state.end_date)
            .then(response=>{
                toast.dismiss();
                this.setState({inquiries:response.data.data,next_page_url:response.data.next_page_url});
            })
            .catch(()=>{
              toast.update(toastId,{
                render:"An error occured. If this perisists, please contact the administrator",
                isLoading:false,
                autoClose:5000,
                type:"error"
              })
            });
        
    }
}

handleDateChange = (e)=>{
  this.setState({[e.target.name]:e.target.value});

  const self = this;
  setTimeout(()=>{
      self.filterByDate();
  },300);
  
}
  

  handleShow = ()=>{
    this.setState({show:true});
  }
  handleClose = ()=>{
    this.setState({show:false})
  }

  handleDateInputFocus = (e)=>{
    e.target.type = "date";
    document.getElementById(e.target.id).showPicker();
}
handleDateInputBlur = (e)=>{
    e.target.type = "text";
}

  render() {
    const { inquiries,selectedInquiry, talentsRoles } = this.state;
    return (
      <div className="inquiriesPage">
        <Header background={false} />
        <Container className="content-on-bg mt-3 mb-3">
            <Row>
              <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
                <UserSidebarNav />
                <HelpButton />
              </Col>
              <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                <div className="welcome m-0 p-4">
                    <div className="profileInfo subscriptions mb-3">
                        <div className="p-4 pt-0 pb-0">
                            <Row>
                            <Col xl={8} md={8} lg={8} sm={12}>
                                <h2 className="mb-4">Inquiries</h2>
                            </Col>
                            </Row> 

                            <Row>
                            <Col xl={8} md={8} lg={8} sm={12}>
                                <div className="item inline-text">
                                <Row>
                                    <Col xl={6} lg={6} sm={12} xs={12}>
                                        <InputGroup>
                                            <Form.Control
                                                id="startDate"
                                                inline="true"
                                                type="text"
                                                name="start_date"
                                                placeholder="Start Date"
                                                onChange={this.handleDateChange}
                                                onBlur={this.handleDateInputBlur}
                                                onFocus={this.handleDateInputFocus}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xl={6} lg={6} sm={12} xs={12}>
                                        <InputGroup>
                                            <Form.Control
                                                id="endDate"
                                                inline="true"
                                                type="text"
                                                name="end_date"
                                                placeholder="End date"
                                                min={this.state.start_date}
                                                onChange={this.handleDateChange}
                                                onBlur={this.handleDateInputBlur}
                                                onFocus={this.handleDateInputFocus}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                        
                                </div>
                            </Col>
                            <Col xl={4} md={4} lg={4} sm={12}>
                                <div className="mt-2">
                                <Form.Control
                                    type="text"
                                    placeholder="Search by name, email or phone"
                                    onChange={this.handleSearch}
                                />
                                </div>
                            </Col>
                            </Row>  
                            <div className="mb-3"></div>

                            {/* {subscriptionItems} */}

                            {this.state.inquiries.map((val,index)=>{
                              return <InquiryItem inquiry={val} key={index} showDetails={this.handleShowDetails}/>
                            })}




                            <div className={!inquiries.length?'d-none':''}>
                                <Button className="float-right" onClick={this.handleLoadMore} disabled={!this.state.next_page_url} variant="light">Load More</Button>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
              </Col>
            </Row>
        </Container>
          <div className="home-bg"></div>
            <div className="overlay"></div>
            <ToastContainer/>

        <InquiryDetails inquiry={selectedInquiry} show={this.state.show} handleClose={this.handleClose}/>
      </div>
    )
  }
}

export default InquiriesPage
