import SEO from '@americanexpress/react-seo'
import React, { Component } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import image from "../../assets/images/akazungu.jpg"
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import { Constants } from '../../constants'
import Avatar  from "../../assets/images/avatar.png";
import { HomeApi } from '../../apis/HomeApi'
import { Link } from 'react-router-dom'

import createDOMPurify from 'dompurify'

class AboutPage extends Component {
  state={
    settings:"",
    team:[],
    loadingSettings:false,
    loadingTeam:false,
    loadingTeam:false
  }

  componentDidMount(){
    document.documentElement.scrollTop=0;
    this.setState({loadingSettings:true,loadingTeam:true});
    HomeApi.getSettings()
      .then(res=>{
        this.setState({settings:res.data,loadingSettings:true});
      })
      .catch(()=>{
        this.setState({loadingSettings:false});
      });
    
    HomeApi.getTeamMembers()
      .then(res=>{
        this.setState({team:res.data,loadingTeam:false});
      })
      .catch(()=>{
        this.setState({loadingTeam:false});
      });
  }

 

  render() {
    const {settings, team, loadingSettings, loadingTeam } = this.state;

    return (
      <div className="AboutPage">
        <SEO
          title="About Us - Afrifame Models"
          description="Don't be shy. Please share your experience with us or let us know where we can improve"
          keywords={['Rwandese talents', 'Rwandese actors']}
          siteUrl={Constants.siteUrl+'/about'}
          image={{
            src: image
          }}
        />

        <Header background={false} />
        <Container>
            <div className="wrapper mt-4 mb-3">
              <div className="mb-4">
                <h2 className="title mb-5 text-center">About Us</h2>
                <div className="mb-4">
                  <Row>
                    {/* <Col lg={4} md={4} sm={12} className="mb-5">
                        <img src={settings.about_photo?Constants.publicUrl+'/images/'+settings.about_photo:Avatar}  className="img-responsive ceo-img" />
                    </Col> */}

                    <Col lg={12} md={12} sm={12}>
                    <div className="welcome-note">
                      <h3 className="mb-4">Who are we?</h3>
                      {settings?
                      <div className="mb-5">{settings.welcome_note}</div>
                      :(loadingSettings?
                          <div className="text-center p-3">
                            <Spinner animation="grow" variant="light" />
                          </div>
                        :
                        <div>Failed to load data. Check your connect and refresh this page.</div>
                      )}
                       </div>
                      
                    </Col>
                  </Row>
                </div>
                <div className="mb-4 mission-vision">
                  <Row className="mb-4">
                    <Col lg={6} className="mb-4">
                      <div className="mission">
                        <h2 className="title mb-4">Mission</h2>
                        {settings?
                        <div dangerouslySetInnerHTML={{ __html: createDOMPurify.sanitize(settings.mission) }}/>
                        :(loadingSettings?
                            <div className="text-center p-3">
                              <Spinner animation="grow" variant="light" />
                            </div>
                          :
                          <div>Failed to load data. Check your connect and refresh this page.</div>
                        )}
                      </div>
                    </Col>
                    <Col lg={6} className="mb-4">
                      <div className="vision">
                        <h2 className="title mb-4">Vision</h2>
                        {settings?
                        <div dangerouslySetInnerHTML={{ __html: createDOMPurify.sanitize(settings.vision) }}/>
                        :(loadingSettings?
                            <div className="text-center p-3">
                              <Spinner animation="grow" variant="light" />
                            </div>
                          :
                          <div>Failed to load data. Check your connect and refresh this page.</div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                
                {team?
                  <div className="team">
                    <h2 className="title mb-4">Team</h2>
                    
                    {team?
                        <Row>
                          {team.map((val,index)=>{
                            return <Col lg={3} md={6} sm={12} key={index}>
                              <div className="team-member">
                                <div className="img">
                                  <Link to={`/team/${val.id}`}>
                                    <img src={val.photo?Constants.publicUrl+'/images/'+val.photo:Avatar} className="img-responsive" />
                                  </Link>
                                </div>
                                <Link to={`/team/${val.id}`}>
                                  <h2 className="title">{val.name}</h2>
                                </Link>
                                <p>{val.position}</p>
                              </div>
                            </Col>
                          })}
                        </Row>
                        :(loadingTeam?
                            <div className="text-center p-3">
                              <Spinner animation="grow" variant="light" />
                            </div>
                          :
                          <div>Failed to load data. Check your connect and refresh this page.</div>
                        )}


                    
                  </div>
                :
                ""
                }
              </div>
                
            </div>
        </Container>
        <Footer/>
      </div>
    )
  }
}

export default AboutPage
