import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PlaceholderLoading from 'react-placeholder-loading'
import Header from '../../../common/Header'
import HelpButton from '../../../components/user/HelpButton'
import UserSidebarNav from '../../../components/user/UserSidebarNav'
import ButtonPlaceholder from '../../../placeholders/ButtonPlaceholder'
import HeadingPlaceholder from '../../../placeholders/HeadingPlaceholder'
import ImagePlaceholder from '../../../placeholders/ImagePlaceholder'
import ParagraphPlaceholder from '../../../placeholders/ParagraphPlaceholder'

import '../.././../assets/css/placeholder.css';

export class AccountPagePlaceholder extends Component {
    componentDidMount = ()=>{
        document.documentElement.scrollTop=0;
    }
  render() {
    return (
      <div>
          <Header background={false} />
          <Container className="content-on-bg mt-3">
            <Row>
              <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
                <UserSidebarNav />
                <HelpButton />
              </Col>
              <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                <div className="subscription">
                    <Row>
                        <Col xs={4} md={3} lg={2} className="leftside">
                               <ImagePlaceholder circle={true} />
                        </Col>
                        <Col xs={8} md={9} lg={10}>
                            <HeadingPlaceholder />
                            <ParagraphPlaceholder block={true} />
                            <ParagraphPlaceholder />
                            <ParagraphPlaceholder block={true} />
                            <div className="mt-3">
                                <Row>
                                    <Col xs={6} md={3} lg={2}>
                                        <ButtonPlaceholder/>
                                    </Col>
                                    <Col xs={6} md={3} lg={2}>
                                        <ButtonPlaceholder/>
                                    </Col>
                                </Row>
                            </div>
                            
                        </Col>
                    </Row>
                </div>

                <div className="welcome">
                    <HeadingPlaceholder />
                    <HeadingPlaceholder block={true} />

                    <ParagraphPlaceholder block={true} />
                    <ParagraphPlaceholder block={true} />
                    <ParagraphPlaceholder />
                    <ParagraphPlaceholder block={true} />
                    <ParagraphPlaceholder block={true} />
                    <ParagraphPlaceholder block={true} />
                    <ParagraphPlaceholder block={true} />
                    
                   
                    <div className="mt-3 text-right">
                        <ButtonPlaceholder />
                        <div className="clearfix"></div>
                    </div>

                    <div className="mt-3">
                        <PlaceholderLoading width={100} height={30} shape="rect"/>
                        <div className="mb-3"></div>
                        <Row>
                            <Col xs={12} md={4} lg={4}>
                                <ImagePlaceholder width={200} height={200}/>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <ImagePlaceholder  width={200} height={200}/>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <ImagePlaceholder  width={200} height={200} />
                            </Col>
                        </Row>
                    </div>

                    <div className="mt-3">
                        <HeadingPlaceholder />
                        <div className="mb-3"></div>
                        <Row >
                            <Col xs={12} md={12} lg={12}>
                                <ButtonPlaceholder block={true} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            </Row>
        </Container>
        <div className="home-bg"></div>
          <div className="overlay"></div>
      </div>
    )
  }
}

export default AccountPagePlaceholder
