import React, { Component } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import SettingsApi from '../../apis/SettingsApi'
import Header from '../../common/Header'
import HelpButton from '../../components/user/HelpButton'
import UserSidebarNav from '../../components/user/UserSidebarNav'
import { ToastContainer, toast } from 'react-toastify'
import { Constants } from '../../constants'
import avatar from "../../assets/images/avatar.png";
import SettingsPagePlaceholder from './placeholders/SettingsPagePlaceholder'

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);



export class SettingsPage extends Component {
    state = {
        settings:'',
        processing:false,
        formValid:true,
        location:'',
        contact_email:'',
        contact_phone:'',
        welcome_note:'',
        mission:'',
        vision:'',
        variety:'',
        professionalism:'',
        trustworthy:'',
        increase_revenue:'',
        location_valid:true,
        contact_email_valid:true,
        contact_phone_valid:true,
        errors:{
            location:'',
            contact_email:'',
            contact_phone:'',
            welcome_note:'',
            mission:'',
            vision:'',
            variety:'',
            professionalism:'',
            trustworthy:'',
            increase_revenue:'',
            image:''
        }
    }
    componentDidMount(){
       this.loadSettings();
    }
    loadSettings = ()=>{
      SettingsApi.loadSettings()
      .then(res=>{
          if(res.data)
          this.setState({
              settings:res.data,
              location:res.data.location,
              contact_email:res.data.contact_email,
              contact_phone:res.data.contact_phone,
              welcome_note:res.data.welcome_note,
              mission:res.data.mission,
              vision:res.data.vision,
              variety:res.data.variety,
              professionalism:res.data.professionalism,
              trustworthy:res.data.trustworthy,
              increase_revenue:res.data.increase_revenue
          });
          else
              this.setState({settings:res.data});
      });
    }


    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value})
  
        event.preventDefault();
          const { name, value } = event.target;
          let errors = this.state.errors;
          
          switch (name) {
            case 'location': 
              errors.location = 
                value.length < 2
                  ? 'Location must be 2 characters long!'
                  : '';
              break;
            case 'contact_email': 
              errors.contact_email = 
                validEmailRegex.test(value)
                  ? ''
                  : 'Email is not valid!';
              break;
            case 'contact_phone': 
              errors.contact_phone = 
                value.length <= 0
                  ? 'Phone is required'
                  : '';
              break;
            case 'welcome_note': 
              errors.welcome_note = 
                value.length <= 0
                  ? 'Welcome note is required'
                  : '';
              break;
            
            default:
              break;
          }
  
          let fieldValid = !errors[name]?true:false;
          let fieldNameValid = name+'_valid';
        
          this.setState({errors, [name]: value, [fieldNameValid]:fieldValid});
  
          console.log(this.state);
    }
  
    validateForm = (errors) => {
        let valid = true;
        
        if(!this.state.location_valid){
            errors['location'] = 'Location is required';
        }
        if(!this.state.contact_email_valid){
            errors['contact_email'] = 'Email is required';
        }
  
        if(!this.state.contact_phone_valid){
            errors['contact_phone'] = 'Phone is required';
        }
  
        this.setState({errors:errors});
  
        Object.values(errors).forEach(
          // if we have an error string set valid to false
          (val) => val.length > 0 && (valid = false)
        );
        
        return valid;
    }
  

    handleSubmit = (e)=>{
        e.preventDefault();

        const data = new FormData(document.getElementById("settingsFrm"));

        this.setState({processing:true});

        if(this.validateForm(this.state.errors,["location","contact_email","contact_phone"])) {
          const toastId = toast.loading('Creating Member...',{position:'bottom-right'});

            SettingsApi.updateSettings(this.state.settings.id,data)
            .then(()=>{
              /**refresh page */
              this.loadSettings();
                document.getElementById("image").value="";
                this.setState({processing:false});
                toast.update(toastId,{
                    isLoading: false,
                    autoClose: 5000,
                    type:'success',
                    render: "Settings update successfully"
                });
            })
            .catch(err=>{
                this.setState({processing:false});
                
                if(err?.response?.status==422){
                    const { errors } = err.response.data;
                    let inputErrors = this.state.errors;
  
                    for (const key in errors) {
                        inputErrors[key]=errors[key][0];
                    }

                    this.setState({errors:inputErrors});
                }
                if(err?.response?.status==404||err?.response?.status==500){
                  toast.update(toastId,{
                      isLoading: false,
                      autoClose: 5000,
                      type:'error',
                      render: err?.message??'Something went wrong'
                  });
                }
                else{
                  toast.update(toastId,{
                      isLoading: false,
                      autoClose: 5000,
                      type:'error',
                      render: err?.response?.data?.message??'Something went wrong'
                  });
                }
            });
        }

    }


  render() {
    const { processing, errors, settings } = this.state;

    let html = "";
    {!settings?
      html = <SettingsPagePlaceholder />
      :
       html=
        <div>
          <Header background={false} />
          <Container className="content-on-bg mt-3">
            <Row>
              <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
                <UserSidebarNav />
                <HelpButton />
              </Col>
              <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                  <div className="welcome mt-0">
                  <h2 className="title mb-5 border-bottom pb-4 mt-4">Settings</h2>
                  <form id="settingsFrm" onSubmit={this.handleSubmit} encType="multipart/form-data">
               
                      <fieldset>
                        <legend className="mb-3">About Afrifame</legend>
                  <Row>
                          <Col xl={6} lg={6} md={12}>
                              <Form.Label htmlFor="location">Location</Form.Label>
                              <Form.Control
                                  type="text"
                                  id="location"
                                  name="location"
                                  disabled={processing?true:false}
                                  defaultValue={this.state.location} 
                                  onChange={this.handleChange}
                              />
                              {errors.location.length > 0 && 
    <span className='text-danger'>{errors.location}</span>}
                          </Col>
                          <Col xl={6} lg={6} md={12}>
                              <Form.Label htmlFor="contact_email">Contact Email</Form.Label>
                              <Form.Control
                                  type="text"
                                  id="contact_email"
                                  name="contact_email"
                                  disabled={processing?true:false}
                                  defaultValue={this.state.contact_email} 
                                  onChange={this.handleChange}
                              />
                          {errors.contact_email.length > 0 && 
    <span className='text-danger'>{errors.contact_email}</span>}
                          </Col>
                          
                          <Col xl={12} lg={12} md={12}>
                              <Form.Label htmlFor="contact_phone">Contact Phone</Form.Label>
                              <Form.Control
                                  type="text"
                                  id="contact_phone"
                                  name="contact_phone"
                                  disabled={processing?true:false}
                                  defaultValue={this.state.contact_phone} 
                                  onChange={this.handleChange}
                              />
                              {errors.contact_phone.length > 0 && 
    <span className='text-danger'>{errors.contact_phone}</span>}
                          </Col>
                          <Col xl={12} lg={12} md={12}>
                              <Form.Label htmlFor="welcome_note">Welcome note</Form.Label>
                              <Form.Control
                                  as="textarea"
                                  id="welcome_note"
                                  name="welcome_note"
                                  disabled={processing?true:false}
                                  defaultValue={this.state.welcome_note} 
                                  onChange={this.handleChange}
                                  maxLength="600"
                              />
                              {errors.welcome_note.length > 0 && 
    <span className='text-danger'>{errors.welcome_note}</span>}
                          </Col>
                          <Col xl={12} lg={12} md={12}>
                              <Form.Label htmlFor="mission">Mission</Form.Label>
                              <Form.Control
                                  as="textarea"
                                  id="mission"
                                  name="mission"
                                  disabled={processing?true:false}
                                  defaultValue={this.state.mission} 
                                  onChange={this.handleChange}
                                  maxLength="250"
                              />
                              {errors.mission.length > 0 && 
    <span className='text-danger'>{errors.mission}</span>}
                          </Col>
                          <Col xl={12} lg={12} md={12}>
                              <Form.Label htmlFor="vision">Vision</Form.Label>
                              <Form.Control
                                  as="textarea"
                                  id="vision"
                                  name="vision"
                                  disabled={processing?true:false}
                                  defaultValue={this.state.vision} 
                                  onChange={this.handleChange}
                                  maxLength="250"
                              />
                              {errors.vision.length > 0 && 
    <span className='text-danger'>{errors.vision}</span>}
                          </Col>

                          <Col xl={6} lg={6} md={12}>
                              <Form.Label htmlFor="vision">About photo</Form.Label>
                              <Form.Control
                                type="file"
                                  id="image"
                                  name="image"
                                  accept=".jpg,.png"
                                  disabled={processing?true:false} 
                              />
                              {errors.image.length > 0 && 
    <span className='text-danger'>{errors.image}</span>}
                          </Col>
                          
                          <Col xl={6} lg={6} md={12}>
                                  <img className="img-responsive" src={this.state.settings.about_photo?Constants.publicUrl+"/images/"+this.state.settings.about_photo:avatar} alt="" />

                          </Col>
                          
                          

                      </Row>
                      </fieldset>
                      
                      

                  
                        <div className="chooseUs">
                          <fieldset>
                            <legend className="mb-3">Why to choose Afrifame</legend>
                            <Row>
                            <Col xl={12} lg={12} md={12}>
                              <Form.Label htmlFor="vision">Variety</Form.Label>
                              <Form.Control
                                  as="textarea"
                                  id="variety"
                                  name="variety"
                                  disabled={processing?true:false}
                                  defaultValue={this.state.variety} 
                                  onChange={this.handleChange}
                                  maxLength="250"
                              />
                              {errors.variety.length > 0 && 
    <span className='text-danger'>{errors.variety}</span>}
                          </Col>

                          <Col xl={12} lg={12} md={12}>
                              <Form.Label htmlFor="vision">Professionalism</Form.Label>
                              <Form.Control
                                  as="textarea"
                                  id="professionalism"
                                  name="professionalism"
                                  disabled={processing?true:false}
                                  defaultValue={this.state.professionalism} 
                                  onChange={this.handleChange}
                                  maxLength="250"
                              />
                              {errors.professionalism.length > 0 && 
    <span className='text-danger'>{errors.professionalism}</span>}
                          </Col>

                          <Col xl={12} lg={12} md={12}>
                              <Form.Label htmlFor="vision">TrustWorthy</Form.Label>
                              <Form.Control
                                  as="textarea"
                                  id="trustworthy"
                                  name="trustworthy"
                                  disabled={processing?true:false}
                                  defaultValue={this.state.trustworthy} 
                                  onChange={this.handleChange}
                                  maxLength="250"
                              />
                              {errors.trustworthy.length > 0 && 
    <span className='text-danger'>{errors.trustworthy}</span>}
                          </Col>

                          <Col xl={12} lg={12} md={12}>
                              <Form.Label htmlFor="vision">Increase Revenue</Form.Label>
                              <Form.Control
                                  as="textarea"
                                  id="increase_revenue"
                                  name="increase_revenue"
                                  disabled={processing?true:false}
                                  defaultValue={this.state.increase_revenue} 
                                  onChange={this.handleChange}
                                  maxLength="250"
                              />
                              {errors.increase_revenue.length > 0 && 
    <span className='text-danger'>{errors.increase_revenue}</span>}
                          </Col>
                            </Row>
                          </fieldset>
                        </div>
                      <p className="d-grid gap-2">
                          <Button type="submit" variant="primary" disabled={processing?true:false} size="lg">Update</Button>
                      </p>
                  </form>



                  </div>
              </Col>
            </Row>
          </Container>
          <div className="home-bg"></div>
          <div className="overlay"></div>
          <ToastContainer/>
        </div>                    
    }

    return html;
  }
}

export default SettingsPage
