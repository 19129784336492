import axios from "axios";

// const getUserById = (id)=>{
//     return axios.get('/users/'+id);
// }
// export {getUserById};

class Api{
    getSubscriptions= ()=>{
        return axios.get('/subscriptions');
    }
    loadMoreSubscriptions = (url)=>{
        return axios.get(url);
    }
    filterByDate = (date1,date2)=>{
        return axios.get('/subscriptions?date1='+date1+'&date2='+date2);
    }
    filterByReferenceNumber = (number)=>{
        return axios.get('/subscriptions?reference='+number);
    }
}
const SubscriptionApi = new Api();
export {SubscriptionApi};