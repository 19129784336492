import { faCheck,faExclamationTriangle, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Badge,Button,Col, Dropdown, Row } from 'react-bootstrap'
import { Constants } from '../../../constants';
import avatar from '../../../assets/images/avatar.png';
import { ToastContainer, toast } from 'react-toastify';
import ShareButtons from '../../../common/ShareButtons';

class UserDetailsHeader extends Component {
  constructor(props){
    super(props);
  }

  static defaultProps = {
    bg:"",
    border:"",
    usage:"admin",
    handleProceedToBooking:()=>{}
  }

  state = {
    showShareButtons:false
  }

  handleShareButtonsClosed = ()=>{
    this.setState({showShareButtons:false});
  }
  handleShowShareButtons = ()=>{
    this.setState({showShareButtons:true});
  }

  render() {
    const { user,status } = this.props;
    
    let image = '';
    if(user.photo1){
      image = Constants.publicUrl+'/images/'+user.photo1;
    }
    else{
      image = avatar;
    }

    const rolesColors = {Model:'success',Actor:'warning',Admin:'info'};
    let roles = '';
    if(user){
      roles = user.roles.map((role)=>{
          return <Badge key={role.id} bg={rolesColors[role.name]} className="mr-1">{role.name}</Badge>;
      })
    }
  
    //const roles = JSON.parse(user.roles);

    let statusIcon = faExclamationTriangle;
    if(status){
      statusIcon = faCheck;
    }
    const rolesButton = <Dropdown align="end">
        <Dropdown.Toggle variant="outline-danger" id="dropdown-basic">
          Options
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={this.props.showEditUserModal}>Edit User</Dropdown.Item>
          <Dropdown.Item onClick={this.props.showChangeUserModal}>Change Role</Dropdown.Item>
          <Dropdown.Item onClick={this.props.sendResetLink}>Send Password Reset Link</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    const shareButton = <Button variant="light" className="no-border" onClick={this.handleShowShareButtons}>
      <FontAwesomeIcon icon={faShareNodes}/> Share
    </Button>

    const proceedToBookingButton = <Button className="mb-3" onClick={this.props.handleProceedToBooking}>
      Proceed to Booking
    </Button>
      
    return (
      <div className="user-profile-header mb-3 p-4">
        <Row>
          <Col lg={2} md={3} xs={3}>
            <div className="picture">
              <img className="img-responsive" src={image} alt="" />
            </div>
            
          </Col>
          <Col lg={8} md={9} xs={9} className="pt-2">
            <h2 className="title text-white"> {user.first_name} {user.last_name}&nbsp;&nbsp;
              <small className={this.props.usage=="admin"?"":"d-none"}>
                <Badge bg={status?'success':'danger text-white'}><FontAwesomeIcon icon={statusIcon}/> {status?'Active Subscription':'Subscription not yet activated'}</Badge>
              </small>
            </h2>
            
            {roles}
            <span className={this.props.usage=="admin"?"":"d-none"}>
            <Badge bg="light" className="text-dark">Gender: {user.gender?user.gender:'-'}</Badge>&nbsp;
            </span>
            
            <div className="mb-3"></div>
            {this.props.usage=="public"?proceedToBookingButton:""}
          </Col>
          <Col lg={2} md={12} xs={12}>
            <div className="float-right">
              {this.props.usage=="public"?shareButton:rolesButton}
              
              </div>

          </Col>
        </Row>
        <ToastContainer/>
        <ShareButtons url={`${Constants.siteUrl}/talent/${user.id}`} show={this.state.showShareButtons} handleClose={this.handleShareButtonsClosed}/>
      </div>
    )
  }
}

export default UserDetailsHeader
