import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaySubscription from '../admin/subscriptions/Pay'

class SubscriptionRequired extends Component {

  state = {
    showPaySubscription:false
  }

  handlePaySubscriptionHideModal = ()=>{
    this.setState({showPaySubscription:false})
  }
  showPaySubscriptionModal = ()=>{
    this.setState({showPaySubscription:true})
  }
  showPaySubscription = ()=>{
    this.setState({showPaySubscription:true})
  }


  render() {
    if(this.props.user){
      return (
        <div className="subscription">
          <Row>
              <Col xs={4} md={3} lg={2} className="leftside">
                  <div className="icon-wrapper">
                      <FontAwesomeIcon icon={faExclamationTriangle} size="xl"/>
                  </div>
              </Col>
              <Col xs={8} md={9} lg={10}>
                  <h2 className="title">Subscription</h2>
                  <p>Your account has a past due balance. Please submit a payment of RWF 15,000 before Saturday, May 21 to prevent account suspension</p>
                  <Button onClick={this.showPaySubscription} className="btn btn-primary">Renew now</Button>
                  <Link to="/learn-more" className="btn btn-light">Learn more</Link>
              </Col>
          </Row>
          <PaySubscription show={this.state.showPaySubscription} user={this.props.user} handleHideModal={this.handlePaySubscriptionHideModal}/>
        </div>
      )
    }
  }
}

export default SubscriptionRequired
