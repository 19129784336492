import axios from "axios";

class AuthService{
    roles=[];
    user = '';
    token = '';

    getRoles=()=>{
        return this.roles;
    }
    userIsAdmin = ()=>{
        if(this.roles){
            let admin = this.roles.find((role)=>{
                return role.name=='Admin';
            });
            if(admin) return true;
        }
        return false;
    }
    userIsModel = ()=>{
        if(this.roles){
            let model = this.roles.find((role)=>{
                return role.name=='Model';
            });
            if(model) return true;
        }
        return false;
    }
    userIsActor = ()=>{
        if(this.roles){
            let actor = this.roles.find((role)=>{
                return role.name=='Actor';
            });
            if(actor) return true;
        }
        return false;
    }

    checkRole = (roles,roleToCheck)=>{
        if(roles){
            const currentRole = roles.find((role)=>{
                return role.name==roleToCheck;
            });
            if(currentRole) return true;
        }
        return false;
    }

    getCurrentUser = ()=>{
        return this.user;
    }
    setRoles = (roles)=>{
        this.roles=roles;
        localStorage.setItem('roles',JSON.stringify(roles));
    }
    setUser = (user)=>{
        this.user=user;
        localStorage.setItem('user',JSON.stringify(user));
    }
    login = (data)=>{
        return axios.post('/login',data);
    }
    logout = ()=>{
        this.token='';
        this.user='';
        this.roles=[];

        localStorage.setItem('token',null);
        localStorage.setItem('user',null);
        localStorage.setItem('roles',null);
        return true;
        //return axios.post('/logout');
    }
    register=(data)=>{
        return axios.post('/register',data)
    }
}
const Auth = new AuthService();
export { Auth }