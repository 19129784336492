import { faComments } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class HelpButton extends Component {
  render() {
    return (
      <div className="sidebar help-button text-center">
          <div className="circle comments">
            <FontAwesomeIcon icon={faComments}/>
          </div>
        <p>Need Help?</p>
        <Link to="" className="btn btn-primary btn-block">Contact Us</Link>
      </div>
    )
  }
}

export default HelpButton
