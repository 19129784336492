import axios from "axios";

const SettingsApi = {
    loadSettings: ()=>{
        return axios.get('/settings');
    },
    updateSettings:(id,data)=>{
        return axios.post(`/settings/${id}`,data);
    }
}

export default SettingsApi;