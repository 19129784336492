import { faBriefcase, faEnvelope, faHome, faQuestionCircle, faUsers, faUserCircle,
         faIdBadge, faCodePullRequest, faMoneyBill, faArrowCircleLeft, faComments, faUnlock, faUser, faCogs } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import React, { Component } from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Auth } from '../../apis/Auth';

export class UserSidebarNavMobile extends Component {
  
    render() {
        
      const { onClickHandler } = this.props;
        
      let userSignInButtons = '';

    if(!Auth.user){
      userSignInButtons = <Nav className="flex-column">
      <Nav.Link as={Link} to="/signin"><FontAwesomeIcon icon={faUnlock}/> Signin</Nav.Link>
      <Nav.Link as={Link} to="/register"><FontAwesomeIcon icon={faUser}/> Register</Nav.Link>
    </Nav>
    }

    const publicNavigation = <div className="sidebar">
    <Nav defaultActiveKey="/" className="flex-column">
    <Link className="nav-link" to="/" onClick={onClickHandler}><FontAwesomeIcon icon={faHome}/> Home</Link>
    <Link className="nav-link" onClick={onClickHandler} to="/talents"><FontAwesomeIcon icon={faUsers}/> Talents</Link>
    {/* <Link className="nav-link" onClick={this.closeNavModal}><FontAwesomeIcon icon={faUsers}/> Models</Link>
    <Link className="nav-link" onClick={this.closeNavModal}><FontAwesomeIcon icon={faUsers}/> Actors</Link> */}
    <Link className="nav-link" onClick={onClickHandler} to="/faq"><FontAwesomeIcon icon={faQuestionCircle}/> FAQs</Link>
    <Link className="nav-link" onClick={onClickHandler} to="/about"><FontAwesomeIcon icon={faBriefcase}/> About Us</Link>
    <Link className="nav-link" onClick={onClickHandler} to="/contact"><FontAwesomeIcon icon={faEnvelope}/> Contact Us</Link>
    <Link className="nav-link" oonClick={onClickHandler} to="/contact"><FontAwesomeIcon icon={faComments}/> Support</Link>
    </Nav>
    { userSignInButtons }
</div>

      if(Auth.userIsAdmin()){
        return (
            <div>
                { publicNavigation }

                    <hr />
                <div className="sidebar">
                    <Nav className="flex-column">
                        <Link className="nav-link"  onClick={onClickHandler}  to="/account">
                            <FontAwesomeIcon icon={faUserCircle} /> Profile</Link>
                        <Link className="nav-link"  onClick={onClickHandler}  to="/inquiries">
                        <FontAwesomeIcon icon={faCodePullRequest} /> Inquiries</Link>
                        <Link className="nav-link" onClick={onClickHandler}  to="/users">
                        <FontAwesomeIcon icon={faUsers} /> Users</Link>
                        <Link className="nav-link"  onClick={onClickHandler}  to="/subscriptions">
                        <FontAwesomeIcon icon={faMoneyBill} /> Payments</Link>
                        <Link className="nav-link"  onClick={onClickHandler}  to="/staff">
                        <FontAwesomeIcon icon={faUserCircle} /> Staff</Link>
                        <Link className="nav-link"  onClick={onClickHandler}  to="/settings">
                        <FontAwesomeIcon icon={faCogs} /> Settings</Link>
                        <Button className="nav-link" onClick={this.props.logout}><FontAwesomeIcon icon={faArrowCircleLeft} /> Logout</Button>
                    </Nav>
                </div>
            </div>
        )
      }
      else if(Auth.user){
        return (
            <div>
                { publicNavigation }

                    <hr />
                <div className="sidebar">
                    <Nav className="flex-column">
                        <Link className="nav-link" to="/account">
                            <FontAwesomeIcon icon={faUserCircle} /> Profile</Link>
                        <Link className="nav-link" onClick={onClickHandler}  to="subscription">
                        <FontAwesomeIcon icon={faIdBadge} /> Subscription</Link>
                        <Button className="nav-link"  onClick={this.props.logout}><FontAwesomeIcon icon={faArrowCircleLeft} /> Logout</Button>
                    </Nav>
                </div>
            </div>
            
        )
      }
      else{
          return publicNavigation;
      }
  }
}

export default UserSidebarNavMobile
