import SEO from '@americanexpress/react-seo/build/components/SEO'
import React, { Component } from 'react'
import Header from '../../common/Header'
import { Constants } from '../../constants'
import '../../assets/css/actors.css';
import '../../assets/css/pub-sidebar.css';
import { Col, Container, FormControl, Row, Spinner } from 'react-bootstrap';
import { HomeApi } from '../../apis/HomeApi';
import SingleModelActor from '../../components/public/SingleModelActor';
import HelpButton from '../../components/user/HelpButton';
import Booking from '../../components/public/Booking';


class ActorsPage extends Component {
  state={
    actors:[],
    currentPage:0,
    loadingMore:false,
    loading:true,
    totalActors:0,
    searchInput:'',
    bookingItems:[]
  }
  componentDidMount(){
      window.addEventListener('scroll', this.loadMore);
      this.loadDataOnline();
  }
  loadDataOnline = ()=>{
    const page = this.state.currentPage;
    HomeApi.getUsersByType('actor',(page+1))
      .then(res=>{console.log(res,(page+1));
        let actors = this.state.actors;

        if(this.state.loadingMore){
          actors =  actors.concat(res.data.data);
        }
        else{
          actors = res.data.data;
        }

        this.setState({loading:false,actors:actors,currentPage:res.data.current_page,totalActors:res.data.total,loadingMore:false});
      })
  }
  handleSearchInputChange = (e)=>{
    const input = e.target.value;
    this.searchActor(input);
  }
  searchActor = (search)=>{
    //if(search){
      this.setState({currentPage:0,searchInput:search});
      HomeApi.searchUserByTypeAndName('actor',search,0)
      .then(res=>{
        this.setState({actors:res.data.data,currentPage:res.data.current_page,totalActors:res.data.total,loadingMore:false});
      })
    //}
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.loadMore);
  }
  loadMore = ()=>{
      if(this.state.searchInput){
        if (!this.state.loadingMore&&window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
          
          if(this.state.actors.length&&this.state.totalActors > this.state.actors.length){
            this.setState({loadingMore:true});

            const page=this.state.currentPage;

            HomeApi.searchUserByTypeAndName('actor',this.state.searchInput,(page+1))
            .then(res=>{ 
              let actors = this.state.actors;
              actors = actors.concat(res.data.data);

              this.setState({actors:actors,currentPage:res.data.current_page,totalActors:res.data.total,loadingMore:false});
            })
          }
        }
      }
      else{
        if (!this.state.loadingMore&&window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
          if(this.state.actors.length&&this.state.totalActors > this.state.actors.length){
            this.setState({loadingMore:true});
            this.loadDataOnline();
          }
      }
      }
  }
  handleAddToBooking = (e)=>{
    let items = this.state.bookingItems;

    const id = e.target.value;
    const selectedItem = this.state.actors.filter(val=>{
        return val.id == id;
    });

    items = items.concat(selectedItem);
    this.setState({bookingItems:items});
  }
  handleRemoveItem = (id)=>{
    let items = this.state.bookingItems;

    const selectedItem = items.filter(val=>{
        return val.id != id;
    });
    console.log('id',id)

    this.setState({bookingItems:selectedItem});
  }

  handleBookingSuccess = ()=>{
    this.setState({bookingItems:[]});
  }


  render() {
    const {actors} = this.state;
    let actorItems = "";
    if(actors){
      actorItems = actors.map((val,index)=>{
        return <SingleModelActor showBookingBtn={true} selectedItems={this.state.bookingItems} addToBooking={this.handleAddToBooking} lg={4} xl={4} user={val} key={index}/>
      })
    }
    return (
      <div className="actorsPage">
        <SEO
          title="Talented actors - Afrifame"
          description="We connect you with Rwandan talented actors of your choice."
          keywords={['Rwandese actors', 'Rwandese actors']}
          siteUrl={Constants.publicUrl}
          image={{
            src: 'http://example.com/foo.jpg'
          }}
        />

        <Header background={false} />
        <Container>
            <h2 className="page-title mt-5">Find actors</h2>
            <div className="mb-5">
              <Row>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>

                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="float-right">
                      <FormControl
                        type="text"
                        id="search_input"
                        name="search"
                        className="search_input"
                        placeholder="Search model by name"
                        onChange = {this.handleSearchInputChange}
                        />
                    </div>
                    <div className="clearfix"></div>
                </Col>
              </Row>
            </div>
            <div className="talents-list">
              <div className="actors actors-wrapper">
                <Row>
                  <Col xl={9} lg={9} md={7} sm={12} xs={12}>
                    <Row>
                      {actorItems}
                      <Col xs={12}>
                          <div className={this.state.loadingMore||this.state.loading?"d-block text-center":"d-none"}>
                            <Spinner animation="grow" variant="light"/>
                          </div>
                      </Col>
                      <Col xs={12}>
                          <div className={!this.state.loading&&this.state.actors&&!this.state.actors.length?"d-block":"d-none"}>
                            No actors found
                          </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={3} lg={3} md={5} sm={12} xs={12}>
                    <div className="pub-sidebar">
                      <Booking selectedItems={this.state.bookingItems} handleBookingSuccess={this.handleBookingSuccess} handleRemoveItem={this.handleRemoveItem}/>
                      <HelpButton />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
        </Container>
      </div>
    )
  }
}

export default ActorsPage
