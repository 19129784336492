import React, { Component } from 'react'
import { Button, Col, Container, Dropdown, Form, ProgressBar, Row } from 'react-bootstrap'
import Header from '../../common/Header'
import UserSidebarNav from '../../components/user/UserSidebarNav'
import HelpButton from '../../components/user/HelpButton'
import { UserApi } from '../../apis/UserApi'


import { ToastContainer, toast } from 'react-toastify';
import { Auth } from '../../apis/Auth'
import { Redirect } from 'react-router-dom'

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);


class UpdateProfilePage extends Component {
    state = {
        user:'',
        processing:false,
        updated:false,
        first_name_valid:true,
        gender_valid:true,
        email_valid:true,
        phone_valid:true,
        errors:{
          first_name:'',
          last_name:'',
          email:'',
          phone:'',
          location:'',
          height:'',
          instagram:'',
          gender:'',
          password:''
        },
        first_name:'',
        last_name:'',
        email:'',
        phone:'',
        location:'',
        height:'',
        instagram:'',
        gender:'',
        formValid:false
    }

    componentDidMount(){
      UserApi.getProfile()
      .then(response=>{
        this.setState({
          user:response.data,
          first_name:response.data.first_name,
          last_name:response.data.last_name,
          email:response.data.email,
          phone:response.data.phone,
          location:response.data.location,
          height:response.data.height,
          instagram:response.data.instagram,
          gender:response.data.gender
      });

      document.documentElement.scrollTop=0;

      })
    }

    // handleChange = (e)=>{
    //   this.setState({[e.target.name]:e.target.value});
    //   this.inputValidation(e);
    // }

    // inputValidation = (e)=>{
    //   let errors = this.state.errors;

    //   if(e.target.name=='first_name'){
    //     if(!e.target.value){
    //       errors[e.target.name] = 'First name is required';
    //       this.setState({'formValid':false,errors:errors});
    //     }
    //     else{
    //       errors[e.target.name] = '';
    //       this.setState({'formValid':true,errors:errors});
    //     }
    //   }
    //   if(e.target.name=='last_name'){
        
    //   }
    //   if(e.target.name=='gender'){
    //     if(!e.target.value){
    //       errors[e.target.name] = 'Gender is is required';
    //       this.setState({'formValid':false,errors:errors});
    //     }
    //     else{
    //       errors[e.target.name] = '';
    //       this.setState({'formValid':true,errors:errors});
    //     }
    //   }
    //   if(e.target.name=='email'){
    //     if(!e.target.value){
    //       errors[e.target.name] = 'Email is required';
    //       this.setState({'formValid':false,errors:errors});
    //     }
    //     else{
    //       if(!validEmailRegex.test(e.target.value)){
    //         errors[e.target.name] = 'Invalid email';
    //         this.setState({'formValid':false});
    //       }
    //       else{
    //         errors[e.target.name] = '';
    //         this.setState({'formValid':true});
    //       }
    //     }
    //   }
    //   if(e.target.name=='phone'){
    //     errors[e.target.name] = '';
    //         this.setState({'formValid':true});
    //   }
    //   if(e.target.name=='location'){
    //     errors[e.target.name] = '';
    //     this.setState({'formValid':true});
    //   }
    //   if(e.target.name=='height'){
    //     errors[e.target.name] = '';
    //         this.setState({'formValid':true});
    //   }
    //   if(e.target.name=='instagram'){
    //     errors[e.target.name] = '';
    //         this.setState({'formValid':true});
    //   }
      
    // }



    validateForm = (errors) => {
      let valid = true;
      
      if(!this.state.first_name_valid){
          errors['first_name'] = 'First name is required';
      }
      if(!this.state.email_valid){
        errors['gender'] = 'Gender is required';
    }
      if(!this.state.email_valid){
          errors['email'] = 'Email is required';
      }
      if(!this.state.password_valid){
          errors['password'] = 'Password is required';
      }

      this.setState({errors:errors});

      Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
      );
      
      return valid;
  }

  handleChange = (event) => {
      event.preventDefault();
      const { name, value } = event.target;
      let errors = this.state.errors;
      
      switch (name) {
        case 'first_name': 
          errors.first_name = 
            value.length < 2
              ? 'First Name must be 2 characters long!'
              : '';
          break;
          case 'gender': 
          errors.gender = 
            value.length==0
              ? 'Your Gender is required'
              : '';
          break;
        case 'email': 
          errors.email = 
            validEmailRegex.test(value)
              ? ''
              : 'Email is not valid!';
          break;
        case 'phone': 
          errors.phone = 
            value.length==0
              ? 'Your Phone number is required'
              : '';
          break;
        case 'password': 
          errors.password = 
            value.length < 8
              ? 'Password must be 8 characters long!'
              : '';
          break;
        default:
          break;
      }

      let fieldValid = !errors[name]?true:false;
      let fieldNameValid = name+'_valid';

    
      this.setState({errors, [name]: value, [fieldNameValid]:fieldValid})
    }






    handleSubmit = (e)=>{
      e.preventDefault();
      if(this.validateForm(this.state.errors,["first_name","gender","email","phone","password"])){
        const toastId = toast.loading("Please wait...",{position:'bottom-right'});

        UserApi.updateProfile({
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          phone: this.state.phone,
          location: this.state.location,
          height: this.state.height,
          instagram: this.state.instagram,
          gender:this.state.gender,
          password: this.state.password
        })
        .then((response)=>{
          const {data} = response.data;
          if(data&&data.user){
            Auth.setUser(data.user);
          }
          this.setState({updated:true,processing:false});
          toast.update(toastId, 
                {
                  render: "Request processed successfully", 
                  type: "success", 
                  isLoading: false,
                  autoClose: 5000 ,
                  onclose:()=>{console.log('closed');
                    this.setState({updated:true,processing:false});
                  }
                });
        })
        .catch(err=>{console.log(err);
          toast.update(toastId, {render: err?.response?.data?.message??'Something went wrong', type: "error", isLoading: false,autoClose: 5000 });
          if(err?.response?.status==422){
            let errors = this.state.errors;

            if (err.response.data.errors.first_name) {
              errors['first_name']=err.response.data.errors.first_name[0];
            }
            if (err.response.data.errors.last_name) {
              errors['last_name']=err.response.data.errors.last_name[0];
            }
            if (err.response.data.errors.email) {
              errors['email']=err.response.data.errors.email[0];
            }
            if (err.response.data.errors.instagram) {
              errors['instagram']=err.response.data.errors.instagram[0];
            }
            if (err.response.data.errors.gender) {
              errors['gender']=err.response.data.errors.gender[0];
            }
            if (err.response.data.errors.password) {
              errors['password']=err.response.data.errors.password[0];
            }

            this.setState({processing:false,errors:errors});
          }
        })
      }
    }

  render() {
    const {user,processing,errors,formValid,updated} =this.state;
    if(updated){
      return <Redirect to={"/account"}/>
    }

    console.log(user)
    return (
      
    <div>
        <Header background={false} />
        <Container className="content-on-bg mt-3">
          <Row>
            <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
              <UserSidebarNav />
              <HelpButton />
            </Col>
            <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                <div className="welcome mt-0">
                <h1 className="title mb-4">Update Profile Information</h1>
                <form onSubmit={this.handleSubmit}>

                <Row>
                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="firstname">First Name</Form.Label>
                            <Form.Control
                                type="text"
                                id="firstname"
                                name="first_name"
                                disabled={processing?true:false}
                                defaultValue={this.state.first_name} 
                                onChange={this.handleChange}
                            />
                            {errors.first_name.length > 0 && 
  <span className='text-danger'>{errors.first_name}</span>}
                        </Col>
                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="lastname">Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                id="lastname"
                                name="last_name"
                                disabled={processing?true:false}
                                defaultValue={this.state.last_name} 
                                onChange={this.handleChange}
                            />
                        </Col>

                        <Col xl={12} lg={12} md={12}>
                          <div className="mb-3 mt-2">
                            <Row>
                              <Col xl={3} lg={3} md={6} sm={6} xs={6}>
                                <Form.Check 
                                    type='radio'
                                    name="gender"
                                    id='female'
                                    label='Female'
                                    value="Female"
                                    checked={user.gender=='Female'||this.state.gender=='Female'}
                                    onChange={this.handleChange}
                                  />
                              </Col>
                              <Col xl={3} lg={3} md={6} sm={6} xs={6}>
                                <Form.Check 
                                    type='radio'
                                    name="gender"
                                    id='male'
                                    label='Male'
                                    checked={user.gender=='Male'||this.state.gender=='Male'}
                                    value="Male"
                                    onChange={this.handleChange}
                                  />

                              </Col>
                            </Row>
                            {errors.gender.length > 0 && 
  <span className='text-danger'>{errors.gender}</span>}
                          </div>
                        </Col>
                        
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                                type="text"
                                id="email"
                                name="email"
                                disabled={processing?true:false}
                                defaultValue={this.state.email} 
                                onChange={this.handleChange}
                            />
                            {errors.email.length > 0 && 
  <span className='text-danger'>{errors.email}</span>}
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="phone">Phone</Form.Label>
                            <Form.Control
                                type="text"
                                id="phone"
                                name="phone"
                                disabled={processing?true:false}
                                defaultValue={this.state.phone} 
                                onChange={this.handleChange}
                            />
                            {errors.phone.length > 0 && 
  <span className='text-danger'>{errors.phone}</span>}
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="location">Location</Form.Label>
                            <Form.Control
                                type="text"
                                id="location"
                                name="location"
                                disabled={processing?true:false}
                                defaultValue={this.state.location} 
                                onChange={this.handleChange}
                            />
                            {errors.location.length > 0 && 
  <span className='text-danger'>{errors.location}</span>}
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="email">Height</Form.Label>
                            <Form.Control
                                type="text"
                                id="height"
                                name="height"
                                disabled={processing?true:false}
                                defaultValue={this.state.height} 
                                onChange={this.handleChange}
                            />
                            {errors.height.length > 0 && 
  <span className='text-danger'>{errors.height}</span>}
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="instagram">Instagram</Form.Label>
                            <Form.Control
                                type="text"
                                id="instagram"
                                name="instagram"
                                disabled={processing?true:false}
                                defaultValue={this.state.instagram} 
                                onChange={this.handleChange}
                            />
                            {errors.instagram.length > 0 && 
  <span className='text-danger'>{errors.instagram}</span>}
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="password">Password</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Enter your current password to confirm it's you"
                                disabled={processing?true:false}
                                onChange={this.handleChange}
                            />
                            {errors.password.length > 0 && 
  <span className='text-danger'>{errors.password}</span>}
                        </Col>
                    </Row><br />
                    <p className="d-grid gap-2">
                        <Button type="submit" variant="primary" disabled={processing||!this.state.user?true:false} size="lg">Update</Button>
                    </p>
                </form>



                </div>
            </Col>
          </Row>
        </Container>
        <div className="home-bg"></div>
        <div className="overlay"></div>
        <ToastContainer/>
      </div>
    )
  }
}

export default UpdateProfilePage
