import { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify";
import { Auth } from "../../../apis/Auth";
import { UserApi } from "../../../apis/UserApi";

class ChangeUserRole extends Component{
    constructor(props){
        super(props);

        this.state = {
            user_id:this.props.user.id,
            password:'',
            roles:{
                model:Auth.checkRole(this.props.user.roles,'Model')?'model':'',
                actor:Auth.checkRole(this.props.user.roles,'Actor')?'actor':'',
                admin:Auth.checkRole(this.props.user.roles,'Admin')?'admin':'',
            },
            errors:{
                password:''
            }
        }

    }
    
    handleRoleChange = (e)=>{
        let roles = this.state.roles;
        const elm = document.getElementById(e.target.id);
        if(elm.checked==true){
            roles[e.target.name] = e.target.value;
        }
        else{
            roles[e.target.name] = '';
        }

        this.setState({roles:roles});
    }
    handleChange = (e)=>{
        const name = e.target.name;
        this.setState({[name]:e.target.value});
    }
    handleSubmit = (e)=>{
        e.preventDefault();
        let roles = this.state.roles;
        const formValid = this.state.password&&(roles.admin==='admin'||roles.model==='model'||roles.actor==='actor');


        if(formValid){
            const validatedRoles = Object.values(roles).filter(val=>{
                return val!='';
            });

            const userId = document.getElementById('user_id');

            const data = {
                user_id:userId.value,
                password:this.state.password,
                roles: validatedRoles
            };

            const toastId = toast.loading('Processing...',{position:'bottom-right'});


            UserApi.changeUserRole(data)
                .then(response=>{
                    this.props.onRoleChanged();
                    toast.update(toastId,{
                        isLoading: false,
                        autoClose: 5000,
                        type:'success',
                        render: 'User Role updated successfully'
                    });
                })
                .catch(err=>{console.log(err);
                    if(err?.response?.status==422){
                        const errors = err.response.data.errors;
                        let inputErrors = this.state.errors;
    
                        for (const key in errors) {
                            inputErrors[key]=errors[key][0];
                        }
                        this.setState({errors:inputErrors});

                        toast.update(toastId,{
                            isLoading: false,
                            autoClose: 5000,
                            type:'error',
                            render: 'Invalid role inputs'
                        });
                    }
                    else{
                        toast.update(toastId,{
                            isLoading: false,
                            autoClose: 5000,
                            type:'error',
                            render: err?.response?.data?.message??'Something went wrong'
                        });
                    }
                });
        }
        else if(!this.state.password){
            let errors = this.state.errors;
            errors['password']='Password is required';
            this.setState({errors});
        }
    }
    render(){
        const { roles, errors } = this.state;
        const { first_name,last_name,id }=this.props.user;

        const formValid = roles.admin==='admin'||roles.model==='model'||roles.actor==='actor';

        return (

            <div>
                <Modal className="modalWrapper" show={this.props.show} fullscreen="md-down" onHide={this.props.handleHideModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Change user Role</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert-info p-2">You are about to modify user: {first_name } {last_name}</div>
                        <form onSubmit={this.handleSubmit}>
                            <Form.Check
                                        className="large"
                                        inline="true"
                                        label="Actor / Actress"
                                        name="actor"
                                        type='checkbox'
                                        id='actor'
                                        value="actor"
                                        checked={roles.actor=='actor'}
                                        onChange={this.handleRoleChange}
                                    />
                                <Form.Check
                                        className="large"
                                        inline="true"
                                        label="Model"
                                        name="model"
                                        type='checkbox'
                                        id='model'
                                        value="model"
                                        checked={roles['model']=='model'}
                                        onChange={this.handleRoleChange}
                                    />
                                <Form.Check
                                        className="large"
                                        inline="true"
                                        label="Admin"
                                        name="admin"
                                        type='checkbox'
                                        id='admin'
                                        value="admin"
                                        checked={roles['admin']=='admin'}
                                        onChange={this.handleRoleChange}
                                    />
                                 <Form.Control
                                        className="large"
                                        inline="true"
                                        name="user_id"
                                        type='hidden'
                                        id='user_id'
                                        value={id}
                                        onChange={this.handleChange}
                                    />
                                <Form.Label htmlFor="birthdate">Password</Form.Label>
                                <Form.Control
                                        className="large"
                                        inline="true"
                                        name="password"
                                        type='password'
                                        id='password'
                                        placeholder="Enter your password"
                                        onChange={this.handleChange}
                                    />
                                {errors.password.length > 0 && 
  <div className='text-danger'>{errors.password}</div>}   

                            <div className="text-right">
                                <Button variant="primary" type="submit" className="float-right" disabled={!formValid}>Update</Button>
                            </div>
                            <div className="clearfix"></div>
                        </form> 
                </Modal.Body>
                </Modal>
                <ToastContainer/>
            </div>
        )
    }
    
}
export default ChangeUserRole