import React, { Component } from 'react'
import { Badge } from 'react-bootstrap'
import '../../assets/css/choose-us-item.css';
class ChooseUsItem extends Component {
  render() {
      const { title, description, bg} = this.props;
    return (
      <div className="item">
          <Badge className="badge" bg={bg}> </Badge>
          <h4 className="title mt-3 mb-3">{title}</h4>
          <p>{description}</p>
      </div>
    )
  }
}

export default ChooseUsItem
