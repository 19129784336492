import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { faCcVisa} from '@fortawesome/free-brands-svg-icons'

class SubscriptionItem extends Component {
  render() {
      const { showName, subscription, user } = this.props;
    return (
        <div className="item p-0">
            <div className="p-2">
        <Row>
            <Col xl={7} lg={7} md={7} sm={12} xs={12}>
            <div className="item">
                <div className={showName?'mb-2':'d-none'}>
                <strong>{user.first_name} {user.last_name}</strong>
                </div>
                <strong>#{subscription.reference_number}</strong>
                <p className={showName?'d-none':'pt-2'}>
                    <small className="text-muted">
                        <FontAwesomeIcon icon={faCalendar}/> Paid On: {subscription.start_on}
                    </small>&nbsp;
                    <small className="text-muted">
                        <FontAwesomeIcon icon={faCalendar}/> Expires On: {subscription.expires_at}
                    </small>
                </p>
            </div>
                
            </Col>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <div className="item">
                    <Row>
                    <Col sm={9} xs={9}>
                        <strong>{subscription.paid_amount}RWF</strong>
                        <p><small>Paid with {subscription.payment_method}</small></p>
                    </Col>
                    <Col sm={3} xs={3}>
                        <span className="icon">
                        <FontAwesomeIcon icon={faCcVisa}/>
                        </span>
                        
                    </Col>
                    </Row>
                </div>
            </Col>
        </Row>
        </div>
        
        <p className={showName?'border-top p-2 pt-3 pb-3':'d-none'}>
                    <small className="text-muted">
                        <FontAwesomeIcon icon={faCalendar}/> Paid On:  {subscription.start_on}
                    </small>&nbsp;
                    <small className="text-muted">
                        <FontAwesomeIcon icon={faCalendar}/> Expires On: {subscription.expires_at}
                    </small>
                </p>
    </div>
    )
  }
}

export default SubscriptionItem
