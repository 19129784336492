import axios from 'axios';
import React, { Component } from 'react'
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { Link, Redirect, withRouter } from 'react-router-dom'
import Header from '../../common/Header'
import { ToastContainer, toast } from 'react-toastify';
import { Auth } from '../../apis/Auth';
import { Constants } from '../../constants';

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class RegisterPage extends Component {
    constructor({match}) {
      super();

      const type = match.params.type;

      this.state = {
          account_type:false,
          processing:false,
          roles:{model:(type=='model'?type:''),actor:(type=='actor'?type:'')},
          first_name:'',
          last_name:'',
          email:'',
          password:'',
          account_type_valid:false,
          first_name_valid:false,
          last_name_valid:true,
          email_valid:false,
          password_valid:false,
          errors:{
              account_type:'',
              first_name:'',
              last_name:'',
              email:'',
              password:''
          },
          touchedFields:[]
      }
    }


    componentDidMount(){
      document.documentElement.scrollTop=0;
    }

    validateForm = (errors) => {
        let valid = true;
        
        if(!this.state.roles.actor&&!this.state.roles.model){
            errors['account_type'] = 'Please Choose account type first';
        }
        
        if(!this.state.first_name_valid){
            errors['first_name'] = 'First name is required';
        }
        if(!this.state.email_valid){
            errors['email'] = 'Email is required';
        }
        if(!this.state.password_valid){
            errors['password'] = 'Password is required';
        }

        this.setState({errors:errors});

        Object.values(errors).forEach(
          // if we have an error string set valid to false
          (val) => val.length > 0 && (valid = false)
        );
        
        return valid;
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        
        switch (name) {
          case 'account_type': 
            errors.account_type = 
              value.length <= 0
                ? 'Account type is required'
                : '';
            break;
          case 'first_name': 
            errors.first_name = 
              value.length < 2
                ? 'First Name must be 2 characters long!'
                : '';
            break;
          case 'email': 
            errors.email = 
              validEmailRegex.test(value)
                ? ''
                : 'Email is not valid!';
            break;
          case 'password': 
            errors.password = 
              value.length < 8
                ? 'Password must be 8 characters long!'
                : '';
            break;
          default:
            break;
        }

        let fieldValid = !errors[name]?true:false;
        let fieldNameValid = name+'_valid';

      
        this.setState({errors, [name]: value, [fieldNameValid]:fieldValid})
      }

      handleAccountTypeChange = (e)=>{
        let roles = this.state.roles;

        let errors = this.state.errors;

        errors['account_type'] = '';

        var currentRoleInput = document.getElementById(e.target.id);
        if(currentRoleInput.checked==true){
            roles[e.target.name] = e.target.value;
        }else{
            roles[e.target.name] = '';
        }

        this.setState({account_type:e.target.name,account_type_valid:true,roles,errors});
      }
    
    handleSubmit = (e)=>{

        e.preventDefault();

        if(this.validateForm(this.state.errors,["account_type","first_name","last_name","email","password"])) {
            const toastId = toast.loading('Registering account...',{position:'bottom-right'});

            this.setState({processing:true});
            const self = this;

            window.grecaptcha.ready(function() {
              window.grecaptcha.execute(Constants.public_key, {action: 'submit'}).then(function(token) {
                
                const validateRoles = Object.values(self.state.roles).filter(val=>{
                  return val!='';
                });
    
                axios.post('/register',{
                  first_name:self.state.first_name,
                  last_name: self.state.last_name,
                  email:self.state.email,
                  password:self.state.password,
                  roles:validateRoles,
                  recaptcha_token:token
                })
                .then(()=>{
                  self.setState({processing:false,userRegistered:true});
                  toast.update(toastId,{
                    isLoading:false,
                    autoClose:5000,
                    render: 'Account created successfully',
                    type:'success'              
                  });
                })
                .catch((err)=>{
                  self.setState({processing:false});
                  
                  if(err?.response?.status==422){
                      const errors = err.response.data;
                      console.log(err);
                      let inputErrors = self.state.errors;
    
                      for (const key in errors) {
                          inputErrors[key]=errors[key][0];
                      }
                      self.setState({errors:inputErrors});
                  }
                  if(err?.response?.status==404||err?.response?.status==500){
                    toast.update(toastId,{
                        isLoading: false,
                        autoClose: 5000,
                        type:'error',
                        render: err?.message??'Something went wrong'
                    });
                  }
                  else{
                    toast.update(toastId,{
                        isLoading: false,
                        autoClose: 5000,
                        type:'error',
                        render: err?.response?.data?.message??'Something went wrong'
                    });
                  }
                });
              })
            });
        }
    }

   

  render() {
      const account_type = this.state.account_type;

      if(this.state.userRegistered){
          return <Redirect to="/registered"/>
      }

      if(Auth.user){
        return <Redirect to="/account"/>
      }

      const {errors,processing,roles} = this.state;
    return (
    <div>
        <Header background={false} currentPage="register" />
        <Container className="content-on-bg">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div  className="register">
                <h1 className="title text-center card-title mb-4">Register</h1>
                <form onSubmit={this.handleSubmit}>

                    <div className="mb-4 mt-2">
                      <div className={account_type?"mb-3":'d-none'}>
                    <strong>I am joining as: <span className="text-danger">{roles.actor} <span className="text-dark">{roles.actor&&roles.model?" and ":""}</span> {roles.model}</span></strong>
                                
</div>
                        <Row>
                            <Col md={6} sm={6} xs={6}>
                                
                              <Form.Check
                                  className="large"
                                  inline="true"
                                  label="Actor / Actress"
                                  name="actor"
                                  type='checkbox'
                                  id='actor'
                                  value="actor"
                                  disabled={processing?true:false}
                                  checked={roles.actor?true:false}
                                  onChange={this.handleAccountTypeChange}
                              />
                          


                            </Col>
                            <Col md={5} sm={5} xs={5}>
                              <Form.Check
                                  className="large"
                                  inline="true"
                                  label="Model"
                                  name="model"
                                  type='checkbox'
                                  id='model'
                                  value="model"
                                  disabled={processing?true:false}
                                  checked={roles.model?true:false}
                                  onChange={this.handleAccountTypeChange}
                              />
                            </Col>
                        </Row>
                        {errors.account_type.length > 0 && 
  <div className='text-danger'>{errors.account_type}</div>}                    
                    </div>
                    <Row>
                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="firstname">First Name</Form.Label>
                            <Form.Control
                                type="text"
                                id="firstname"
                                name="first_name"
                                disabled={processing?true:false}
                                value={this.state.first_name} 
                                onChange={this.handleChange}
                            />
                            {errors.first_name.length > 0 && 
  <span className='text-danger'>{errors.first_name}</span>}
                        </Col>
                        <Col xl={6} lg={6} md={12}>
                            <Form.Label htmlFor="lastname">Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                id="lastname"
                                name="last_name"
                                disabled={processing?true:false}
                                value={this.state.last_name} 
                                onChange={this.handleChange}
                            />
                            {errors.last_name.length > 0 && 
  <span className='text-danger'>{errors.last_name}</span>}
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                                type="text"
                                id="email"
                                name="email"
                                disabled={processing?true:false}
                                value={this.state.email} 
                                onChange={this.handleChange}
                            />
                            {errors.email.length > 0 && 
  <span className='text-danger'>{errors.email}</span>}
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="password">Password</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                name="password"
                                disabled={processing?true:false}
                                value={this.state.password} 
                                onChange={this.handleChange}
                                
                            />
                            {errors.password.length > 0 && 
  <span className='text-danger'>{errors.password}</span>}
                        </Col>
                    </Row><br />
                    <p className="d-grid gap-2">
                        <Button type="submit" data-action='user-registration' variant="primary" disabled={processing?true:false} size="lg">Register</Button>
                    </p>
                </form>
                <p className="p-2 text-center">Have an account? <Link to="/signin" className="text-primary">Login</Link></p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-bg"></div>
        <div className="overlay"></div>
        <ToastContainer/>
      </div>
    )
  }
}

export default withRouter(RegisterPage)
