import axios from "axios";
class Api{
    getRecentUsers=(accountType)=>{
        return axios.get('/recent-users/'+accountType);
    }
    getUsersByType = (type,page)=>{console.log('/get-users-by-type/'+type+'?page='+page);
        if(!type){
            return axios.get('/get-users-by-type?page='+page);
        }
        return axios.get('/get-users-by-type/'+type+'?page='+page);
    }
    searchUserByTypeAndName = (type,search,page)=>{
        return axios.get('/search-users-by-type/'+type+'/'+search+'?page='+page);
    }
    bookTalents = (data)=>{
        return axios.post('/book-talents',data);
    }

    getTalentById= (id)=>{
        return axios.get('/talent/'+id);
    }
    getRelatedTalents = (roles,limit="")=>{
        if(limit){
            return axios.get('/get-related-talents/'+limit+'?roles='+roles);
        }
        return axios.get('/get-related-talents?roles='+roles);
    }

    getSettings = ()=>{
        return axios.get('settings');
    }
    getTeamMembers = ()=>{
        return axios.get('team-members');
    }
    getTeamMember = (id)=>{
        return axios.get('team-member/'+id);
    }
    sendMessage = (id,data)=>{
        return axios.post(`/staff/message/${id}`,data);
    }
}
const HomeApi = new Api();
export {HomeApi};