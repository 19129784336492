import { faCheck, faClose,faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Button, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Constants } from '../../../constants'
import avatar from '../../../assets/images/avatar.png';


class MemberItem extends Component {
    

  render() {
    const { member } = this.props;

    return (
        <Col xs={6} sm={6} md={6} lg={4}>
            <div className="user-photo-wrapper">
                <div className="user-photos">
                    <Link to={'/staff/'+member.id}>
                        <img className="w-100" src={member.photo?Constants.publicUrl+'/images/'+member.photo:avatar} alt={member.name} />
                    </Link>
                    <Button type="button" className="upload-button" variant={member.status==1?"success":(member.status==2?"danger":"light")} title={member.status==1?'Member is Active':(member.status==2?'Member unpublished':'Member is not active')} size="sm">
                        <FontAwesomeIcon icon={member.status==1?faCheck:(member.status==2?faClose:faEllipsis)}/>
                    </Button>
                </div>
                <div className="details">
                    <Link to={'/staff/'+member.id}>
                        <h2 className="title">{member.name}</h2>
                    </Link>
                    <p>{member.position}</p>
                </div>
                <div className="border-bottom-1"></div>
                <div className="border-bottom-2"></div>
            </div>
        </Col>
    )
  }
}

export default MemberItem
