import SEO from '@americanexpress/react-seo/build/components/SEO'
import React, { Component } from 'react'
import Header from '../../common/Header'
import { Constants } from '../../constants'
import '../../assets/css/models.css';
import '../../assets/css/talents.css';
import '../../assets/css/pub-sidebar.css';
import { Button, Col, Container, FormControl, Row, Spinner } from 'react-bootstrap';
import { HomeApi } from '../../apis/HomeApi';
import SingleModelActor from '../../components/public/SingleModelActor';
import HelpButton from '../../components/user/HelpButton';
import Booking from '../../components/public/Booking';
import Footer from '../../common/Footer';

class TalentsPage extends Component {
  state={
    AccountTypeFilter:'',
    talents:[],
    currentPage:0,
    loadingMore:false,
    loading:true,
    totalTalents:0,
    searchInput:'',
    bookingItems:[]
  }
  componentDidMount(){
      window.addEventListener('scroll', this.loadMore);
      this.loadDataOnline();
  }
  loadDataOnline = (type='')=>{
    let page = 1;
    if(this.state.loadingMore){
      page = this.state.currentPage+1;
    }
    
    HomeApi.getUsersByType(type,page)
      .then(res=>{
        let talents = this.state.talents;

        if(this.state.loadingMore){
          talents =  talents.concat(res.data.data);
        }
        else{
          talents = res.data.data;
        }

        this.setState({loading:false,talents:talents,currentPage:res.data.current_page,totalTalents:res.data.total,loadingMore:false});
      })
  }
  handleSearchInputChange = (e)=>{
    const input = e.target.value;
    this.searchModel(input);
  }
  searchModel = (search)=>{
      this.setState({currentPage:0,searchInput:search});
      HomeApi.searchUserByTypeAndName(this.state.AccountTypeFilter,search,0)
      .then(res=>{
        this.setState({talents:res.data.data,currentPage:res.data.current_page,totalTalents:res.data.total,loadingMore:false});
      });
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.loadMore);
  }
  loadMore = ()=>{
      if(this.state.searchInput){
        if (!this.state.loadingMore&&window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
          
          if(this.state.talents.length&&this.state.totalTalents > this.state.talents.length){
            this.setState({loadingMore:true});

            const page=this.state.currentPage;

            HomeApi.searchUserByTypeAndName(this.state.AccountTypeFilter,this.state.searchInput,(page+1))
            .then(res=>{ 
              let talents = this.state.talents;
              talents = talents.concat(res.data.data);

              this.setState({talents:talents,currentPage:res.data.current_page,totalTalents:res.data.total,loadingMore:false});
            })
          }
        }
      }
      else{
        if (!this.state.loadingMore&&window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
          if(this.state.talents.length&&this.state.totalTalents > this.state.talents.length){
            this.setState({loadingMore:true});
            this.loadDataOnline(this.state.AccountTypeFilter);
          }
      }
      }
  }
  loadUserByType = (e)=>{
    this.setState({AccountTypeFilter:e.target.value,currentPage:0,searchInput:'',talents:[],loading:true});
    this.loadDataOnline(e.target.value);
  }

  handleAddToBooking = (e)=>{
    let items = this.state.bookingItems;

    const id = e.target.value;
    const selectedItem = this.state.talents.filter(val=>{
        return val.id == id;
    });

    items = items.concat(selectedItem);
    this.setState({bookingItems:items});
  }
  handleRemoveItem = (id)=>{
    let items = this.state.bookingItems;

    const selectedItem = items.filter(val=>{
        return val.id != id;
    });

    this.setState({bookingItems:selectedItem});
  }
  handleBookingSuccess = ()=>{
    this.setState({bookingItems:[]});
  }


  render() {
    const {talents, AccountTypeFilter} = this.state;
    let modelItems = "";
    if(talents){
      modelItems = talents.map((val,index)=>{
        return <SingleModelActor showBookingBtn={true} selectedItems={this.state.bookingItems} addToBooking={this.handleAddToBooking} lg={4} xl={4} user={val} key={index}/>
      })
    }
    return (
      <div className="modelsPage">
        <SEO
          title="Find Rwandan Talents - Afrifame"
          description="We connect you with Rwandan talented talents of your choice."
          keywords={['Rwandese talents', 'Rwandese actors']}
          siteUrl={Constants.publicUrl}
          image={{
            src: 'http://example.com/foo.jpg'
          }}
        />

        <Header background={false} />
        <Container>
            <h2 className="page-title mt-5">Find talents</h2>
            <div className=" mt-5">
              <Row>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} className="talents-filter-buttons">
                    <Button variant={AccountTypeFilter==''?"active":'light'} value="" onClick={this.loadUserByType}>All</Button>
                    <Button variant={AccountTypeFilter=='model'?"active":'light'} value="model"  onClick={this.loadUserByType}>Models</Button>
                    <Button variant={AccountTypeFilter=='actor'?"active":'light'} value="actor"  onClick={this.loadUserByType}>Actors</Button>
                    <div className="pb-5"></div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="float-right search-input">
                      <FormControl
                        type="text"
                        id="search_input"
                        name="search"
                        className="search_input"
                        placeholder="Search model by name"
                        onChange = {this.handleSearchInputChange}
                        />
                    </div>
                    <div className="clearfix"></div>
                    <div className="pb-5"></div>
                </Col>
              </Row>
            </div>
            <div className="talents-list">
              <div className="models models-wrapper">
                <Row>
                  <Col xl={9} lg={9} md={7} sm={12} xs={12}>
                    <Row>
                      {modelItems}
                      <Col xs={12}>
                          <div className={this.state.loadingMore||this.state.loading?"d-block text-center pb-5":"d-none"}>
                            <Spinner animation="grow" variant="light"/>
                          </div>
                      </Col>
                      <Col xs={12}>
                          <div className={!this.state.loading&&!talents.length?"d-block":"d-none"}>
                            No talents found
                          </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={3} lg={3} md={5} sm={12} xs={12}>
                    <div className="pub-sidebar">
                      <Booking selectedItems={this.state.bookingItems} handleBookingSuccess={this.handleBookingSuccess} handleRemoveItem={this.handleRemoveItem}/>
                      <HelpButton />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            
        </Container>
        <Footer/>
      </div>
    )
  }
}

export default TalentsPage
