import React, { Component } from 'react'
import { Row, Spinner } from 'react-bootstrap'
import SingleModelActor from './SingleModelActor'

class RecentModels extends Component {
    
  render() {
    const { models } = this.props;
    let modelsHtml = '';
    if(models){
        console.log('models',models)
        modelsHtml = models.map((val)=>{
            return <SingleModelActor key={val.id} user={val}/>
        });
    }

    if(modelsHtml){
        return (
            <div className="models models-wrapper pt-5">
                <h2 className="mb-5 heading model">Models</h2>
                <Row>
                    {modelsHtml}
                </Row>
            </div>
        )
    }
    else{
        return (
            <div className="actors actors-wrapper pt-5">
                <h2 className="mb-5 heading actor">Models</h2>
                
                <div className={this.props.loading?"d-block text-center pb-5":"d-none"}>
                    <Spinner animation="grow" variant="light"/>
                </div>

                <p className={!this.props.loading?"d-none":"text-center"}>No Model added yet</p>
            </div>
        )
    }
  }
}

export default RecentModels
