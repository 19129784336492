import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Redirect } from 'react-router-dom';
import { Auth } from '../../../apis/Auth';

export class PaySubscription extends Component {
    state={
        roles:{
            actor:Auth.checkRole(this.props.user.roles,'Actor')?'actor':'',
            model:Auth.checkRole(this.props.user.roles,'Model')?'model':'',
        },
        subscription_date:'',
        errors:{
            subscription_date:''
        },
        paymentToken : ''
    }

    handleAccountChange=(e)=>{
        let roles=this.state;
        const Input = document.getElementById(e.target.id);
        if(Input.checked==true){
            roles[e.target.name]=e.target.value
        }
        else{
            roles[e.target.name]='';
        }
        this.setState({roles:roles})
    }
    handleChange = (e)=>{
        const InputName = e.target.name;
        this.setState({[InputName]:e.target.value});
    }

    handleSubmit = (e)=>{
        e.preventDefault();

        console.log(this.state);

        this.setState({paymentToken:'dkdkdkdayeafaolfdaskfdkaskdfaskfdaskdfas'});
    }
  render() {
      if(this.state.paymentToken){
          return <Redirect to={"/pay-subscription/"+this.state.paymentToken}/>
      }

      const { roles, errors, subscription_date } = this.state;
      const formValid = (roles.actor||roles.model)&&subscription_date;

    return (
      <div>
        <Modal className="modalWrapper" size="sm" show={this.props.show} fullscreen="md-down" onHide={this.props.handleHideModal}>
            <Modal.Header closeButton>
            <Modal.Title>Pay Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Check
                                className="large"
                                inline="true"
                                label="Actor / Actress"
                                name="actor"
                                type='checkbox'
                                id='actor'
                                value="actor"
                                checked={this.state.roles['actor']=='actor'}
                                onChange={this.handleAccountChange}
                            />

                        
                            <Form.Check
                                className="large"
                                inline="true"
                                label="Model"
                                name="model"
                                type='checkbox'
                                id='model'
                                value="model"
                                checked={this.state.roles['model']=='model'}
                                onChange={this.handleAccountChange}
                            />
                        </Col>
                    </Row>
                    <div className={roles.actor||roles.model?'':'d-none'}>
                        <div className="subscription-fees mb-3">
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="item">
                                        <Row>
                                        <Col sm={9} xs={9}>
                                            <p><small>Your subscription fees </small></p>
                                            <strong>150000RWF</strong>
                                        </Col>
                                        <Col sm={3} xs={3}>
                                            <span className="icon">
                                            <FontAwesomeIcon icon={faMoneyBill}/>
                                            </span>
                                            
                                        </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Form.Label htmlFor="birthday">Subscription Start from<sup className="text-danger">*</sup></Form.Label>
                                <Form.Control
                                    name="subscription_date"
                                    type='date'
                                    id='subscription_date'
                                    defaultValue={this.state.subscription_date}
                                    onChange={this.handleChange}
                                />
                                {errors.subscription_date.length > 0 && 
    <span className='text-danger'>{errors.subscription_date}</span>}
                                <br/>
                            </Col>
                        </Row>
                        <div className="text-right mt-3">
                            <Button type="submit" variant="primary" className="float-right" disabled={!formValid}>Proceed to payment</Button>
                            <Button type="button" variant="light" className="float-right" onClick={this.back}>Back</Button>&nbsp;
                            
                        </div>          
                    </div>
                    
                </form>
            </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default PaySubscription
