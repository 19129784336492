import React, { Component } from 'react'
import { Button, Col, Container, Form, ProgressBar, Row } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import Header from '../../common/Header'
import { ToastContainer, toast } from 'react-toastify';
import { Auth } from '../../apis/Auth';
import axios from 'axios';
import { Constants } from '../../constants';



const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class LoginPage extends Component {
    constructor(props) {
        super(props);
    }


    state = {
        email:'',
        password:'',
        email_valid:false,
        password_valid:false,
        errors:{
            email:'',
            password:''
        },
        processing:false,
        loggedIn:false
    }

    componentDidMount(){
      document.documentElement.scrollTop=0;
    }

    validateForm = (errors) => {
        let valid = true;
    
        if(!this.state.email_valid){
            errors['email'] = 'Email is required';
        }
        if(!this.state.password_valid){
            errors['password'] = 'Password is required';
        }

        this.setState({errors:errors});

        Object.values(errors).forEach(
          // if we have an error string set valid to false
          (val) => val.length > 0 && (valid = false)
        );
        
        return valid;
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        
        switch (name) {
          case 'email': 
            errors.email = 
              validEmailRegex.test(value)
                ? ''
                : 'Email is not valid!';
            break;
          case 'password': 
            errors.password = 
              value.length == ''
                ? 'Password is required'
                : '';
            break;
          default:
            break;
        }

        let fieldValid = !errors[name]?true:false;
        let fieldNameValid = name+'_valid';
      
        this.setState({errors, [name]: value, [fieldNameValid]:fieldValid})
      }
    
    handleSubmit = (e)=>{
        e.preventDefault();
        if(this.validateForm(this.state.errors)) {
            const toastId = toast.loading('Login you in....',{position:'bottom-right'});

            const self = this;

            window.grecaptcha.ready(function() {
              window.grecaptcha.execute(Constants.public_key, {action: 'submit'}).then(function(token) {
                Auth.login({
                  email:self.state.email,
                  password:self.state.password,
                  recaptcha_token:token
                })
                .then((response)=>{
                  toast.update(toastId,{
                    isLoading:false,
                    autoClose:5000,
                    type:'success',
                    render:'Logged in Successfully'
                  });

                  localStorage.setItem('token',response.data.token);
                  localStorage.setItem('user',JSON.stringify(response.data.user));
                  localStorage.setItem('roles',JSON.stringify(response.data.roles));

                  Auth.roles = response.data.roles;
                  Auth.user = response.data.user;

                  /**Set token header for axios */
                  axios.defaults.headers.common['Authorization'] = 'Bearer '+response.data.token;

                  self.setState({loggedIn:true});
                })
                .catch(err=>{
                  if(err?.response?.status==422){
                      const errors = err.response.data.errors;
                      let inputErrors = self.state.errors;

                      for (const key in errors) {
                          inputErrors[key]=errors[key][0];
                      }
                      self.setState({errors:inputErrors});
                  }
                  toast.update(toastId,{
                      isLoading: false,
                      autoClose: 5000,
                      type:'error',
                      render: err?.response?.data?.message??'Something went wrong'
                  });
                })
              });
            });
          
        }else{
            console.error('Invalid Form')
        }
    }

  render() {
      if(this.state.loggedIn){
          return <Redirect to="/welcome"/>
      }

      if(Auth.user){
        /**you are logged in */
        return <Redirect to="/account"/>
      }

      const {errors,processing} = this.state;
    return (
    <div>
        
        <Header background={false} />
        <Container className="content-on-bg">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div  className="register">
                <h1 className="title text-center mb-4">Login</h1>
                <ProgressBar animated now={100} className={processing?"mb-3":'d-none'} />
                <form onSubmit={this.handleSubmit}>
                    <Row>
                        
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                                type="text"
                                id="email"
                                name="email"
                                value={this.state.email} 
                                disabled={processing?true:false}
                                onChange={this.handleChange}
                            />
                            {errors.email.length > 0 && 
  <span className='text-danger'>{errors.email}</span>}
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="password">Password</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                name="password"
                                disabled={processing?true:false}
                                value={this.state.password} 
                                onChange={this.handleChange}
                                
                            />
                            {errors.password.length > 0 && 
  <span className='text-danger'>{errors.password}</span>}
                        </Col>
                    </Row><br />
                    <p className="d-grid gap-2">
                        <Button type="submit" variant="primary" disabled={processing?true:false} size="lg">Sign in</Button>
                    </p>
                </form>
                <p className="p-2 text-center">Forgot Password? <Link to="/forgot-password" className="text-primary">Reset</Link> | <Link to="/register" className="text-primary">Register</Link></p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-bg"></div>
        <div className="overlay"></div>
        <ToastContainer />
      </div>
    )
  }
}

export default LoginPage
