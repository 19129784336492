import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Header from '../../common/Header'
import {faPlus } from '@fortawesome/free-solid-svg-icons'
import UserSidebarNav from '../../components/user/UserSidebarNav'
import HelpButton from '../../components/user/HelpButton'
import AddMember from '../../components/admin/members/AddMember'
import {ToastContainer, toast} from 'react-toastify'
import UsersPagePlaceholder from './placeholders/UsersPagePlaceholder'
import TeamApi from '../../apis/TeamApi'
import MemberItem from '../../components/admin/members/MemberItem'

class TeamPage extends Component {
    state = {
        members:'',
        next_page_url:'',
        showAddMemberForm:false
    }

    componentDidMount(){
      this.loadAllMembers();
      document.documentElement.scrollTop=0;
    }

    handleMemberCreated = ()=>{
      this.loadAllMembers();
    }

    loadAllMembers = ()=>{
        TeamApi.loadMembers()
        .then(response=>{console.log(response);
          this.setState({members:response.data.data,next_page_url:response.data.next_page_url});
          document.documentElement.scrollTop=0;
        });
    }

    searchMembers = (e)=>{
        TeamApi.searchMembers(e.target.value)
        .then(response=>{
          this.setState({members:response.data.data,next_page_url:response.data.next_page_url});
          document.documentElement.scrollTop=0;
        })
    }

    showAddMemberFormHandler = ()=>{
      this.setState({showAddMemberForm:true});
    }
    handleAddUserHide = ()=>{
      this.setState({showAddMemberForm:false});
    }

    handleAddMemberHide = ()=>{
        this.setState({showAddMemberForm:false});
    }

  render() {
    const { members, next_page_url } = this.state;

    if(!members){
      return <UsersPagePlaceholder />
    }
    else{
      let memberItems = '';
      if(members){
        memberItems = members.map((val)=>{
          return <MemberItem member={val} key={val.id}/>
        });
      }
      return (
      <div>
          <Header background={false} />
          <Container className="content-on-bg mt-3">
            <Row>
              <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
                <UserSidebarNav />
                <HelpButton />
              </Col>
              <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                <div className="welcome mt-0 p-4">
                  <div className="Users mb-3">
                    <h2 className="section-title border-bottom mb-5 pb-4 mt-2">
                      Staff Members
                    </h2>
                      <div className="buttons mb-4">
                          <Row>
                              <Col xl={10} lg={10} md={9} sm={8} className="mb-3">
                                  <input type="text" className="form-control" onChange={this.searchMembers} placeholder="Search staff by name, email, or position"/>
                              </Col>
                              <Col xl={2} lg={2} md={3} sm={4} className="text-center mb-3">
                                  <Button variant="outline-danger" onClick={this.showAddMemberFormHandler}><FontAwesomeIcon icon={faPlus}/> New</Button>
                              </Col>
                          </Row>
                      </div>
                    <Row>
                          {/*User items */}
                          {memberItems}
                      </Row>

                  </div>
                  <AddMember onNewMember={this.handleMemberCreated} show={this.state.showAddMemberForm} onHideModal={this.handleAddMemberHide} />
                </div>
              </Col>
            </Row>
          </Container>
          <div className="home-bg"></div>
          <div className="overlay"></div>
          <ToastContainer/>
        </div>
      )
    }
    
  }
}

export default TeamPage
