import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link} from 'react-router-dom'
import Header from '../../common/Header'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'
import { UserApi } from '../../apis/UserApi'
import { ToastContainer, toast } from 'react-toastify'

class VerifyPage extends Component {
   constructor({match}){
        super();
        this.state = {
            code: match.params.code,
            verifying:true,
            userData:{},
            error:''
        }
   }
    componentDidMount() {
        const toastId= toast.loading('Verifying...',{position:'bottom-right'});

        UserApi.verifyEmail(this.state.code)
          .then(()=>{
            this.setState({verifying:false});
            toast.update(toastId,{
                isLoading: false,
                autoClose: 5000,
                type:'success',
                render: 'Email verified successfully'
            });
          })
          .catch(err=>{
            if(err?.response?.status==422){
                const errors = err.response.data.errors;
                let errorString = '';
                for (const key in errors) {
                    errorString+=errors[key][0];
                }
                toast.update(toastId,{
                    isLoading: false,
                    autoClose: 5000,
                    type:'error',
                    render: errorString
                });
                this.setState({verifying:false,error:errorString})
            }
            if(err?.response?.status==404||err?.response?.status==401||err?.response?.status==500){
              toast.update(toastId,{
                  isLoading: false,
                  autoClose: 5000,
                  type:'error',
                  render: err?.response?.data?.message??(err?.message??'Something went wrong')
              });
              this.setState({verifying:false,error:err?.response?.data?.message??(err?.message??'Something went wrong')})
            }
            else{
              toast.update(toastId,{
                  isLoading: false,
                  autoClose: 5000,
                  type:'error',
                  render: err?.response?.data?.message??'Something went wrong'
              });
              this.setState({verifying:false,error:err?.response?.data?.message??'Something went wrong'})
            }
          });
    }
    

  render() {
      
    return (
    <div>
        <Header background={false} />
        <Container className="content-on-bg">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div  className="register text-center">
                <h1 className="title text-center mb-4">Account Verification</h1>
                <div className={!this.state.verifying&&!this.state.error?'d-block':'d-none'}>
                    <div className="circle envelope">
                        <FontAwesomeIcon icon={faCheck}/>
                    </div>
                    <p>Your account has been verified. Sign in to get started</p>
                    <p className="d-grid gap-2">
                        <Link to="/signin" className="btn btn-primary btn-block">Sign in</Link>
                    </p>
                </div>
                <div className={this.state.verifying?'d-block':'d-none'}>
                    Verifying....
                </div>
                <div className={this.state.error?'d-block text-danger':'d-none'}>
                    {this.state.error}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-bg"></div>
        <div className="overlay"></div>
        <ToastContainer/>
      </div>
    )
  }
}

export default withRouter(VerifyPage)
