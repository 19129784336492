import React, { Component, Fragment } from 'react'
import { Container, Dropdown, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown} from '@fortawesome/free-solid-svg-icons'
import GetStared from '../components/public/GetStared'
import { Link } from 'react-router-dom'
import { Auth } from '../apis/Auth'
import avatar from '../assets/images/avatar.png';
import Logo from "../assets/images/logo-afrifame.svg"
import { Constants } from '../constants'
import { Redirect } from 'react-router-dom'
import UserSidebarNavMobile from '../components/user/UserSidebarNavMobile'

class Header extends Component {
    constructor(){
      super();
      this.state = {
        showSideBarMenu:false,
        showGetStarted:false,
        loggedOut:false
      }
    }

    componentDidMount(){
      const navbar = document.getElementById('navbar');
      
      document.addEventListener('scroll',function(e){
        if(navbar&&document.documentElement.scrollTop >= 20){
          navbar.classList.add("navbarBlack");
        }
        else if(navbar){
          navbar.classList.remove("navbarBlack");
        }
      })
    }
    
  
    handleClose = () => this.setState({showSideBarMenu:false,showGetStarted:false});
    handleShow = () => this.setState({showSideBarMenu:true,showGetStarted:false});

    handleShowGetState=()=>{
        //localStorage.removeItem('accountType');
        this.setState({showGetStarted: true, showSideBarMenu:false});
    }

    logout = ()=>{
      if(Auth.logout()){
      this.setState({showSideBarMenu:false});
      this.setState({loggedOut:true,showSideBarMenu:false});
      }
    }

    closeNavModal = ()=>{
      this.setState({showSideBarMenu:false});
    }

  render() {
    const { currentPage } = this.props;

    if(this.state.loggedOut){
      return <Redirect to="/signin"/>
    }


    let userAccountMenu = '';

    if(Auth.user){
      userAccountMenu = <Nav>       
            <Link className="nav-link" to="/talents">Talents</Link>
            <Link className="nav-link" to="/faq">FAQs</Link>
            <Link className="nav-link" to="/about">About Us</Link>
            <Link className="nav-link" to="/contact">Contact Us</Link>  
            <Dropdown align="end" className="headerDropdown">
                <Dropdown.Toggle className="nav-link btn btn-sm btn-default btn-account" size="sm" id="dropdown-basic1">
                    <div className="avatar circle">
                    <img src={Auth.user.photo1?Constants.publicUrl+'/images/'+Auth.user.photo1: avatar}/>
                  </div>
                  Account 
                </Dropdown.Toggle>


                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/account">Profile</Dropdown.Item>
                  <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            
        </Nav>
    }
    else{
      userAccountMenu = <Nav>       
            <Link className="nav-link" to="/talents">Talents</Link>
            <Link className="nav-link" to="/faq">FAQs</Link>
            <Link className="nav-link" to="/about">About Us</Link>
            <Link className="nav-link" to="/contact">Contact Us</Link>
            <Link to="/signin" className="nav-link btn btn-sm mr-2 btn-primary" size="sm">Sign in</Link>   
            <Link to="/register" className="nav-link btn btn-sm btn-default bg-white" size="sm">Get started <FontAwesomeIcon icon={faCaretDown}/></Link>        
        </Nav>
                   
    }

    

    return (
        <Fragment>
            <Navbar id="navbar" className={!this.props.background?"content-on-bg transparent navbar fixed-top":"content-on-bg navbar fixed-top"} collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                <Navbar.Brand className="brand">
                  <Link to="/">
                  {/* Afrifame<span>Models</span> */}
                  <img src={Logo} alt="Afrifame Models app" className="img-responsive"/>
                  </Link>
                  
                </Navbar.Brand>
                
                <button className="navbar-toggler" onClick={this.handleShow} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
                
                <Navbar.Collapse className="mainNavigation" id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    {userAccountMenu}
                </Navbar.Collapse>
                </Container>
            </Navbar>


      <Offcanvas className="navModal" show={this.state.showSideBarMenu} onHide={this.handleClose}>
          <Offcanvas.Header closeButton className="color-light">
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <div className={Auth.user?"sidbarAvatar":"d-none"}>
            <div className="img">
            <img src={avatar}/>
            </div>
            </div>
           
      
            <UserSidebarNavMobile logout={this.logout} onClickHandler={this.closeNavModal}/>

          </Offcanvas.Body>
        </Offcanvas>
        <div id="belowNavbar" className="belowNavbar"></div>

        <GetStared onCloseHandler={this.handleClose} show={this.state.showGetStarted&&currentPage!='register'} />
        </Fragment>
    )
  }
}



export default Header
