import { faFacebookSquare, faTwitterSquare, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import "../assets/css/share-buttons.css";

import { RWebShare } from "react-web-share";


const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class ShareButtons extends Component {
  constructor(props){
    super(props);
  }
  static defaultProps = {
      url:'',
      text:''
  }
  state = {
    sharer:''
  }

  handleShareFacebook = ()=>{
    const link = `https://www.facebook.com/sharer/sharer.php?u=${this.props.url}`;
    this.openWindow(link);
  }
  handleShareTwitter = ()=>{
    const link = `https://twitter.com/intent/tweet?url=${this.props.url}`;
    this.openWindow(link);
  }
  handleShareWhatsapp = ()=>{
    let link="";
    if(this.isMobile()){
      link = `https://api.whatsapp.com/send?text=${this.props.url}`;
    }
    else{
      link = `https://api.whatsapp.com/send?text=${this.props.url}`;
    }
    this.openWindow(link);
  }
  handleShareEmail = (email)=>{
    this.setState({sharer:'email'});
  }
  onCancel = ()=>{
    this.setState({sharer:''});
  }

  onReceiveInput = (email)=>{
    console.log(email);
    if( validEmailRegex.test(email)){
      const link = `mailto:${email}?&subject=&cc=&bcc=&body=${this.props.url}`;
      this.openWindow(link);
    }
  }

  isMobile = ()=>{
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }


  openWindow = (url)=>{
    const w = 550;
    const h = 400;
    console.log(window.screen);
    const left = (window.screen.width/2)-(w/2);
    const top = (window.screen.height/2)-(h/2);
    window.open(url,'',`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, width=${w},height=${h},top=${top},left=${left}`);
  }

  render() {
    return (
      <div>
        <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.props.show} onHide={this.props.handleClose}
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Share
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="text-center share-buttons">
                  <Button className="facebook" onClick={this.handleShareFacebook}>
                    <FontAwesomeIcon icon={faFacebookSquare}/>
                  </Button>
                  <Button className="twitter" onClick={this.handleShareTwitter}>
                    <FontAwesomeIcon icon={faTwitterSquare}/>
                  </Button>
                  <Button className="whatsapp" onClick={this.handleShareWhatsapp}>
                    <FontAwesomeIcon icon={faWhatsappSquare}/>
                  </Button>
                  <Button className="email" onClick={this.handleShareEmail}>
                    <FontAwesomeIcon icon={faEnvelopeSquare}/>
                  </Button>
                </div> */}

<RWebShare
        data={{
          text: "Like humans, flamingos make friends for life",
          url: "https://on.natgeo.com/2zHaNup",
          title: "Flamingos",
        }}
        onClick={() => console.log("shared successfully!")}
      >
        <button>Share 🔗</button>
      </RWebShare>
            </Modal.Body>
        </Modal>
        
      </div>
    )
  }
}

export default ShareButtons
