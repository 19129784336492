import React, { Component } from 'react'
import PlaceholderLoading from 'react-placeholder-loading'
export class HeadingPlaceholder extends Component {
    constructor(props){
        super(props);
        
    }
    static defaultProps ={
        block:false
    }

  render() {
      const { block } = this.props;
    return (
      <div className={block?"heading block":"heading"}> 
          <PlaceholderLoading width={100} height={30} shape="rect"/>
      </div>
    )
  }
}

export default HeadingPlaceholder
