import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../../common/Header'
import HelpButton from '../../components/user/HelpButton'
import UserDetailsHeader from '../../components/admin/userDetails/UserDetailsHeader'
import UserDetailsProfile from '../../components/admin/userDetails/UserDetailsProfile'
import UserDetailsSubscription from '../../components/admin/userDetails/UserDetailsSubscription'

import { ToastContainer, toast } from 'react-toastify';

import { UserApi }  from '../../apis/UserApi'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import UserSidebarNav from '../../components/user/UserSidebarNav'
import ChangeUserRole from '../../components/admin/users/ChangeUserRole'
import EditUser from '../../components/admin/users/EditUser'
import UserPhotosAdmin from '../../components/admin/users/UserPhotosAdmin'
import UserDetailsPlaceholder from './placeholders/UserDetailsPlaceholder'
import PaySubscription from '../../components/admin/subscriptions/Pay'

class UserDetailsPage extends Component {
  constructor({match}){
    super();
    this.state = {
      id: match.params.id,
      userActive:false,
      subscriptions:'',
      user:'',
      showChangeUserRole:false,
      showEditUser:false,
      showPaySubscription:false
    }
  }

    componentDidMount(){
      document.documentElement.scrollTop=0;
      UserApi.getUserById(this.state.id).then((response)=>{
        this.setState({user:response.data.user});
        document.documentElement.scrollTop=0;
      })
      .catch((error)=>{
        console.log(error);
        toast.error(error.message,{
          position:'top-right'
        })
      });
      UserApi.checkUserActiveSubscription(this.state.id)
      .then((status)=>{
        this.setState({userActive:status});
      })
      .catch((error)=>{

      })
      UserApi.getUserSubscriptions(this.state.id)
      .then((response)=>{
        this.setState({subscriptions:{next_page_url:response.data.next_page_url,data:response.data.data}});
      })
    }
    showModal = ()=>{console.log('showing modal...');
      this.setState({showChangeUserRole:true})
    }

    showEditUserModal = ()=>{console.log('showing modal...');
      this.setState({showEditUser:true})
    }

    handleHideModal= ()=>{
      this.setState({showChangeUserRole:false})
    }
    handleEditUserHideModal = ()=>{
      this.setState({showEditUser:false})
    }

    handlePaySubscriptionHideModal = ()=>{
      this.setState({showPaySubscription:false})
    }
    showPaySubscriptionModal = ()=>{
      this.setState({showPaySubscription:true})
    }

    handleUserRoleChanged = ()=>{
      this.setState({showChangeUserRole:false})
      /*Refresh user data**/
      UserApi.getUserById(this.state.id).then((response)=>{
        this.setState({user:response.data.user});
      })
      .catch((error)=>{
        console.log(error);
        toast.error(error.message,{
          position:'top-right'
        })
      });
    }

    handleUserUpdated = ()=>{
      this.setState({showEditUser:false})
      /*Refresh user data**/
      UserApi.getUserById(this.state.id).then((response)=>{
        this.setState({user:response.data.user});
      })
      .catch((error)=>{
        console.log(error);
        toast.error(error.message,{
          position:'top-right'
        })
      });
    }

    loadMoreSubscriptions = (nextUrl)=>{
      const url = nextUrl.replace(axios.defaults.baseURL,'');
      if(url){
        toast.promise(
          UserApi.loadMoreUserSubscriptions(url)
          .then((response)=>{
            const {next_page_url,data} = response.data;
            let subscriptions = this.state.subscriptions.data;
            
            if(data){
              subscriptions = subscriptions.concat(data);
            }
            this.setState({subscriptions:{next_page_url:next_page_url,data:subscriptions}});
          }),
          {
          pending:'Loading...',
          success: 'Data fetched successfully',
          error: (err) =>
            err?.response?.data?.msg ?? 'Something is wrong, please try again',
          position:'top-center'
          }
          
        );
      }
    }

    handleSendResetLink = ()=>{
      const toastId = toast.loading('Sending email...',{position:'bottom-right'});
      console.log('sending email...')
      UserApi.sendResetLink(this.state.id,{ })
      .then(()=>{
        toast.update(toastId,{
          isLoading: false,
          autoClose: 5000,
          type:'success',
          render: 'Reset Email sent successfully'
        })
      })
      .catch(err=>{console.log(err.message)
        toast.update(toastId,{
            isLoading: false,
            autoClose: 5000,
            type:'error',
            render: err?.response?.data?.message??(err.message??'Something went wrong')
        });
      })
    }

  render() {
    const { user,status,subscriptions } = this.state;
    if(!user){
      return <UserDetailsPlaceholder/>
    }
    else{
      return (
        <div>
            <Header background={false} />
            <Container className="content-on-bg mt-3">
              <Row>
                <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
                  <UserSidebarNav />
                  <HelpButton />
                </Col>
                <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                    <div className="welcome mt-0 pt-4">
                        <UserDetailsHeader user={user} status={status} sendResetLink={this.handleSendResetLink} showEditUserModal={this.showEditUserModal} showChangeUserModal={this.showModal}/>
                        <UserDetailsProfile user={user} /> 
                        <UserPhotosAdmin displayOnly={true} user={user} />
                        <UserDetailsSubscription user={user} data={subscriptions} loadMore={this.loadMoreSubscriptions} showPaySubscription={this.showPaySubscriptionModal}/>   
                    </div>
                    
                </Col>
              </Row>
            </Container>
            <div className="home-bg"></div>
            <div className="overlay"></div>
            <ToastContainer />
    
            <ChangeUserRole show={this.state.showChangeUserRole} user={this.state.user} onRoleChanged={this.handleUserRoleChanged} handleHideModal={this.handleHideModal}/>
            <EditUser  show={this.state.showEditUser} userUpdated={this.handleUserUpdated}  user={this.state.user} handleHideModal={this.handleEditUserHideModal}/>
            <PaySubscription show={this.state.showPaySubscription} user={this.state.user} handleHideModal={this.handlePaySubscriptionHideModal}/>
          </div>
        )
    }
  }
}

export default withRouter(UserDetailsPage)
