import { Component } from "react";
import { Button, Form } from "react-bootstrap"

class AccountType extends Component{
    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    saveAndContinue = (e) => {
        e.preventDefault();
        this.props.nextStep();
    };
    
    render(){
        const {roles} = this.props.inputValues;

        const formValid = roles.admin.length==='admin'||roles.model==='model'||roles.actor==='actor';

        return (
            <div>
                <form>
                    <Form.Check
                                className="large"
                                inline="true"
                                label="Actor / Actress"
                                name="actor"
                                type='checkbox'
                                id='actor'
                                value="actor"
                                checked={this.props.inputValues.roles['actor']=='actor'}
                                onChange={this.props.handleChange}
                            />
                        <Form.Check
                                className="large"
                                inline="true"
                                label="Model"
                                name="model"
                                type='checkbox'
                                id='model'
                                value="model"
                                checked={this.props.inputValues.roles['model']=='model'}
                                onChange={this.props.handleChange}
                            />
                        <Form.Check
                                className="large"
                                inline="true"
                                label="Admin"
                                name="admin"
                                type='checkbox'
                                id='admin'
                                value="admin"
                                checked={this.props.inputValues.roles['admin']=='admin'}
                                onChange={this.props.handleChange}
                            />
                    <div className="text-right">
                        <Button variant="primary" className="float-right" disabled={!formValid} onClick={this.saveAndContinue}>Choose and Continue</Button>
                    </div>
                    <div className="clearfix"></div>
                </form> 
            </div>
        )
    }
    
}
export default AccountType