import React, { Component } from 'react'
import PlaceholderLoading from 'react-placeholder-loading'
export class ImagePlaceholder extends Component {
    constructor(props){
        super(props);
        
    }
    static defaultProps ={
        circle:false,
        width:100,
        height:100
    }

  render() {
      const { circle,width, height } = this.props;
    return (
      <div className={circle?"image circle":"image"}> 
          <PlaceholderLoading width={width} height={height} shape="rect"/>
      </div>
    )
  }
}

export default ImagePlaceholder
