import { Redirect } from 'react-router-dom';
import { Auth } from '../apis/Auth';

const ProtectedWelcome = ({children}) =>{
    const loggedIn = localStorage.getItem('token')!=null;
    if(loggedIn){
        if(Auth.user.accepted_terms==1){
            return <Redirect to="/account"/>
        }
        return children;
    }
    return <Redirect to="/signin"/>
}

export default ProtectedWelcome
