import { faHandshake } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'

export class UserName extends Component {
  render() {
    const {user} = this.props;

    return (
      <div className="border-bottom mb-4">
        <h2 className="main-title pb-3 pt-4" >Hey, {`${user.first_name} ${user.last_name}`} <FontAwesomeIcon icon={faHandshake}/></h2>
      </div>
    )
  }
}

export default UserName
