import React, { Component } from 'react'
import HeadingPlaceholder from '../../../placeholders/HeadingPlaceholder'
import ButtonPlaceholder from '../../../placeholders/ButtonPlaceholder'
import '../../../assets/css/placeholder.css';
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../../../common/Header'
import UserSidebarNav from '../../../components/user/UserSidebarNav';
import HelpButton from '../../../components/user/HelpButton';
import ImagePlaceholder from '../../../placeholders/ImagePlaceholder';
import ParagraphPlaceholder from '../../../placeholders/ParagraphPlaceholder';

class SubscriptionsPagePlaceholder extends Component {
    
  render() {
    
    return (
    <div>
        <Header background={false} />
        <Container className="content-on-bg mt-3">
          <Row>
            <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
              <UserSidebarNav />
              <HelpButton />
            </Col>
            <Col xl={9} lg={9} md={12} sm={12} xs={12}>
              <div className="welcome mt-0 p-4">
                <div className="Users mb-3">
                  <HeadingPlaceholder block={true} />

                    <div className="buttons border-top mb-4 pt-3">
                        <Row>
                            <Col xl={3} lg={3} md={3} sm={12} className="mb-3">
                                <ButtonPlaceholder  block={true}/>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={12} className="mb-3">
                                <ButtonPlaceholder block={true}/>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} className="mb-3">
                                <ButtonPlaceholder block={true}/>
                            </Col>
                        </Row>
                        
                        

                    </div>
                  <Row>
                        {/*User items */}
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="round-border mb-3">
                                <Row>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <div className="round-border m-3 p-3">
                                            <ParagraphPlaceholder/>
                                            <ParagraphPlaceholder/>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={4}>
                                        <div className="round-border m-3 p-3">
                                            <Row>
                                                <Col xs={12} sm={12} md={8} lg={8}>
                                                    <ParagraphPlaceholder block={true}/>
                                                    <ParagraphPlaceholder  block={true}/>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={4}>
                                                    <ButtonPlaceholder/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="border-top p-3">
                                            <ButtonPlaceholder/>
                                            <ButtonPlaceholder/>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="round-border mb-3">
                                <Row>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <div className="round-border m-3 p-3">
                                            <ParagraphPlaceholder/>
                                            <ParagraphPlaceholder/>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={4}>
                                        <div className="round-border m-3 p-3">
                                            <Row>
                                                <Col xs={12} sm={12} md={8} lg={8}>
                                                    <ParagraphPlaceholder block={true}/>
                                                    <ParagraphPlaceholder  block={true}/>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={4}>
                                                    <ButtonPlaceholder/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="border-top p-3">
                                            <ButtonPlaceholder/>
                                            <ButtonPlaceholder/>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="round-border mb-3">
                                <Row>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <div className="round-border m-3 p-3">
                                            <ParagraphPlaceholder/>
                                            <ParagraphPlaceholder/>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={4}>
                                        <div className="round-border m-3 p-3">
                                            <Row>
                                                <Col xs={12} sm={12} md={8} lg={8}>
                                                    <ParagraphPlaceholder block={true}/>
                                                    <ParagraphPlaceholder  block={true}/>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={4}>
                                                    <ButtonPlaceholder/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="border-top p-3">
                                            <ButtonPlaceholder/>
                                            <ButtonPlaceholder/>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="round-border mb-3">
                                <Row>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <div className="round-border m-3 p-3">
                                            <ParagraphPlaceholder/>
                                            <ParagraphPlaceholder/>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={4}>
                                        <div className="round-border m-3 p-3">
                                            <Row>
                                                <Col xs={12} sm={12} md={8} lg={8}>
                                                    <ParagraphPlaceholder block={true}/>
                                                    <ParagraphPlaceholder  block={true}/>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={4}>
                                                    <ButtonPlaceholder/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="border-top p-3">
                                            <ButtonPlaceholder/>
                                            <ButtonPlaceholder/>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="round-border mb-3">
                                <Row>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <div className="round-border m-3 p-3">
                                            <ParagraphPlaceholder/>
                                            <ParagraphPlaceholder/>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={4}>
                                        <div className="round-border m-3 p-3">
                                            <Row>
                                                <Col xs={12} sm={12} md={8} lg={8}>
                                                    <ParagraphPlaceholder block={true}/>
                                                    <ParagraphPlaceholder  block={true}/>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={4}>
                                                    <ButtonPlaceholder/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="border-top p-3">
                                            <ButtonPlaceholder/>
                                            <ButtonPlaceholder/>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-bg"></div>
        <div className="overlay"></div>
      </div>
    )
  }
}

export default SubscriptionsPagePlaceholder
