import React, { Component } from 'react'
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap'
import Header from '../../common/Header'
import UserSidebarNav from '../../components/user/UserSidebarNav'
import HelpButton from '../../components/user/HelpButton'
import ProfileInfo from '../../components/user/ProfileInfo'
import SubscriptionRequired from '../../components/user/SubscriptionRequired'
import UserPhotos from '../../components/user/UserPhotos'
import { UserApi } from '../../apis/UserApi'
import AccountPagePlaceholder from './placeholders/AccountPagePlaceholder'
import { Auth } from '../../apis/Auth'
import { ToastContainer, toast } from 'react-toastify'

class AccountPage extends Component {
    state = {
        user:'',
        imageCompletion:0,
        profileInfoCompletion:0
    }

    componentDidMount(){
      UserApi.getProfile()
      .then(response=>{
        this.setState({user:response.data});
        document.documentElement.scrollTop=0;
      });
    }

    setImageCompletion = (percentage)=>{
      this.setState({imageCompletion:percentage});
    }
    setProfileCompletion = (percentage)=>{
      this.setState({profileInfoCompletion:percentage});
    }

    handleSubmitForReview = ()=>{
      if(this.state.user){
        const toastId = toast.loading('Submitting...',{position:'bottom-right'});
        UserApi.submitAccountForReview()
          .then((res)=>{
            this.setState({user:res.data.user});

            toast.update(toastId, 
              {
                render: "Your account is now under review", 
                type: "success", 
                isLoading: false,
                autoClose: 5000 
              });
          })
          .catch((err)=>{
            toast.update(toastId, 
              {
                render: err?.response?.data?.message??'An error occured', 
                type: "error", 
                isLoading: false,
                autoClose: 5000 
              });
          })
      }
    }



  render() {
    const { user } =this.state;

    if( !user ){
      return <AccountPagePlaceholder/>
    }
    else{

      let photoCount=0;
      if(user.photo1)photoCount++;
      if(user.photo2)photoCount++;
      if(user.photo3)photoCount++;
      let percentage = photoCount*100/3;

      let completion = 0;
      completion += user.first_name?1:0;
      completion += user.last_name?1:0;
      completion += user.gender?1:0;
      completion += user.email?1:0;
      completion += user.phone?1:0;
      completion += user.height?1:0;
      completion += user.location?1:0;
      completion += user.instagram?1:0;

      const completionPercentage = completion*100/8;

      const overallCompletion = (percentage+completionPercentage)/2;

      return (
        
      <div>
          <Header background={false} />
          <Container className="content-on-bg mt-3">
            <Row>
              <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
                <UserSidebarNav />
                <HelpButton />
              </Col>
              <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                  <div className={!Auth.userIsAdmin()?'':'d-none'}>
                    <SubscriptionRequired  user={user}/>
                  </div>
                  <div className={!Auth.userIsAdmin()?"welcome":'welcome mt-0'}>
                    <ProfileInfo onCompletion={this.setProfileCompletion} user={user}/>
                    <UserPhotos onCompletion={this.setImageCompletion} user={user}/>

                    {/* Submit for review */}
                    <div className={!Auth.userIsAdmin()?"profileInfo mb-3":'d-none'}>
                      <h2 className="section-title">
                        Complete your Profile
                        <span className="float-right">
                          <small>Completed {photoCount+completion}/11</small>
                          <ProgressBar now={overallCompletion} variant="success" />
                        </span>
                      </h2>
                      <div className="clearfix"></div>
                      <p className="d-grid gap-2 mt-3">
                      <Button variant="primary" disabled={overallCompletion!=100||user.status=='Activated'||user.status=='Review'} onClick={this.handleSubmitForReview} size="lg">Submit Profile for Evaluation</Button>
                      </p>
                    </div>
                  </div>
              </Col>
            </Row>
          </Container>
          <div className="home-bg"></div>
          <div className="overlay"></div>
          <ToastContainer/>
        </div>
      )
    }
  }
}

export default AccountPage
