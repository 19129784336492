import { Redirect } from 'react-router-dom';
import { Auth } from '../apis/Auth';

const Protected = ({children}) =>{
    const loggedIn = localStorage.getItem('token')!=null;
    if(loggedIn){
        if(Auth.user.accepted_terms==1){
            return children;
        }
        else{
            return <Redirect to="/welcome"/>
        }
    }
    return <Redirect to="/signin"/>
}

export default Protected
