import React, { Component } from 'react'
import HeadingPlaceholder from '../../../placeholders/HeadingPlaceholder'
import ButtonPlaceholder from '../../../placeholders/ButtonPlaceholder'
import '../../../assets/css/placeholder.css';
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../../../common/Header'
import UserSidebarNav from '../../../components/user/UserSidebarNav';
import HelpButton from '../../../components/user/HelpButton';
import ImagePlaceholder from '../../../placeholders/ImagePlaceholder';
import ParagraphPlaceholder from '../../../placeholders/ParagraphPlaceholder';

class UsersPagePlaceholder extends Component {
    
  render() {
    
    return (
    <div>
        <Header background={false} />
        <Container className="content-on-bg mt-3">
          <Row>
            <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
              <UserSidebarNav />
              <HelpButton />
            </Col>
            <Col xl={9} lg={9} md={12} sm={12} xs={12}>
              <div className="welcome mt-0 p-4">
                <div className="Users mb-3">
                  <HeadingPlaceholder block={true} />
                  <hr />
                    <div className="buttons mb-4">
                        <Row>
                            <Col xl={6} lg={6} md={5} sm={12} className="mb-3">
                                <ButtonPlaceholder/>
                                <ButtonPlaceholder/>
                                <ButtonPlaceholder/>
                                <ButtonPlaceholder/>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} className="mb-3">
                                <ButtonPlaceholder block={true}/>
                            </Col>
                            <Col xl={2} lg={2} md={3} sm={12} className="text-center mb-3">
                                <ButtonPlaceholder/>
                            </Col>
                        </Row>
                        
                        

                    </div>
                  <Row>
                        {/*User items */}
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={4}>
                            <ImagePlaceholder width={200} height={200} />
                            <ParagraphPlaceholder/>
                            <ButtonPlaceholder/>
                            <ButtonPlaceholder/>
                        </Col>
                    </Row>

                </div>
                <div className="text-right">
                    <ButtonPlaceholder/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-bg"></div>
        <div className="overlay"></div>
      </div>
    )
  }
}

export default UsersPagePlaceholder
