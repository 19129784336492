import SEO from '@americanexpress/react-seo'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Accordion, Button, Col, Container, Row } from 'react-bootstrap'
import image from "../../assets/images/akazungu.jpg"
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import { Constants } from '../../constants'

import "../../assets/css/faq.css"
import { Link } from 'react-router-dom'

class FAQPage extends Component {
  componentDidMount(){
    document.documentElement.scrollTop=0;
  }

  render() {
    return (
      <div className="FAQPage">
        <SEO
          title="Frequently Asked Questions - Afrifame Models"
          description="Have a question on how to use Afrifame Models app? Please check the list below that contains Frequently Asked Questions. May be you will find your response. If not please contact us."
          keywords={['Rwandese talents', 'Rwandese actors']}
          siteUrl={Constants.siteUrl+'/about'}
          image={{
            src: image
          }}
        />

        <Header background={false} />
        <Container>
            <div className="wrapper mt-4 mb-3">
              <h2 className="title section-title mb-5 text-white text-center">Frequently Asked Questions</h2>
              <div className="mb-4">
                  <h3 className="section-heading mb-4">
                    <FontAwesomeIcon icon={faArrowRight}/> For Models & Actors
                  </h3>
  <Accordion  flush>
    <Accordion.Item eventKey="0">
        <Accordion.Header>Do I have to introduce myself personally at the agency?</Accordion.Header>
        <Accordion.Body>
        Yes, for you to sign the consent form and to be taken professional pictures to upload on the platform
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What happens to my data?</Accordion.Header>
        <Accordion.Body>
        Every member can only see his or her own personal data. Your personal data (Names, Pictures, age range) are accessible to the clients, the rest of data will be provided to the clients who chose you or sent an inquiry. There is no way that your data will be shared with third parties without your consent.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>When do I get the first job?</Accordion.Header>
        <Accordion.Body>
        This varies widely from model/actor to model/actor. Some models/actors are booked right away. For others, it may take a while, depending on which models/actors are currently being sought or in need. The frequency of placements and jobs varies and is determined exclusively by the customers. Theoretically, it is also possible that you will not be booked at all. For this reason, we cannot offer a booking guarantee. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Do I have to pay the travel costs for the job myself?</Accordion.Header>
        <Accordion.Body>
        No. The travel expenses are paid by the customer. The same applies to other expenses. 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Do I get a booking guarantee from you as a member?</Accordion.Header>
        <Accordion.Body>
        Unfortunately, no! A legitimate agency cannot guarantee this to every member. Each client has his or her own ideas and is only responsible for deciding which member to book depending on the activity. We cannot offer any job/booking guarantee, despite the fact that we receive many job and booking requests for our members through our portal.

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>How can I be booked?</Accordion.Header>
        <Accordion.Body>
        The customer/client will  book you via AFRIFAME’s models & actors website.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>How will I be notified in case of a possible job?</Accordion.Header>
        <Accordion.Body>
        In case of a possible job, you will receive a message from us by email and a phone call.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>My address has changed</Accordion.Header>
        <Accordion.Body>
        As a member, if you have changed your location, if your personal data has changed , or if you have a new phone number, don't worry. You can change everything by yourself, very easily just using your own access data. You can manage and add any other informations and photos by yourself.
        </Accordion.Body>
      </Accordion.Item>
  </Accordion>
                </div>
                <div className="mb-4">
                  <h3 className="section-heading mb-4">
                    <FontAwesomeIcon icon={faArrowRight}/> For Clients
                  </h3>


  <Accordion  flush>
    <Accordion.Item eventKey="0">
        <Accordion.Header>How can I book models/Actor?</Accordion.Header>
        <Accordion.Body>
        You can use the message function on the portal and our chat service to discuss all the details of a job d with the Afrifame and make the necessary arrangements.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Which model/Actor can I book</Accordion.Header>
        <Accordion.Body>
        We have models and actors from a wide range of genres. Depending on the activity, you can access the model of your choice, but if you're having trouble deciding, we're here to help. Chat with us.

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What does your service cost me?</Accordion.Header>
        <Accordion.Body>
        Our service are completely free for clients. As a client, if you like a model/actor, contact us for more information and we will provide you that model.

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>How much commission has to be paid to you</Accordion.Header>
        <Accordion.Body>
        No commission. You have to arrange the settlement of the fee and other payments for the model/actor.
        </Accordion.Body>
      </Accordion.Item>
  </Accordion>

                </div>
                <div className="mb-4 mt-5 more-questions">
                  <h2 className="title text-white mb-4">Still have a Question?</h2>
                  <p className="mb-4">If you can not find answer to you questions in FAQs,
you can always contact us, we will answer you shortly!</p>
                  <Link to="/contact" className="btn btn-primary">Contact Us <FontAwesomeIcon icon={faArrowRight}/></Link>
                </div>
                
                
            </div>
        </Container>
        <Footer/>
      </div>
    )
  }
}

export default FAQPage
