import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../assets/css/footer.css';

import Logo from "../assets/images/logo-afrifame.svg"

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-wrapper">
            <Navbar.Brand className="brand">
                <Link to="/">
                <img src={Logo} alt="Afrifame Models app" className="img-responsive"/>
                </Link>
                
            </Navbar.Brand>
                
            <p>
            The leading marketplace to discover and hire top professional models & actors
            </p>
            <div className="social">
                <Link to="">
                <FontAwesomeIcon icon={faFacebook}/></Link>
                <Link to=""><FontAwesomeIcon icon={faInstagram}/></Link>
                <Link to=""><FontAwesomeIcon icon={faLinkedin}/></Link>
                <Link to=""><FontAwesomeIcon icon={faEnvelope}/></Link>
                <Link to=""><FontAwesomeIcon icon={faTwitter}/></Link>
            </div>
            <div className="copyright">
            Copyright @ 2022, Afrifame Ltd, All Right Reserved
            </div>
        </div>
      </div>
    )
  }
}

export default Footer
