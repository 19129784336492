import React, { Component, Fragment } from 'react'
import { Switch } from 'react-router-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Admin from '../components/admin/Admin';
import Protected from '../components/Protected';
import ProtectedWelcome from '../components/ProtecteWelcome';
import InquiriesPage from '../pages/admin/InquiriesPage';
import SettingsPage from '../pages/admin/SettingsPage';
import SubscriptionsPage from '../pages/admin/SubscriptionsPage';
import TeamDetailsPage from '../pages/admin/TeamDetailsPage';
import TeamPage from '../pages/admin/TeamPage';
import UserDetailsPage from '../pages/admin/UserDetailsPage';
import UsersPage from '../pages/admin/UsersPage';
import AboutPage from '../pages/public/AboutPage';
import ActorsPage from '../pages/public/ActorsPage';
import ContactPage from '../pages/public/ContactPage';
import FAQPage from '../pages/public/FAQPage';
import ForgotPasswordPage from '../pages/public/ForgotPasswordPage';
import HomePage from '../pages/public/HomePage';
import LoginPage from '../pages/public/LoginPage';
import ModelsPage from '../pages/public/ModelsPage';
import PageNotFound from '../pages/public/PageNotFound';
import PaySubscriptionPage from '../pages/public/PaySubscriptionPage';
import RegisteredPage from '../pages/public/RegisteredPage';
import RegisterPage from '../pages/public/RegisterPage';
import ResetPasswordConfirmationPage from '../pages/public/ResetPasswordConfirmationPage';
import ResetPasswordPage from '../pages/public/ResetPasswordPage';
import ResetPasswordRequest from '../pages/public/ResetPasswordRequestPage';
import TalentPage from '../pages/public/TalentPage';
import TalentsPage from '../pages/public/TalentsPage';
import TeamMemberPage from '../pages/public/TeamMemberPage';
import VerifyPage from '../pages/public/VerifyPage';
import AccountPage from '../pages/user/AccountPage';
import UpdateProfilePage from '../pages/user/UpdateProfilePage';
import WelcomePage from '../pages/user/WelcomePage';

class AppRouter extends Component {
  static navigationOptions = () => ({
    title: 'Home',
    headerTintColor: '#eee',
    headerStyle: {
      backgroundColor: '#000',
    },      
  });
  render() {
    return (
        <Router>
          <Switch>
              <Route path="/" exact>
              <HomePage/>
              </Route>
              <Route path="/talents">
                <TalentsPage/>
              </Route>
              <Route path="/talent/:id">
                <TalentPage/>
              </Route>
              <Route path="/models">
                <ModelsPage/>
              </Route>
              <Route path="/actors">
                <ActorsPage/>
              </Route>
              <Route path="/contact">
                <ContactPage/>
              </Route>
              <Route path="/about">
                <AboutPage/>
              </Route>
              <Route path="/team/:id">
                <TeamMemberPage/>
              </Route>
              <Route path="/faq">
                <FAQPage/>
              </Route>
              <Route path="/register/:type">
              <RegisterPage/>
              </Route>
              <Route exact path="/register">
              <RegisterPage/>
              </Route>
              <Route path="/signin"  component={LoginPage}/>
              <Route path="/registered">
              <RegisteredPage/>
              </Route>
              <Route path="/verify/:code">
              <VerifyPage/>
              </Route>
              <Route path="/forgot-password" component={ForgotPasswordPage}/>
              <Route path="/reset-password-request-confirmation" component={ResetPasswordRequest}/>
              <Route path="/reset-password/:code" component={ResetPasswordPage}/>
              <Route path="/reset-password-confirmation" component={ResetPasswordConfirmationPage}/>
              <Route path="/welcome">
                <ProtectedWelcome>
                  <WelcomePage />
                </ProtectedWelcome>
              </Route>
              <Route path="/account">
                <Protected>
                  <AccountPage />
                </Protected>
              </Route>
              <Route path="/update-profile">
                <Protected>
                  <UpdateProfilePage/>
                </Protected>
              </Route>
              <Route path="/users/:id">
                <Admin>
                  <UserDetailsPage />
                </Admin>
              </Route>
              <Route path="/users">
                <Admin>
                  <UsersPage />
                </Admin>
              </Route>
              <Route path="/settings" exact>
                <Admin>
                  <SettingsPage />
                </Admin>
              </Route>
              <Route path="/staff" exact>
                <Admin>
                  <TeamPage />
                </Admin>
              </Route>
              <Route path="/staff/:id">
                <Admin>
                  <TeamDetailsPage />
                </Admin>
              </Route>
              <Route path="/subscriptions">
                <Admin>
                  <SubscriptionsPage />
                </Admin>
              </Route>
              <Route path="/inquiries">
                <Admin>
                  <InquiriesPage />
                </Admin>
              </Route>
              <Route path="/pay-subscription/:token">
                <PaySubscriptionPage/>
              </Route>
              <Route path="/confirm-email/:code">
                <VerifyPage/>
              </Route>
              <Route path="*" component={PageNotFound} />
          </Switch>
        </Router>
    )
  }
}

export default AppRouter
