import React, { Component } from 'react'
import Header from '../../common/Header'
import '../../assets/css/talents.css';

import UserDetailsHeader from "../../components/admin/userDetails/UserDetailsHeader";
import { Button, Col, Container, Row, Spinner} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { HomeApi } from '../../apis/HomeApi';

import avatar from '../../assets/images/avatar.png';
import { Constants } from '../../constants';
import UserDetailsProfile from '../../components/admin/userDetails/UserDetailsProfile';
import SingleModelActor from '../../components/public/SingleModelActor';
import Footer from '../../common/Footer';
import Booking from '../../components/public/Booking';
import SEO from '@americanexpress/react-seo/build/components/SEO';
import { ToastContainer,toast } from 'react-toastify';

class TalentPage extends Component {
  constructor({match,history}){
    super();
    this.state = {
      id: match.params.id,
      user:'',
      status: true,
      relatedTalents:[],
      loading:false,
      bookingItems:[],
      showProccedToBookingModal:false,
      pathname:'/talent/'+match.params.id,
      history:history
    }
  }
  loadUser = (id)=>{
    document.documentElement.scrollTop=0;
    
    this.setState({loading:true});

    HomeApi.getTalentById(id)
    .then(res=>{
      this.setState({loading:false,user:res.data});
      const roles = res.data.roles.map((val=>{
        return val.name;
      }));

      HomeApi.getRelatedTalents(roles,6)
      .then(res=>{
        this.setState({relatedTalents:res.data});
      })

    })
    .catch(err=>{
      this.setState({loading:false});
      toast.error('Failed. '+err.data.message,{
        position:"bottom-right",
        autoClose:5000
      })
    });
  }
  componentDidMount = ()=>{

    this.loadUser(this.state.id);

    const self = this;

    this.state.history.listen((location)=>{
      if(self.state.pathname != location.pathname){
        self.setState({pathname:location.pathname});
        const params = location.pathname.split('/');
        const id = params[(params.length-1)];
        if(parseInt(id))
          self.loadUser(id);
      }
    });
  }


  handleThumbnailClick = (e)=>{
    document.getElementById('profileImage').src=e.target.src;
  }

  handleProceedToBookingBtn = (e)=>{
    let items = this.state.bookingItems;
    /**check if it is not yet added*/
    let added="";
    if(items){
      added = items.filter(val=>{
        return val.id==this.state.user.id;
      });
    }

    if(!added.length){
      items = items.concat(this.state.user);
      this.setState({bookingItems:items,showProccedToBookingModal:true});
    }
    else{
      this.setState({showProccedToBookingModal:true});
    }
    
  }


  handleAddToBooking = (e)=>{
    let items = this.state.bookingItems;

    const id = e.target.value;

    const selectedItem = this.state.relatedTalents.filter(val=>{
        return val.id == id;
    });

    items = items.concat(selectedItem);
    this.setState({bookingItems:items});
  }
  handleRemoveItem = (id)=>{
    let items = this.state.bookingItems;

    const selectedItem = items.filter(val=>{
        return val.id != id;
    });

    this.setState({bookingItems:selectedItem});
  }
  handleBookingSuccess = ()=>{
    this.setState({bookingItems:[]});
  }

  handleModalClose = ()=>{
    this.setState({showProccedToBookingModal:false});
  }



  render() {
    const { relatedTalents } = this.state;

    let image = '';
    if(this.state.user&&this.state.user.photo1){
      image = Constants.publicUrl+'/images/'+this.state.user.photo1;
    }
    else{
      image = avatar;
    }

    let modelItems = "";
    if(relatedTalents){
      modelItems = relatedTalents.map((val,index)=>{
        return <SingleModelActor showBookingBtn={true} selectedItems={this.state.bookingItems} addToBooking={this.handleAddToBooking} lg={4} xl={4} user={val} key={index}/>
      })
    }

    return (
      <div className="talentPage">
        <SEO
          title={(this.state.user.first_name+' '+this.state.user.last_name)+" - Afrifame"}
          description={(this.state.user.first_name+' '+this.state.user.last_name)+" We connect you with Rwandan talented talents of your choice."}
          keywords={['Rwandese talents', 'Rwandese actors']}
          siteUrl={Constants.siteUrl+'/talent/'+this.state.user.id}
          image={{
            src: image
          }}
        />

        <Header background={false} />
        {!this.state.loading?<Container>
          <div className="wrapper mt-3">
            <UserDetailsHeader user={this.state.user} usage="public" handleProceedToBooking={this.handleProceedToBookingBtn}/>

            <Row>
              <Col xl={8} lg={8} md={7} sm={12}>
                <div className="profile-image">
                  <img className="img-responsive" id="profileImage" src={image} alt="" />
                  <div className="thumbnails">
                    <Row>
                        {this.state.user.photo1 && 
                        <Col xl={3} lg={2} md={3} sm={4} xs={4}>
                          <div className="image-w">
                            <img src={Constants.publicUrl+'/images/'+this.state.user.photo1} alt="" onClick={this.handleThumbnailClick} />
                          </div>
                        </Col>
                        }
                      {this.state.user.photo2 && 
                        <Col xl={3} lg={2} md={3} sm={4} xs={4}>
                          <div className="image-w">
                            <img src={Constants.publicUrl+'/images/'+this.state.user.photo2} alt="" onClick={this.handleThumbnailClick}/>
                          </div>
                        </Col>
                      }
                      {this.state.user.photo3 && 
                        <Col xl={3} lg={2} md={3} sm={4} xs={4}>
                          <div className="image-w">
                            <img src={Constants.publicUrl+'/images/'+this.state.user.photo3} alt="" onClick={this.handleThumbnailClick} />
                          </div>
                        </Col>
                      }
                    </Row>
                  </div>
                </div>
                
              </Col>
              <Col xl={4} lg={4} md={5} sm={12}>
                <Row>
                  <UserDetailsProfile user={this.state.user} lg={12} md={12} sm={12} showTitle={false} usage="public"/>
                </Row>
              </Col>
            </Row>
            <div className="clearfix"></div>
                  <div className="border rounded mt-3 p-3">
                    <p className="proc-title">Want to work with {this.state.user.first_name} {this.state.user.last_name}?</p>
                    <Button onClick={this.handleProceedToBookingBtn}>Proceed to Booking</Button>
                  </div>
          </div>
          <div className="wrapper mt-3 mb-3">
            <div className="talents-list border rounded p-3">
              <div className="models">
                <h2 className="section-title mb-4">See models</h2>
                <Row>
                  {modelItems}
                </Row>
              </div>
            </div>
          </div>
        </Container>:<Container>
            <div className="wrapper mt-3 mb-3">
              <div className="text-center">
              <Spinner animation="grow" variant="dark"/>
              </div>
            </div>
        </Container>
        }
        <div className="d-none">
        <Booking view="button" show={this.state.showProccedToBookingModal}  selectedItems={this.state.bookingItems} handleBookingSuccess={this.handleBookingSuccess} handleRemoveItem={this.handleRemoveItem} handleClose={this.handleModalClose}/>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default withRouter(TalentPage)
