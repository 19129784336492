import './assets/css/common.css';

import { Fragment } from 'react';
import AppRouter from './routes/AppRouter';
import { Auth } from './apis/Auth';

function App() {
  if(localStorage.getItem('token')){
      Auth.user = JSON.parse(localStorage.getItem('user'));
      Auth.roles = JSON.parse(localStorage.getItem('roles'));
  }

  return (
    <Fragment>
      <AppRouter/>
    </Fragment>
  );
}

export default App;
