import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../../common/Header'
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom'

class RegisteredPage extends Component {
    state = {
        accountType:''
    }
    componentDidMount(){
      document.documentElement.scrollTop=0;
    }

  render() {
      const accountType = localStorage.getItem('accountType');
      
      console.log(accountType);
      
      if(this.state.userRegistered){
          return <Redirect to="/registered"/>
      }
    return (
    <div>
        <Header background={false} />
        <Container className="content-on-bg">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div  className="register text-center">
                <h1 className="title text-center mb-4">Register</h1>
                <div className="circle envelope">
                    <FontAwesomeIcon icon={faEnvelopeOpen}/>
                </div>
                <strong>Check your email</strong>
                <p>Account activation email has been sent to your email</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-bg"></div>
        <div className="overlay"></div>
      </div>
    )
  }
}

export default RegisteredPage
