import SEO from '@americanexpress/react-seo'
import { faCheck, faEnvelope, faMapMarker, faPhone, faTent } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { Component } from 'react'
import { Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row, Spinner, } from 'react-bootstrap'
import image from "../../assets/images/akazungu.jpg"
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import { Constants } from '../../constants'
import {  ToastContainer, toast } from "react-toastify"
import { Link } from 'react-router-dom'
import SettingsApi from '../../apis/SettingsApi'
import "../../assets/css/contact.css"

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class ContactPage extends Component {
    state={
        email:'',
        name:'',
        subject:'',
        message:'',
        name_valid:false,
        email_valid:false,
        subject_valid:false,
        message_valid:false,
        errors:{
            name:'',
            email:'',
            subject:'',
            message:''
        },
        valid:false,
        processing:false,
        messageSent:false,
        settings:''
    }

    componentDidMount(){
      document.documentElement.scrollTop=0;

      SettingsApi.loadSettings()
      .then(res=>{
        this.setState({settings:res.data});
      })
    }

    validateForm = (errors) => {
        let valid = true;
        
        if(!this.state.name_valid){
            errors['name'] = 'Name is required';
        }
        if(!this.state.email_valid){
            errors['email'] = 'Email is required';
        }
        if(!this.state.subject_valid){
            errors['subject'] = 'Subject is required';
        }
        if(!this.state.message_valid){
            errors['message'] = 'Message is required';
        }

        this.setState({errors:errors});

        Object.values(errors).forEach(
          // if we have an error string set valid to false
          (val) => val.length > 0 && (valid = false)
        );
        
        return valid;
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        
        switch (name) {
          case 'name': 
            errors.name = 
              value.length <= 0
                ? 'Name is required'
                : '';
            break;
          case 'email': 
            errors.email = 
              validEmailRegex.test(value)
                ? ''
                : 'Email is not valid!';
            break;
          case 'subject': 
            errors.subject = 
              value.length <= 0
                ? 'Subject is required'
                : '';
            break;
          case 'message': 
            errors.message = 
              value.length <= 0
                ? 'Message is required'
                : '';
            break;
          default:
            break;
        }

        let fieldValid = !errors[name]?true:false;
        let fieldNameValid = name+'_valid';

      
        this.setState({errors, [name]: value, [fieldNameValid]:fieldValid})
    }

    handleInputChange = (e)=>{
        const name = e.target.name;
        this.setState({[name]:e.target.value})
    }


    handleSubmit = (e)=>{

        e.preventDefault();

        // ["name","email","subject","message"]

        if(this.validateForm(this.state.errors)) {
            const toastId = toast.loading('Sending message...',{position:'bottom-right'});

            this.setState({processing:true});
            const self = this;

            window.grecaptcha.ready(function() {
              window.grecaptcha.execute(Constants.public_key, {action: 'submit'}).then(function(token) {
            
                axios.post('/contact',{
                  name:self.state.name,
                  subject: self.state.subject,
                  email:self.state.email,
                  message:self.state.message,
                  recaptcha_token:token
                })
                .then(()=>{
                  self.setState({processing:false,messageSent:true,name:'',email:'',subject:'',message:''});
                  toast.update(toastId,{
                    isLoading:false,
                    autoClose:5000,
                    render: 'Message sent successfully',
                    type:'success'              
                  });

                })
                .catch((err)=>{
                  self.setState({processing:false});
                  
                  if(err?.response?.status==422){
                      const errors = err.response.data;
                      let inputErrors = self.state.errors;
    
                      for (const key in errors) {
                          inputErrors[key]=errors[key][0];
                      }
                      self.setState({errors:inputErrors});
                  }
                  if(err?.response?.status==404||err?.response?.status==500){
                    toast.update(toastId,{
                        isLoading: false,
                        autoClose: 5000,
                        type:'error',
                        render: err?.message??'Something went wrong'
                    });
                  }
                  else{
                    toast.update(toastId,{
                        isLoading: false,
                        autoClose: 5000,
                        type:'error',
                        render: err?.response?.data?.message??'Something went wrong'
                    });
                  }
                });
              })
            });
        }
    }

    sendAnotherMessage = ()=>{
        this.setState({messageSent:false});
    }



  render() {
    const { errors, settings } = this.state;

    return (
      <div className="contactPage">
        <SEO
          title="Contact us - Afrifame Models"
          description="Don't be shy. Please share your experience with us or let us know where we can improve"
          keywords={['Rwandese talents', 'Rwandese actors']}
          siteUrl={Constants.siteUrl+'/contact'}
          image={{
            src: image
          }}
        />

        <Header background={false} />
        <Container>
            <div className="wrapper mt-4 mb-3">
            <h2 className="title text-center text-white mb-5">Contact Us</h2>
            <div className="company-contact mb-4">
                <Row>
                  
                  <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                      <div className="item">
                        
                      <FontAwesomeIcon icon={faPhone} />
                      <p>Phone<br/>
                      <span>{settings.contact_phone??"-"}</span>
                      </p>
                          <div className="clearfix"></div>      
                      </div>
                  </Col>
                  <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                      <div className="item">
                      
                      <FontAwesomeIcon icon={faEnvelope} />
                            <p>Email <br/>
                            <span>{settings.contact_email??"-"}</span>
                            </p>
                            <div className="clearfix"></div>   
                      </div>
                  </Col>
                  <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                    <div className="item">
                        
                        <FontAwesomeIcon icon={faMapMarker} />
                          <p>Location <br/>
                          <span>{settings.location??"-"}</span>
                          </p>
                          <div className="clearfix"></div>  
                    </div>
                  </Col>
                </Row>
              </div>

              <Row>

                  <Col xl={{span:10,offset:1}} lg={{span:10,offest:1}} md={8} sm={12} xs={12}>
              <div className="bg-white p-3 rounded  mb-4">
                      
                            <div className="border rounded p-4 mb-4">
                                {!this.state.messageSent?
                                <form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                        
                                            <FormGroup>
                                                <FormLabel>Name:</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter your name"
                                                    value={this.state.name}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.processing}
                                                    />
                                            </FormGroup>
                                            {errors.name.length > 0 && 
  <div className='text-danger'>{errors.name}</div>}
                                        </Col>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <FormGroup>
                                                <FormLabel>Email:</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="email"
                                                    placeholder="Enter your email"
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.processing}
                                                    />
                                            </FormGroup>
                                            {errors.email.length > 0 && 
  <div className='text-danger'>{errors.email}</div>}
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <FormLabel>Subject:</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="subject"
                                            placeholder="Enter your subject"
                                            value={this.state.subject}
                                            onChange={this.handleChange}
                                            disabled={this.state.processing}
                                            />
                                    </FormGroup>
                                    {errors.subject.length > 0 && 
  <div className='text-danger'>{errors.subject}</div>}

                                    <FormGroup>
                                        <FormLabel>Message:</FormLabel>
                                        <FormControl
                                            rows={10}
                                            as="textarea"
                                            name="message"
                                            placeholder="Enter message"
                                            value={this.state.message}
                                            onChange={this.handleChange}
                                            disabled={this.state.processing}
                                            />
                                    </FormGroup>
                                    {errors.message.length > 0 && 
  <div className='text-danger'>{errors.message}</div>}
                                    <div className="text-right">
                                        <Button type="submit" disabled={this.state.processing}>Send</Button>
                                    </div>
                                </form>:
                                    <div>
                                        <div className="contact-success text-center text-success">
                                            <div className="icon bg-success text-white">
                                                <FontAwesomeIcon icon={faCheck}/>
                                            </div>
                                            <strong>Your message has been sent</strong>
                                        </div>
                                        
                                    </div>
                                    }
                            </div>
                            {this.state.messageSent?<div>
                                <Link to="/" className="btn btn-light btn-md">Go Back Home</Link>
                                        <Button onClick={this.sendAnotherMessage} className="btn-light btn-md float-right">Send Another message</Button>
                            </div>:""}
                        

                </div>
                </Col>

                       <Col xl={{span:10,offset:1}} lg={{span:10,offest:1}} md={8} sm={12} xs={12}>
                        <div className="rounded">
                        <iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15950.126552747264!2d30.0782021!3d-1.939924!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3c2acde307c78d05!2sAfrifame%20Ltd!5e0!3m2!1sen!2srw!4v1655922834737!5m2!1sen!2srw" width="600" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              
                        </div>
                       
                        </Col> 

                    </Row>
                
            </div>
            <ToastContainer/>
        </Container>
        <Footer/>
      </div>
    )
  }
}

export default ContactPage
