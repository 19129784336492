import React, { Component } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Header from '../../common/Header'
import HelpButton from '../../components/user/HelpButton'

import { ToastContainer, toast } from 'react-toastify';

import { Redirect, withRouter } from 'react-router-dom'
import UserSidebarNav from '../../components/user/UserSidebarNav'
import UserDetailsPlaceholder from './placeholders/UserDetailsPlaceholder'
import EditMember from '../../components/admin/members/EditMember';
import TeamApi from '../../apis/TeamApi';
import { Constants } from '../../constants';

import avatar from "../../assets/images/avatar.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faEllipsis, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import DeleteAlertModel from '../../common/DeleteAlertModel';
import PublishAlertModel from '../../common/PublishAlertModel';
import UnpublishAlertModal from '../../common/UnpublishAlertModal';

class TeamDetailsPage extends Component {
  constructor({match}){
    super();
    this.state = {
        id:match.params.id,
        member:'',
        showEditMember:false,
        deleting:false,
        publishing:false,
        memberDeleted:false,
        unpublishing:false
    }
  }

    componentDidMount(){
      document.documentElement.scrollTop=0;
      this.loadMember();
    }
    loadMember = ()=>{
      TeamApi.getMemberById(this.state.id)
        .then(res=>{
          this.setState({member:res.data});
        })
        .catch(()=>{
          toast.error("An error occured. Please retry again later",{position:"bottom-right"});
        })
    }

    showEditMemberModal = ()=>{
      this.setState({showEditMember:true})
      console.log('edit member');
    }
    handleEditMemberHideModal = ()=>{
      this.setState({showEditMember:false})
    }

    handleMemberUpdated = ()=>{
      this.setState({showEditMember:false})
      /*Refresh user data**/
      TeamApi.getMemberById(this.state.id).then((response)=>{
        this.setState({member:response.data});
      })
      .catch((error)=>{
        console.log(error);
        toast.error(error.message,{
          position:'top-right'
        })
      });
    }

    showDeleteMemberModal = ()=>{
      this.setState({deleting:true});
    }

    handleHideDeleteAlertModal = ()=>{
      this.setState({deleting:false});
    }
    confirmDelete = ()=>{
      this.setState({deleting:false});
      const toastId = toast.loading("Loading...",{position:"bottom-right"});

      TeamApi.deleteMember(this.state.id)
      .then(()=>{
        this.setState({memberDeleted:true});
        toast.update(toastId,{
          render:"Member deleted successfully",
          type:"success",
          isLoading:false,
          autoClose:5000
        });
      })
      .catch((err)=>{
        toast.update(toastId,{
          render:"An error occured: "+err.response.message,
          type:"error",
          isLoading:false,
          autoClose:5000
        });
      });
    }


    showPublishMemberModal = ()=>{
      this.setState({publishing:true});
    }

    handleHidePublishAlertModal = ()=>{
      this.setState({publishing:false});
    }

    confirmPublish = ()=>{
      this.setState({publishing:false});
      const toastId = toast.loading("Loading...",{position:"bottom-right"});

      TeamApi.publishMember(this.state.id)
      .then(()=>{
        this.setState({memberPublished:true});
        /**Refresh page */
        this.loadMember();
        toast.update(toastId,{
          render:"Member published successfully",
          type:"success",
          isLoading:false,
          autoClose:5000
        });
      })
      .catch((err)=>{
        toast.update(toastId,{
          render:"An error occured: "+err.response.message,
          type:"error",
          isLoading:false,
          autoClose:5000
        });
      });
    }


    showUnpublishMemberModal = ()=>{
      this.setState({unpublishing:true});
    }

    handleHideUnpublishAlertModal = ()=>{
      this.setState({unpublishing:false});
    }

    confirmUnpublish = ()=>{
      this.setState({unpublishing:false});
      const toastId = toast.loading("Loading...",{position:"bottom-right"});

      TeamApi.unpublishMember(this.state.id)
      .then(()=>{
        this.setState({memberPublished:true});
        /**Refresh page */
        this.loadMember();
        toast.update(toastId,{
          render:"Member unpublished successfully",
          type:"success",
          isLoading:false,
          autoClose:5000
        });
      })
      .catch((err)=>{
        toast.update(toastId,{
          render:"An error occured: "+err.response.message,
          type:"error",
          isLoading:false,
          autoClose:5000
        });
      });
    }

  render() {
    const { member } = this.state;

    if(this.state.memberDeleted){
      return <Redirect to="/staff"/>
    }


    if(!member){
      return <UserDetailsPlaceholder/>
    }
    else{
      return (
        <div className="TeamDetails">
            <Header background={false} />
            <Container className="content-on-bg mt-3">
              <Row>
                <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mainSidebar">
                  <UserSidebarNav />
                  <HelpButton />
                </Col>
                <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                    <div className="welcome mt-0 pt-4">
                      <div className="p-3 bg-black rounded user-profile-header text-white">
                        <Row>
                          <Col lg={2}>
                            <div className="image picture">
                              <img className="img-responsive" src={member.photo?Constants.publicUrl+"/images/"+member.photo:avatar} alt="" />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <h3 className="text-white">{member.name}</h3>
                            <p className="text-light">{member.position}</p>
                            Status: <Button type="button" className="upload-button" variant={member.status==1?"success":(member.status==2?"danger":"light")} title={member.status==1?'Member is Active':(member.status==2?'Member unpublished':'Member is not active')} size="sm">
                              <FontAwesomeIcon icon={member.status==1?faCheck:(member.status==2?faClose:faEllipsis)}/>
                          </Button>
                          </Col>
                          <Col lg={4} className="text-center">
                            <Button type="button" onClick={this.showEditMemberModal} title="Edit Member">
                                <FontAwesomeIcon icon={faPencil}/> </Button>
                                {member.status!=1?
                                  <Button type="button" onClick={this.showPublishMemberModal} title="Publish Member">
                                  <FontAwesomeIcon icon={faCheck}/> </Button>
                                  :
                                  <Button type="button" onClick={this.showUnpublishMemberModal} title="Unpublish Member">
                                  <FontAwesomeIcon icon={faClose}/> </Button>
                                }
                                


                              <Button className="btn-light" type="button" title="Delete Member" onClick={this.showDeleteMemberModal}>
                              <FontAwesomeIcon icon={faTrash}/> </Button>
                              
                          </Col>
                        </Row>
                      </div>
                    
                      <p className="pt-3 pb-3">{member.bio??"Bio was not added yet"}</p>
                      <div className="mb-4">
                      <strong >Social Media Links:</strong>
                      </div>
                      
                      <ul className="member-social">
                        <li><strong>Facebook:</strong> {member.facebook_url??"Not yet provided"}</li>
                        <li><strong>Twitter:</strong> {member.twitter_url??"Not yet provided"}</li>
                        <li><strong>LinkedIn:</strong> {member.linkedin_url??"Not yet provided"}</li>
                        <li><strong>Instagram:</strong> {member.instagram_url??"Not yet provided"}</li>
                      </ul>
                    </div>
                    
                </Col>
              </Row>
            </Container>
            <div className="home-bg"></div>
            <div className="overlay"></div>
            <ToastContainer />
    
            <EditMember  show={this.state.showEditMember} onMemberUpdated={this.handleMemberUpdated}  member={this.state.member} onHideModal={this.handleEditMemberHideModal}/>
            <DeleteAlertModel show={this.state.deleting} handleHideModal={this.handleHideDeleteAlertModal} onDelete={this.confirmDelete}/>
            <PublishAlertModel show={this.state.publishing} handleHideModal={this.handleHidePublishAlertModal} onPublish={this.confirmPublish}/>
            <UnpublishAlertModal show={this.state.unpublishing} handleHideModal={this.handleHideUnpublishAlertModal} onUnpublish={this.confirmUnpublish}/>
          </div>
        )
    }
  }
}

export default withRouter(TeamDetailsPage)
