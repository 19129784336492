import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';
import { Link, Redirect } from 'react-router-dom'
import Header from '../../common/Header'
import { ToastContainer, toast } from 'react-toastify';
import { ResetPasswordApi } from '../../apis/ResetPasswordApi';
import { Auth } from '../../apis/Auth';
import { Constants } from '../../constants';

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class ResetPasswordPage extends Component {
    constructor({match}) {
        super();
        this.state = {
            code: match.params.code,
            userData:'',
            password:'',
            password_confirmation:'',
            password_valid:false,
            password_confirmation_valid:'',
            errors:{
                password:'',
                password_confirmation:''
            },
            passwordReset:false
        };
    }

    validateForm = (errors) => {
        let valid = true;
    
        if(!this.state.password_valid){
            errors['password'] = 'Password is required';
        }
        if(!this.state.password_confirmation_valid){
            errors['password_confirmation'] = 'Password Confirmation is required';
        }

        this.setState({errors:errors});

        Object.values(errors).forEach(
          // if we have an error string set valid to false
          (val) => val.length > 0 && (valid = false)
        );
        
        return valid;
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        
        switch (name) {
          case 'password': 
            errors.password = 
              value.length == ''
                ? 'Password is required'
                : '';
            break;
        case 'password_confirmation': 
            errors.password_confirmation = 
              value.length == ''
                ? 'Password confirmation is required'
                : '';
            break;
          default:
            break;
        }

        let fieldValid = !errors[name]?true:false;
        let fieldNameValid = name+'_valid';
      
        this.setState({errors, [name]: value, [fieldNameValid]:fieldValid}, ()=> {
            console.log(errors)
        })
      }
    
    handleSubmit = (e)=>{
        e.preventDefault();
        if(this.validateForm(this.state.errors)) {
            
            const toastId = toast.loading('Processing....',{position:'bottom-right'});

            const self = this;

            window.grecaptcha.ready(function() {
              window.grecaptcha.execute(Constants.public_key, {action: 'submit'}).then(function(token) {

                  ResetPasswordApi.resetPassword({
                    token: self.state.code,
                    password:self.state.password,
                    password_confirmation:self.state.password_confirmation,
                    recaptcha_token:token
                  })
                  .then(response=>{
                    self.setState({passwordReset:true});
                    toast.update(toastId,{
                        isLoading: false,
                        autoClose: 5000,
                        type:'success',
                        render:response?.data?.message
                    });
                  })
                  .catch(err=>{
                    if(err?.response?.status==422){
                        const errors = err.response.data.errors;
                        let inputErrors = self.state.errors;

                        for (const key in errors) {
                            inputErrors[key]=errors[key][0];
                        }
                        self.setState({errors:inputErrors});
                    }
                    if(err?.response?.status==404||err?.response?.status==500){
                      toast.update(toastId,{
                          isLoading: false,
                          autoClose: 5000,
                          type:'error',
                          render: err?.message??'Something went wrong'
                      });
                    }
                    else{
                      toast.update(toastId,{
                          isLoading: false,
                          autoClose: 5000,
                          type:'error',
                          render: err?.response?.data?.message??'Something went wrong'
                      });
                    }
                  });
              });
            });
        }else{
          toast.error('Missing required inputs',{position:'bottom-right'});
        }
    }

   

  render() {
      if(this.state.passwordReset){
          return <Redirect to="/reset-password-confirmation"/>
      }
      if(Auth.user){
        /**you are logged in */
        return <Redirect to="/account"/>
      }

      const {errors} = this.state;
    return (
    <div>
        <Header background={false} />
        <Container className="content-on-bg">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div  className="register">
                <h1 className="title text-center mb-4">Set a new password</h1>
                <form onSubmit={this.handleSubmit}>
                    <Row>
                        
                        
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="password">Password</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                name="password"
                                value={this.state.password} 
                                onChange={this.handleChange}
                                
                            />
                            {errors.password.length > 0 && 
  <span className='text-danger'>{errors.password}</span>}
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Form.Label htmlFor="password_confirmation">Retype Password</Form.Label>
                            <Form.Control
                                type="password"
                                id="password_confirmation"
                                name="password_confirmation"
                                value={this.state.password_confirmation} 
                                onChange={this.handleChange}
                                
                            />
                            {errors.password_confirmation.length > 0 && 
  <span className='text-danger'>{errors.password_confirmation}</span>}
                        </Col>
                    </Row><br />
                    <p className="d-grid gap-2">
                        <Button type="submit" variant="primary" size="lg">Reset Password</Button>
                    </p>
                </form>
                <p className="p-2 text-center"><Link to="/signin" className="text-primary"><FontAwesomeIcon icon={faArrowLeft}/> Back to sign in</Link></p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-bg"></div>
        <div className="overlay"></div>
        <ToastContainer/>
      </div>
    )
  }
}

export default withRouter( ResetPasswordPage)
